var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useActiveOrganizationListener } from 'hooks/useActiveOrganizationListener';
import { LoadingSpinner } from 'lib/components/LoadingSpinner';
import { exists } from 'lib/types';
import { selectUser } from 'redux/auth';
import { useAppSelector } from 'redux/hooks';
import { logInfo } from 'utils/logger';
import React from 'react';
import { RegularDeadlines } from './RegularDeadlines';
function RegularProductDeadlines({ productLine, publishingSetting }) {
    const user = useAppSelector(selectUser);
    const activeOrganization = useActiveOrganizationListener();
    const [deadlines, setDeadlines] = React.useState(publishingSetting.modelData.deadlines);
    if (!exists(activeOrganization)) {
        return _jsx(LoadingSpinner, {});
    }
    const handleDeadlineUpdate = (dayEnum, updatedDeadlineSettings) => __awaiter(this, void 0, void 0, function* () {
        if (!deadlines) {
            return;
        }
        const newDeadlines = [
            ...deadlines.slice(0, dayEnum - 1),
            updatedDeadlineSettings,
            ...deadlines.slice(dayEnum)
        ];
        // This is temporary until we can add proper audit logging on org updates
        logInfo(`Updating product deadlines for ${productLine}`, {
            newDeadlines,
            dayEnum,
            deadlines,
            userId: user === null || user === void 0 ? void 0 : user.id,
            publisher: activeOrganization.id
        });
        yield publishingSetting.ref.update({
            deadlines: newDeadlines
        });
        setDeadlines(newDeadlines);
    });
    const { iana_timezone } = activeOrganization.data();
    return (_jsx(RegularDeadlines, { productLine: productLine, deadlineSettings: deadlines, timeZone: iana_timezone, updateRow: handleDeadlineUpdate, configurable: undefined }));
}
export default RegularProductDeadlines;
