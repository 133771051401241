import { OrderStatus } from 'lib/types/order';
import { NewspaperOrderStatus } from 'lib/types/newspaperOrder';
import { logAndCaptureMessage } from 'utils';
export const getBadgeStatusForNewspaperOrderStatus = (newspaperOrderStatus) => {
    switch (newspaperOrderStatus) {
        case NewspaperOrderStatus.IN_REVIEW:
            return 'warning';
        case NewspaperOrderStatus.COMPLETE:
        case NewspaperOrderStatus.CONFIRMED:
            return 'success';
        case NewspaperOrderStatus.AWAITING_REVIEW:
            return 'critical';
        case NewspaperOrderStatus.CANCELLED:
        case NewspaperOrderStatus.ARCHIVED:
        case NewspaperOrderStatus.DELETED:
        case NewspaperOrderStatus.DRAFT:
            return undefined;
        default:
            logAndCaptureMessage('Unexpected newspaper order status', {
                newspaperOrderStatus
            });
            return undefined;
    }
};
export const getBadgeStatusForOrderStatus = (orderStatus) => {
    switch (orderStatus) {
        case OrderStatus.DRAFT:
            return undefined;
        case OrderStatus.PENDING:
            return 'warning';
        case OrderStatus.CANCELLED:
            return 'critical';
        default:
            logAndCaptureMessage('Unexpected order status', {
                orderStatus
            });
    }
};
export const getStatusBadgeText = (orderStatus) => {
    switch (orderStatus) {
        case OrderStatus.PENDING:
            return 'In Progress';
        default:
            return orderStatus;
    }
};
