import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from 'react';
import { ColumnSelect } from 'lib/components/ColumnSelect';
import { ColumnButton } from 'lib/components/ColumnButton';
import { Label } from 'lib/components/Label';
import moment from 'moment';
import ColumnDatePicker from 'components/ColumnDatePicker';
import classNames from 'classnames';
import { AFFIDAVIT_OPTIONS, DEFAULT_NOTICE_FILTERS, INVOICE_OPTIONS_PUBLISHER, INVOICE_OPTIONS_ADVERTISER, PUBLICATION_DATE_OPTIONS, STATUS_OPTIONS, SYNC_OPTIONS } from 'utils/noticeFilter';
import { calendarDaysApart } from 'lib/helpers';
export function NoticesFilterDialog({ className, value, showSyncFilter, onChange, isPublisher }) {
    var _a, _b, _c, _d, _e, _f;
    const [statusFilter, setStatusFilter] = useState(value.status);
    const [invoiceFilter, setInvoiceFilter] = useState(value.invoice);
    const [affidavitFilter, setAffidavitFilter] = useState(value.affidavit);
    const [syncFilter, setSyncFilter] = useState(value.sync);
    const [dateFilter, setDateFilter] = useState(value.publicationDate);
    const [customStartDate, setCustomStartDate] = useState((_a = value.publicationDateRange) === null || _a === void 0 ? void 0 : _a.start);
    const [customEndDate, setCustomEndDate] = useState((_b = value.publicationDateRange) === null || _b === void 0 ? void 0 : _b.end);
    const publicationDateRange = customStartDate && customEndDate
        ? {
            start: customStartDate,
            end: customEndDate
        }
        : undefined;
    const pendingValue = {
        status: statusFilter,
        invoice: invoiceFilter,
        affidavit: affidavitFilter,
        sync: syncFilter,
        publicationDate: dateFilter,
        publicationDateRange
    };
    const hasChange = pendingValue.status !== value.status ||
        pendingValue.invoice !== value.invoice ||
        pendingValue.affidavit !== value.affidavit ||
        pendingValue.sync !== value.sync ||
        pendingValue.publicationDate !== value.publicationDate ||
        ((_c = pendingValue.publicationDateRange) === null || _c === void 0 ? void 0 : _c.start) !==
            ((_d = value.publicationDateRange) === null || _d === void 0 ? void 0 : _d.start) ||
        ((_e = pendingValue.publicationDateRange) === null || _e === void 0 ? void 0 : _e.end) !== ((_f = value.publicationDateRange) === null || _f === void 0 ? void 0 : _f.end);
    const handleResetClick = () => {
        if (onChange) {
            onChange(DEFAULT_NOTICE_FILTERS);
        }
    };
    const handleApplyClick = () => {
        if (onChange) {
            onChange(pendingValue);
        }
    };
    const formattedPublicationDateOptions = PUBLICATION_DATE_OPTIONS.map(option => {
        let description;
        const today = moment();
        const startOfMonth = moment().startOf('month');
        const lastMonth = moment().subtract(1, 'month');
        if (option.value === 'this-month') {
            description = `${startOfMonth.format('MMM D')} to ${today.format('MMM D')}`;
        }
        if (option.value === 'last-month') {
            description = lastMonth.format('MMMM');
        }
        return Object.assign(Object.assign({}, option), { description });
    });
    return (_jsxs("div", Object.assign({ className: classNames('flex flex-col gap-3 overflow-y-visible mt-1 w-72 z-10 rounded-md shadow-md bg-white border border-column-gray-200 focus:outline-none py-5 px-4 font-normal text-column-gray-500', className), role: "menu", "aria-orientation": "vertical", "aria-labelledby": "affidavit-options-menu" }, { children: [_jsx("p", Object.assign({ className: "uppercase text-column-gray-500 text-xs font-bold mb-3" }, { children: "Filter by" })), isPublisher && (_jsx(ColumnSelect, { id: "notice-status", labelText: "Notice status", options: STATUS_OPTIONS, value: statusFilter, onChange: setStatusFilter, size: "small" })), _jsx(ColumnSelect, { id: "invoices", labelText: isPublisher ? 'Invoice status' : 'Payment status', options: isPublisher ? INVOICE_OPTIONS_PUBLISHER : INVOICE_OPTIONS_ADVERTISER, value: invoiceFilter, onChange: setInvoiceFilter, size: "small" }), _jsx(ColumnSelect, { id: "affidavits", labelText: "Affidavits", options: AFFIDAVIT_OPTIONS, value: affidavitFilter, onChange: setAffidavitFilter, size: "small" }), isPublisher && showSyncFilter && (_jsx(ColumnSelect, { id: "sync-status", labelText: "Sync status", options: SYNC_OPTIONS, value: syncFilter, onChange: setSyncFilter, size: "small" })), _jsx(ColumnSelect, { id: "publication-date", labelText: "Publication date", options: formattedPublicationDateOptions, value: dateFilter, onChange: setDateFilter, size: "small" }), pendingValue.publicationDate === 'custom-date' && (_jsxs("div", { children: [_jsx("div", Object.assign({ className: "mb-2" }, { children: _jsx(Label, Object.assign({ id: "date-filter-label" }, { children: "Choose a date range" })) })), _jsxs("div", Object.assign({ className: "flex flex-row gap-2" }, { children: [_jsx(ColumnDatePicker, { className: "w-1/2", format: "MMM dd", momentFormat: "MMM DD", placeholderText: "Start date", value: customStartDate, onChange: setCustomStartDate, shouldDisableDate: date => {
                                    return !!(date &&
                                        customEndDate &&
                                        calendarDaysApart(moment(date).startOf('day').toDate(), moment(customEndDate).startOf('day').toDate()) > 0);
                                } }), _jsx(ColumnDatePicker, { className: "w-1/2", format: "MMM dd", momentFormat: "MMM DD", placeholderText: "End date", value: customEndDate, onChange: setCustomEndDate, shouldDisableDate: date => {
                                    return !!(date &&
                                        customStartDate &&
                                        calendarDaysApart(moment(customStartDate).startOf('day').toDate(), moment(date).startOf('day').toDate()) > 0);
                                } })] }))] })), _jsxs("div", Object.assign({ className: "flex flex-row justify-between items-center mt-3" }, { children: [_jsx(ColumnButton, { buttonText: "Reset", onClick: handleResetClick, type: "button" }), _jsx(ColumnButton, { buttonText: "Apply", primary: true, disabled: !hasChange, onClick: handleApplyClick, type: "button" })] }))] })));
}
export default NoticesFilterDialog;
