var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { ArrowDownTrayIcon, ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import LoadingState from 'components/LoadingState';
import { Alert } from 'lib/components/Alert';
import { Badge } from 'lib/components/Badge';
import { ColumnButton } from 'lib/components/ColumnButton';
import { ColumnService } from 'lib/services/directory';
import { useContext, useState } from 'react';
import { logAndCaptureException } from 'utils';
import { isObituary } from 'lib/types/ad';
import useSafeAsyncEffect from 'lib/frontend/hooks/useSafeAsyncEffect';
import MultiStepHeader from '../../components/MultiStepHeader';
import downloadProof from '../../helpers/downloadProof';
import { getSoonestAdDeadline } from '../../helpers/soonestAdDeadline';
import NewspaperOrderSummary from './NewspaperOrderSummary';
import { NewspapersContext } from '../../contexts/NewspapersContext';
import OrderSummaryRow from './OrderSummaryRow';
import Pricing from './Pricing';
function OrderSummary({ newspaperOrdersFormData, adData, orderModel, filingTypeLabel, product, version, consolidatedOrderPricing }) {
    var _a, _b, _c;
    const [error, setError] = useState('');
    if (!consolidatedOrderPricing) {
        setError('Order pricing is not available. Please refresh the page.');
    }
    const totalInCents = consolidatedOrderPricing === null || consolidatedOrderPricing === void 0 ? void 0 : consolidatedOrderPricing.totalInCents;
    const newspapers = useContext(NewspapersContext);
    const newspaperOrderSummaries = newspaperOrdersFormData.map(setting => {
        var _a, _b;
        const newspaper = newspapers.find(snap => { var _a; return snap.id === ((_a = setting.newspaper) === null || _a === void 0 ? void 0 : _a.id); });
        if (!newspaper) {
            const err = new Error(`Newspaper ${(_a = setting.newspaper) === null || _a === void 0 ? void 0 : _a.id} not found`);
            logAndCaptureException(ColumnService.OBITS, err, 'Newspaper not found in OrderSummary', {
                newspaperId: (_b = setting.newspaper) === null || _b === void 0 ? void 0 : _b.id,
                service: ColumnService.OBITS
            });
            setError('Newspaper not found. Please refresh the page.');
            return null;
        }
        return { newspaper, publishingDates: setting.publishingDates };
    });
    const [loading, setLoading] = useState(false);
    const handleDownloadPDF = () => __awaiter(this, void 0, void 0, function* () {
        try {
            setLoading(true);
            yield downloadProof(orderModel.id, version);
        }
        catch (error) {
            logAndCaptureException(ColumnService.OBITS, error, 'Error downloading proofs', {
                orderId: orderModel.id
            });
            setError('Error downloading proofs. Please try again.');
        }
        finally {
            setLoading(false);
        }
    });
    const { value: soonestAdDeadline } = useSafeAsyncEffect({
        fetchData: () => getSoonestAdDeadline(product, newspaperOrdersFormData),
        dependencies: []
    });
    if (!soonestAdDeadline) {
        return _jsx(LoadingState, { context: { service: ColumnService.OBITS } });
    }
    const isEditing = orderModel.modelData.activeVersion !== version;
    return (_jsxs(_Fragment, { children: [error && _jsx(Alert, Object.assign({ id: "order-summary-error" }, { children: error })), isEditing && (_jsx(Alert, { id: "order-summary-warning", description: "Column will issue a refund for the full amount of the original payment and you will be charged the new amount.", status: "warning", icon: _jsx(ExclamationTriangleIcon, { className: "h-5 w-5" }) })), _jsx(MultiStepHeader, { title: "Order Summary", description: "Please review your order and confirm its details" }), _jsxs("div", Object.assign({ className: "grid grid-cols-1 divide-y" }, { children: [_jsxs("div", Object.assign({ className: "text-center m-4" }, { children: [_jsx("div", Object.assign({ className: "text-md font-semibold" }, { children: isObituary(adData) ? adData.deceasedName : adData.title })), ((_a = adData.orderImages) === null || _a === void 0 ? void 0 : _a[0]) && (_jsx("div", Object.assign({ className: "flex justify-center my-4 w-full" }, { children: _jsx("img", { src: adData.orderImages[0].imageUrl, className: "w-1/5" }) }))), _jsx("div", Object.assign({ className: "sm:mx-8 mt-4" }, { children: _jsx(ColumnButton, { type: "button", onClick: handleDownloadPDF, buttonText: `Download Proof${((_b = newspaperOrdersFormData.length) !== null && _b !== void 0 ? _b : 0) > 1 ? 's' : ''}`, startIcon: _jsx(ArrowDownTrayIcon, { className: "h-5 w-5" }), size: "lg", loading: loading, tertiary: true }) }))] })), isObituary(adData) && ((_c = adData.deathVerification) === null || _c === void 0 ? void 0 : _c.funeralHomeName) && (_jsx(OrderSummaryRow, { label: "Funeral Home", data: adData.deathVerification.funeralHomeName })), newspaperOrderSummaries.map((setting, i) => {
                        var _a;
                        return setting ? (_jsx(NewspaperOrderSummary, { newspaper: setting.newspaper, publishingDates: (_a = setting.publishingDates) !== null && _a !== void 0 ? _a : [], filingTypeLabel: filingTypeLabel }, i)) : null;
                    }), _jsx(OrderSummaryRow, { label: "Ad Deadline", data: _jsx(Badge, Object.assign({ status: "warning", size: "md" }, { children: soonestAdDeadline.format('MMM D, YYYY h:mm A z') })) }), _jsx(Pricing, { orderModel: orderModel, showComparison: isEditing, totalInCents: totalInCents })] }))] }));
}
export default OrderSummary;
