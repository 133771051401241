var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { Modal } from 'lib/components/Modal';
import { TextAreaField } from 'lib/components/TextAreaField';
import { useLoading } from 'lib/components/hooks/useLoading';
import { centsToDollarsString } from 'lib/helpers';
import React, { useState } from 'react';
import ToastActions from 'redux/toast';
import { useAppDispatch } from 'redux/hooks';
export function ConfirmCancellationModal({ onClose, onCancelOrder, order }) {
    var _a, _b;
    const dispatch = useAppDispatch();
    const [cancellationReason, setCancellationReason] = useState('');
    const [confirmCancelLoading, onConfirmCancelWithLoading] = useLoading();
    const refundAmount = (_b = (_a = order.modelData.pricing) === null || _a === void 0 ? void 0 : _a.totalInCents) !== null && _b !== void 0 ? _b : 0;
    const refundString = centsToDollarsString(refundAmount);
    return (_jsx(Modal, Object.assign({ id: "confirm-cancel-order-modal", onClose: onClose, title: "Cancel order", primaryAction: {
            id: 'confirm-cancel-order-button',
            buttonText: 'Cancel order',
            onClick: () => onConfirmCancelWithLoading(() => __awaiter(this, void 0, void 0, function* () {
                const { error } = yield onCancelOrder(cancellationReason);
                if (error) {
                    dispatch(ToastActions.toastError({
                        headerText: 'Error',
                        bodyText: 'There was an error cancelling your order. Please try again.'
                    }));
                }
                else {
                    dispatch(ToastActions.toastSuccess({
                        headerText: 'Success',
                        bodyText: 'Your order has been cancelled.'
                    }));
                }
                onClose();
            })),
            type: 'button',
            loading: confirmCancelLoading
        }, secondaryActions: [
            {
                id: 'discard-cancel-order-button',
                buttonText: 'Discard',
                onClick: onClose,
                type: 'button'
            }
        ] }, { children: _jsxs("div", Object.assign({ className: "flex flex-col gap-2" }, { children: [_jsxs("div", Object.assign({ className: "text-sm font-medium" }, { children: ["You are about to cancel order no. ", order.id, ". A refund of $", refundString, " will be issued."] })), _jsx(TextAreaField, { id: "order-cancellation-reason-input", labelText: "Reason for cancellation", onChange: value => setCancellationReason(value) })] })) })));
}
