import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { Search } from '../gifs';
import { ColumnButton } from '../ColumnButton';
import { LoadingSpinner } from '../LoadingSpinner';
import { SelectableHeaderCell, SelectableRowCell } from './Selectable';
import { ActionableHeaderCell, ActionableRowCell } from './Actionable';
const isReactNode = (node) => typeof node === 'object';
const ColumnHeader = ({ column }) => {
    if (isReactNode(column)) {
        return _jsx(_Fragment, { children: column });
    }
    return _jsx("th", Object.assign({ className: "font-medium" }, { children: column }));
};
const PAGE_SIZE = 6;
/**
 * Table component rendered inside of a table layout.
 * IMPORTANT: This component should not be rendered directly. Instead you should
 * always import the TableLayout as a wrapper.
 */
export default function DataTable({ onArchive, archivable, selectable, renderRow, clickable, editable, columns, search, data, id, noResultsContent, loading, pagination }) {
    const [pageNumber, setPageNumber] = useState(0);
    const pageSize = (pagination === null || pagination === void 0 ? void 0 : pagination.pageSize) || PAGE_SIZE;
    const pageSlice = data.slice(pageNumber * pageSize, (pageNumber + 1) * pageSize);
    const totalPages = Math.ceil(data.length / pageSize);
    function handlePageChange(pageNumber) {
        var _a;
        setPageNumber(pageNumber);
        (_a = pagination === null || pagination === void 0 ? void 0 : pagination.onPageChange) === null || _a === void 0 ? void 0 : _a.call(pagination, pageNumber);
    }
    useEffect(() => {
        handlePageChange(0);
    }, [search]);
    const renderActions = !!(editable || archivable);
    let colSpan = columns.length + 1;
    if (renderActions)
        colSpan += 1;
    return (_jsxs(_Fragment, { children: [_jsxs("table", Object.assign({ id: id, className: classNames('min-w-full divide-y', {
                    'divide-gray-200': pageSlice.length > 0,
                    'bg-white rounded-b-lg': pageSlice.length === 0
                }) }, { children: [_jsx("thead", { children: _jsxs("tr", Object.assign({ className: "h-18 border-t border-b text-left bg-base-2 uppercase text-column-gray-400 text-sm tracking-wider" }, { children: [_jsx("th", Object.assign({ className: "w-8" }, { children: " " })), _jsx(SelectableHeaderCell, { selectable: selectable, rows: pageSlice }), columns.map((column, i) => (_jsx(ColumnHeader, { column: column }, i))), _jsx(ActionableHeaderCell, { editable: editable, archivable: archivable })] })) }), _jsxs("tbody", Object.assign({ className: "divide-y divide-gray-200" }, { children: [loading && (_jsx("tr", { children: _jsx("td", Object.assign({ colSpan: colSpan, className: "py-8" }, { children: _jsx(LoadingSpinner, {}) })) })), pageSlice.length === 0 && !loading && (_jsx("tr", { children: _jsx("td", Object.assign({ colSpan: colSpan }, { children: _jsxs("div", Object.assign({ className: "mt-16 flex flex-col items-center justify-center" }, { children: [_jsxs("div", Object.assign({ className: "bg-column-yellow-300 h-26 w-26 rounded-full flex items-center justify-center p-1 overflow-hidden" }, { children: [(noResultsContent === null || noResultsContent === void 0 ? void 0 : noResultsContent.icon) && noResultsContent.icon, !(noResultsContent === null || noResultsContent === void 0 ? void 0 : noResultsContent.icon) && _jsx("img", { src: Search })] })), _jsx("div", Object.assign({ className: "my-3 text-column-gray-700 text-lg font-semibold" }, { children: (noResultsContent === null || noResultsContent === void 0 ? void 0 : noResultsContent.header)
                                                    ? noResultsContent.header
                                                    : 'No results found!' })), _jsx("div", Object.assign({ className: "mb-26 text-column-gray-400 text-sm font-medium w-100 text-center" }, { children: (noResultsContent === null || noResultsContent === void 0 ? void 0 : noResultsContent.subheader)
                                                    ? noResultsContent.subheader
                                                    : '' }))] })) })) })), pageSlice.length > 0 &&
                                !loading &&
                                pageSlice.map((rowData, i) => {
                                    const isRowClickable = clickable &&
                                        !(clickable.isClickDisabled &&
                                            clickable.isClickDisabled(rowData));
                                    return (_jsxs("tr", Object.assign({ className: classNames('bg-white h-20 text-sm leading-6 font-medium text-column-gray-400', {
                                            'hover:bg-base-2 cursor-pointer': isRowClickable
                                        }), onClick: () => {
                                            if (isRowClickable) {
                                                clickable.onClick(rowData);
                                            }
                                        } }, { children: [_jsx("td", { className: "w-8" }), _jsx(SelectableRowCell, { selectable: selectable, rowData: rowData }), renderRow(rowData), _jsx(ActionableRowCell, { archivable: archivable, editable: editable, rowData: rowData, index: i, onArchive: onArchive })] }), i));
                                })] }))] })), pageSlice.length > 0 && (_jsx("footer", Object.assign({ className: "rounded-b-lg bg-white py-0.5 border-t" }, { children: _jsxs("div", Object.assign({ className: "px-8 py-3.5 flex justify-between" }, { children: [_jsx("div", Object.assign({ className: "flex" }, { children: _jsx(ColumnButton, { buttonText: "Previous", disabled: pageNumber === 0, onClick: () => handlePageChange(pageNumber - 1), type: "button" }) })), _jsxs("div", Object.assign({ className: "py-2.5 leading-6 text-sm font-medium text-column-gray-400" }, { children: ["Page ", pageNumber + 1, " of ", totalPages] })), _jsx("div", Object.assign({ className: "flex" }, { children: _jsx(ColumnButton, { buttonText: "Next", disabled: pageNumber === totalPages - 1, onClick: () => handlePageChange(pageNumber + 1), type: "button" }) }))] })) })))] }));
}
