import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { DocumentArrowUpIcon } from '@heroicons/react/24/outline';
import { useDropzone } from 'react-dropzone';
import classNames from 'classnames';
import { Alert } from '../Alert';
import { ColumnButton } from '../ColumnButton';
import { getFileExtension } from '../../helpers';
export default function FileDropzone({ id, multiple = true, disabled = false, acceptFileTypes, loading = false, fullHeight = false, maxSizeBytes = Number.MAX_SAFE_INTEGER, onDrop }) {
    const { getRootProps, getInputProps, fileRejections } = useDropzone({
        accept: acceptFileTypes,
        maxSize: maxSizeBytes,
        onDrop,
        multiple,
        disabled
    });
    const invalidFileTypeRejections = fileRejections.filter(rejection => rejection.errors.some(error => error.code === 'file-invalid-type'));
    const invalidFileSizeRejections = fileRejections.filter(rejection => rejection.errors.some(error => error.code === 'file-too-large'));
    const uploaderClasses = classNames('flex w-full', {
        'h-full': fullHeight
    });
    return (_jsxs(_Fragment, { children: [_jsxs("div", Object.assign({}, getRootProps(), { className: uploaderClasses }, { children: [_jsx(ColumnButton, { id: `${id}-button`, secondary: true, fullWidth: true, dropzone: true, disabled: disabled, loading: loading, startIcon: _jsx(UploadIconContainer, {}), buttonText: _jsx(ButtonText, {}), type: "button" }), _jsx("input", Object.assign({ id: id, "aria-labelledby": `${id}-button`, disabled: disabled }, getInputProps()))] })), acceptFileTypes &&
                invalidFileTypeRejections &&
                invalidFileTypeRejections.length > 0 && (_jsx(UnsupportedFileTypeAlert, { acceptFileTypes: acceptFileTypes, rejectedFileTypes: invalidFileTypeRejections.map(rejection => `.${getFileExtension(rejection.file.name)}`) })), invalidFileSizeRejections && invalidFileSizeRejections.length > 0 && (_jsx(FileTooLargeAlert, { maxSizeBytes: maxSizeBytes }))] }));
}
function ButtonText() {
    return (_jsxs(_Fragment, { children: ["Click to upload", _jsx("span", Object.assign({ className: "text-grey-400 hidden md:inline" }, { children: " or drag and drop" }))] }));
}
function UploadIconContainer() {
    return (_jsx("div", Object.assign({ className: "flex justify-center rounded-full bg-white p-1" }, { children: _jsx(DocumentArrowUpIcon, { className: "h-5 w-5 text-primary-400" }) })));
}
function UnsupportedFileTypeAlert({ acceptFileTypes, rejectedFileTypes }) {
    const acceptedFilesList = Array.isArray(acceptFileTypes)
        ? acceptFileTypes.join(', ')
        : acceptFileTypes
            .replace(/\s+/g, '')
            .split(',')
            .filter(ext => ext.startsWith('.'))
            .join(', ');
    const rejectedFilesList = rejectedFileTypes.join(', ');
    return (_jsx("div", Object.assign({ className: "my-2" }, { children: _jsx(Alert, { id: "unsupported-file-type", status: "error", title: "File type not accepted", description: `This form does not accept these file types: ${rejectedFilesList}. Please upload one of the following file types: ${acceptedFilesList}` }) })));
}
function FileTooLargeAlert({ maxSizeBytes }) {
    return (_jsx("div", Object.assign({ className: "my-2" }, { children: _jsx(Alert, { id: "file-too-large", status: "error", title: "File too large", description: `This form only accepts files less than ${Math.round(maxSizeBytes / 1024)}kB` }) })));
}
