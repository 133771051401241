var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import 'lit-flatpickr';
import { html, LitElement, css } from 'lit';
import { property } from 'lit/decorators.js';
import moment from 'moment';
import { ColorsCss } from './styles';
const DEFAULT_FORMAT = 'MM/DD/YYYY';
export class MadlibDateInput extends LitElement {
    constructor() {
        super(...arguments);
        this.key = undefined;
        this.hint = '';
        this.value = '';
        this.format = DEFAULT_FORMAT;
    }
    _handleChange(e) {
        this.value = e.target.value || '';
        // Dispatch event that the template has changed and we should re-render the result
        const event = new CustomEvent('template-updated', {
            bubbles: true,
            composed: true
        });
        this.dispatchEvent(event);
    }
    _getInputWidth() {
        if (!this.value) {
            return '50px';
        }
        return `${this.value.length}ch`;
    }
    // Translates the moment date format to Flatpicker format
    _getFlatpickerFormat() {
        return this.format
            .replace('MMMM', 'F')
            .replace('MMM', 'M')
            .replace('MM', 'm')
            .replace('DD', 'd')
            .replace('DO', 'J')
            .replace('dddd', 'l')
            .replace('ddd', 'D')
            .replace('YYYY', 'Y')
            .replace('YY', 'y');
    }
    render() {
        return html ` <style>
        .myInput {
          width: ${this._getInputWidth()};
        }
      </style>
      <span>
        <madlib-hint text="${this.hint}"></madlib-hint>
        <lit-flatpickr
          altInput
          defaultDate="${this.value
            ? moment(this.value, this.format).valueOf()
            : ''}"
          position="above"
          altFormat=${this._getFlatpickerFormat()}
          dateFormat=${this._getFlatpickerFormat()}
          theme="material_blue"
          minDate=""
          maxDate=""
          ><input
            class="input_date myInput"
            placeholder="${DEFAULT_FORMAT}"
            @change=${this._handleChange}
        /></lit-flatpickr>
      </span>`;
    }
}
MadlibDateInput.TAG_NAME = 'madlib-date-input';
MadlibDateInput.styles = css `
    .input_date {
      margin: 0;
      padding: 0;
      outline: none;
      font-size: 10px;
      border-top-style: hidden;
      border-right-style: hidden;
      border-left-style: hidden;
      border-bottom-style: hidden;
    }

    lit-flatpickr {
      display: inline;
    }

    input::placeholder {
      font-weight: 500;
      color: ${ColorsCss.LIGHT_BLUE};
    }
  `;
__decorate([
    property()
], MadlibDateInput.prototype, "key", void 0);
__decorate([
    property()
], MadlibDateInput.prototype, "hint", void 0);
__decorate([
    property()
], MadlibDateInput.prototype, "value", void 0);
__decorate([
    property()
], MadlibDateInput.prototype, "format", void 0);
