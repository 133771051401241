var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import moment from 'moment';
import { isFeeSplitTypeFullWaiver } from '../types/feeSplit';
import { exists } from '../types';
import { getMailDataFromNoticeOrDraft } from '../mail';
import { getNoticeTypeFromNoticeData, sortNoticePublicationDatesChronologically } from '../helpers';
import { getCustomer } from '../notice/customer';
import { getOrThrow } from '../utils/refs';
const DEFAULT_AFFIDAVIT_FEE_IN_CENTS = 0;
/**
 * Returns the reconciliation settings affiliated with the newspaper. Goes in precendence
 * order of:
 * 1. What is set on the newspaper (if anything)
 * 2. What is set on the parent (if anything)
 * 3. Returns undefined if no settings on either
 */
export const getAffidavitSettingsForNewspaper = (newspaperSnap) => __awaiter(void 0, void 0, void 0, function* () {
    var _a, _b, _c, _d, _e, _f;
    if (!exists(newspaperSnap))
        return undefined;
    if ((_a = newspaperSnap.data()) === null || _a === void 0 ? void 0 : _a.affidavitReconciliationSettings) {
        return ((_b = newspaperSnap.data()) === null || _b === void 0 ? void 0 : _b.affidavitReconciliationSettings) || undefined;
    }
    const parentSnap = yield ((_d = (_c = newspaperSnap.data()) === null || _c === void 0 ? void 0 : _c.parent) === null || _d === void 0 ? void 0 : _d.get());
    if ((_e = parentSnap === null || parentSnap === void 0 ? void 0 : parentSnap.data()) === null || _e === void 0 ? void 0 : _e.affidavitReconciliationSettings) {
        return ((_f = parentSnap === null || parentSnap === void 0 ? void 0 : parentSnap.data()) === null || _f === void 0 ? void 0 : _f.affidavitReconciliationSettings) || undefined;
    }
    return undefined;
});
export const getAffidavitSettingsForNoticeData = (ctx, noticeData, options) => __awaiter(void 0, void 0, void 0, function* () {
    var _g;
    const newspaper = yield getOrThrow(noticeData.newspaper);
    const newspaperLevelAffidavitSettings = yield getAffidavitSettingsForNewspaper(newspaper);
    // If the newspaper doesn't do anything with verification, don't do anything!
    if (!newspaperLevelAffidavitSettings)
        return;
    // Allow for notice-specific settings for affidavit reconciliation
    const noticeLevelAffidavitSettings = noticeData.affidavitReconciliationSettings || {};
    // Allow for customer-specific settings to override newspaper settings for affidavit reconciliation
    let customerLevelAffidavitSettings = {};
    const filer = yield ((_g = noticeData.filer) === null || _g === void 0 ? void 0 : _g.get());
    if (exists(filer)) {
        const customerSnap = yield getCustomer(ctx, filer, newspaper);
        customerLevelAffidavitSettings =
            (customerSnap === null || customerSnap === void 0 ? void 0 : customerSnap.data().affidavitReconciliationSettings) || {};
    }
    // Allow for notice-type-specific settings
    const noticeType = yield getNoticeTypeFromNoticeData(noticeData, newspaper);
    const noticeTypeLevelAffidavitSettings = (noticeType === null || noticeType === void 0 ? void 0 : noticeType.affidavitReconciliationSettings) || {};
    return Object.assign(Object.assign(Object.assign(Object.assign({}, newspaperLevelAffidavitSettings), noticeTypeLevelAffidavitSettings), customerLevelAffidavitSettings), ((options === null || options === void 0 ? void 0 : options.skipNoticeLevelSettings) ? {} : noticeLevelAffidavitSettings));
});
/**
 * Returns the reconciliation settings affiliated with the notice. Goes in precendence
 * order of:
 * 1. What is set on the notice (if anything)
 * 2. What is set on the customer (if anything)
 * 3. What is set on the notice type (if anything)
 * 4. What is set on the publisher
 */
export const getAffidavitSettingsForNotice = (ctx, notice, options) => __awaiter(void 0, void 0, void 0, function* () {
    return getAffidavitSettingsForNoticeData(ctx, notice.data(), options);
});
/**
 * Notices may have an affidavit fee as well as per-mailed-affidavit fees.
 */
export const getAffidavitPricingData = (ctx, notice) => __awaiter(void 0, void 0, void 0, function* () {
    const settings = yield getAffidavitSettingsForNotice(ctx, notice);
    const mail = yield getMailDataFromNoticeOrDraft(notice.ref);
    return {
        settings,
        mail
    };
});
const getAffidavitFeeIsNumber = (affidavitFeeInCents) => {
    return typeof affidavitFeeInCents === 'number';
};
export const applyAffidavitFeeSplitToColumnAffidavitFeeInCents = (grossColumnAffidavitFeeInCents, feeSplit) => {
    if (!feeSplit || !feeSplit.type) {
        return {
            columnAffidavitFeeInCents: grossColumnAffidavitFeeInCents,
            feeSplit: 0
        };
    }
    const splitType = feeSplit.type;
    if (isFeeSplitTypeFullWaiver(feeSplit)) {
        return {
            columnAffidavitFeeInCents: 0,
            feeSplit: 0,
            amountWaived: grossColumnAffidavitFeeInCents
        };
    }
    const splitAmount = feeSplit.amount;
    if (splitAmount && typeof splitAmount !== 'number') {
        throw new Error(`Invalid split amount type: feeSplit is a ${typeof splitAmount} and should be a number`);
    }
    if (splitType === 'flat') {
        const columnAffidavitFeeInCents = Math.max(0, grossColumnAffidavitFeeInCents - splitAmount);
        return {
            columnAffidavitFeeInCents,
            feeSplit: grossColumnAffidavitFeeInCents - columnAffidavitFeeInCents
        };
    }
    if (splitType === 'percent') {
        const splitCap = feeSplit.cap;
        const splitAmountInCents = Math.round(grossColumnAffidavitFeeInCents * (splitAmount / 100));
        if (splitCap && splitAmountInCents > splitCap) {
            const columnAffidavitFeeInCents = Math.max(0, grossColumnAffidavitFeeInCents - splitCap);
            return {
                columnAffidavitFeeInCents,
                feeSplit: grossColumnAffidavitFeeInCents - columnAffidavitFeeInCents
            };
        }
        const columnAffidavitFeeInCents = Math.max(0, grossColumnAffidavitFeeInCents - splitAmountInCents);
        return {
            columnAffidavitFeeInCents,
            feeSplit: grossColumnAffidavitFeeInCents - columnAffidavitFeeInCents
        };
    }
    return {
        columnAffidavitFeeInCents: grossColumnAffidavitFeeInCents,
        feeSplit: 0
    };
};
export const getAffidavitFeeInCentsAndFeeSplitFromSettingsAndMail = (reconciliationSettings, mail) => {
    const { affidavitsManagedByColumn, automatedAffidavitFeeInCents, notarizationVendor, affidavitFeeSplit } = reconciliationSettings || {};
    const hasSpecifiedFee = getAffidavitFeeIsNumber(automatedAffidavitFeeInCents);
    const unitFee = hasSpecifiedFee
        ? automatedAffidavitFeeInCents
        : DEFAULT_AFFIDAVIT_FEE_IN_CENTS;
    if (affidavitsManagedByColumn) {
        // If we are manually sending affidavits, we charge per copy. Otherwise just charge once!
        const unitQuantity = notarizationVendor === 'manual'
            ? (mail || []).reduce((a, m) => a + (m.copies || 0), 0)
            : 1;
        // apply feeSplit if exists to column affidavit fee in cents
        let finalColumnAffidavitUnitFeeInCents = unitFee;
        let feeSplitAmountInCents = 0;
        let amountWaived;
        if ((affidavitFeeSplit === null || affidavitFeeSplit === void 0 ? void 0 : affidavitFeeSplit.feeSplit) && (affidavitFeeSplit === null || affidavitFeeSplit === void 0 ? void 0 : affidavitFeeSplit.feeSplit.type)) {
            const affidavitFeeWithFeeSplitAppliedResult = applyAffidavitFeeSplitToColumnAffidavitFeeInCents(unitFee, affidavitFeeSplit === null || affidavitFeeSplit === void 0 ? void 0 : affidavitFeeSplit.feeSplit);
            finalColumnAffidavitUnitFeeInCents =
                affidavitFeeWithFeeSplitAppliedResult.columnAffidavitFeeInCents;
            feeSplitAmountInCents = affidavitFeeWithFeeSplitAppliedResult.feeSplit;
            amountWaived = affidavitFeeWithFeeSplitAppliedResult.amountWaived;
        }
        const invoiceTrackedAffidavitFeeSplitResult = (affidavitFeeSplit === null || affidavitFeeSplit === void 0 ? void 0 : affidavitFeeSplit.feeSplit)
            ? Object.assign({ feeSplit: affidavitFeeSplit === null || affidavitFeeSplit === void 0 ? void 0 : affidavitFeeSplit.feeSplit, amountInCents: feeSplitAmountInCents }, (amountWaived && { amountWaived })) : undefined;
        return {
            affidavitFeeInCents: unitQuantity * finalColumnAffidavitUnitFeeInCents,
            invoiceTrackedAffidavitFeeSplitResult
        };
    }
    return {
        affidavitFeeInCents: 0,
        invoiceTrackedAffidavitFeeSplitResult: undefined
    };
};
export const getAffidavitFeeInCentsFromSettingsAndMail = (reconciliationSettings, mail) => {
    const { affidavitFeeInCents } = getAffidavitFeeInCentsAndFeeSplitFromSettingsAndMail(reconciliationSettings, mail);
    return affidavitFeeInCents;
};
export const getAffidavitFeeInCentsForNoticeData = (ctx, noticeData, mail) => __awaiter(void 0, void 0, void 0, function* () {
    const affidavitSettings = yield getAffidavitSettingsForNoticeData(ctx, noticeData);
    return getAffidavitFeeInCentsFromSettingsAndMail(affidavitSettings, mail);
});
export const getAffidavitFeeInCentsForNotice = (ctx, noticeSnap) => __awaiter(void 0, void 0, void 0, function* () {
    const mail = yield getMailDataFromNoticeOrDraft(noticeSnap.ref);
    return getAffidavitFeeInCentsForNoticeData(ctx, noticeSnap.data(), mail);
});
export const getHasAffidavitServiceStartedByLastPubDate = (reconciliationStartDate, noticePublicationDates) => {
    const sortedPublicationDates = sortNoticePublicationDatesChronologically(noticePublicationDates);
    const lastPublicationDate = sortedPublicationDates[sortedPublicationDates.length - 1].toDate();
    return moment(lastPublicationDate)
        .startOf('day')
        .isSameOrAfter(moment(reconciliationStartDate.toDate()).startOf('day'));
};
export const getShouldApplyColumnManagedAffidavitFees = (affidavitReconciliationSettings, noticePublicationDates) => {
    if (!affidavitReconciliationSettings) {
        return false;
    }
    if (!affidavitReconciliationSettings.affidavitsManagedByColumn) {
        return false;
    }
    if (!(noticePublicationDates === null || noticePublicationDates === void 0 ? void 0 : noticePublicationDates.length)) {
        return false;
    }
    const { reconciliationStartDate } = affidavitReconciliationSettings;
    /* If we have ARS but don't specific a reconciliationStartDate, we assume the fee applies to
    new invoices created regardless of last publication date */
    if (!reconciliationStartDate || !reconciliationStartDate.toDate()) {
        return true;
    }
    return getHasAffidavitServiceStartedByLastPubDate(reconciliationStartDate, noticePublicationDates);
};
