var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import moment from 'moment';
import { exists } from 'lib/types';
import * as EventTypes from 'lib/types/events';
import { OccupationType, SyncStatusType } from 'lib/enums';
import { awaitAllPromises, getFulfilled, isColumnUser, cdnIfy } from 'lib/helpers';
import { getFirebaseContext } from 'utils/firebase';
import { logAndCaptureMessage } from 'utils';
import { getSyncStatusCategory } from 'lib/utils/events';
import { getModelFromRef, getModelFromSnapshot } from 'lib/model';
import { NoteModel } from 'lib/model/objects/noteModel';
import { getOrThrow } from 'lib/utils/refs';
import { UserModel } from 'lib/model/objects/userModel';
import { RunStatusType } from 'lib/types/runs';
import { convertDateStringFormat } from 'lib/utils/dates';
const COLUMN_SOFTWARE = 'Column Software';
const getEventInitiatorString = (user, organizationName) => {
    if (!exists(user)) {
        return organizationName;
    }
    /**
     * If a member of Column Support has initiated an event by
     * shadowing the publisher or advertiser organization,
     * always show 'Column Support' in the event log
     * instead of the shadowed org
     */
    if (isColumnUser(user)) {
        return `${user.data().name} (${COLUMN_SOFTWARE})`;
    }
    return organizationName.length
        ? `${user.data().name} (${organizationName})`
        : `${user.data().name}`;
};
const getUserEmail = (user) => {
    if (!exists(user)) {
        return '';
    }
    return user.data().email;
};
export const renderNoticeSubmittedEvent = (event, { notice, newspaperName, advertiserSnap, advertiserOrgName }) => __awaiter(void 0, void 0, void 0, function* () {
    var _a;
    const { data: { submittedNoticeData } } = event;
    const { createdBy, filer } = notice.data();
    const submittedProofPath = submittedNoticeData === null || submittedNoticeData === void 0 ? void 0 : submittedNoticeData.proofStoragePath;
    const link = submittedProofPath
        ? { text: 'View proof.', url: cdnIfy(submittedProofPath) }
        : undefined;
    // If the created by field is the same as the filer,
    // then the filer submitted the notices on behalf of themself.
    if (createdBy && filer.id !== createdBy.id) {
        const publisher = yield createdBy.get();
        return {
            header: getEventInitiatorString(publisher, newspaperName),
            content: `Uploaded this notice for ${(_a = advertiserSnap === null || advertiserSnap === void 0 ? void 0 : advertiserSnap.data()) === null || _a === void 0 ? void 0 : _a.name}${advertiserOrgName.length ? ` (${advertiserOrgName})` : ''}.`,
            userEmail: getUserEmail(publisher),
            link
        };
    }
    return {
        header: getEventInitiatorString(advertiserSnap, advertiserOrgName),
        content: `Submitted this notice to ${newspaperName}.`,
        userEmail: getUserEmail(advertiserSnap),
        link
    };
});
const renderNoticeConfirmedEvent = (event, { newspaperName }) => __awaiter(void 0, void 0, void 0, function* () {
    const { confirmedBy } = event.data;
    if (confirmedBy) {
        const publisher = yield confirmedBy.get();
        return {
            header: getEventInitiatorString(publisher, newspaperName),
            content: `Confirmed the notice.`,
            userEmail: getUserEmail(publisher)
        };
    }
    return {
        header: `Notice was confirmed`
    };
});
const renderNoticeCancelledEvent = ({ notice, newspaperName, advertiserOrgName }) => __awaiter(void 0, void 0, void 0, function* () {
    const refreshedNotice = (yield getFirebaseContext()
        .userNoticesRef()
        .doc(notice.id)
        .get());
    const { noticeCancellationReason, cancelledBy } = refreshedNotice.data();
    const user = yield (cancelledBy === null || cancelledBy === void 0 ? void 0 : cancelledBy.get());
    if (user && exists(user)) {
        const orgName = (user === null || user === void 0 ? void 0 : user.data().occupation) === OccupationType.publishing.value
            ? newspaperName
            : advertiserOrgName;
        return {
            header: getEventInitiatorString(user, orgName),
            content: `Cancelled this notice, with reason "${noticeCancellationReason}".`,
            userEmail: getUserEmail(user)
        };
    }
    return {
        header: 'Notice cancelled',
        content: `Reason: "${noticeCancellationReason}".`
    };
});
const renderNoticeCancellationRequestedEvent = (event, { advertiserOrgName }) => __awaiter(void 0, void 0, void 0, function* () {
    const { requestedBy, cancellationReason } = event.data;
    const user = yield requestedBy.get();
    if (exists(user)) {
        return {
            header: getEventInitiatorString(user, advertiserOrgName),
            content: `Requested to cancel this notice, with the reason "${cancellationReason}"`,
            userEmail: getUserEmail(user)
        };
    }
    return {
        header: 'Cancellation requested',
        content: `Reason: "${cancellationReason}"`
    };
});
const renderNoticeRefundedEvent = ({ notice, newspaperName }) => __awaiter(void 0, void 0, void 0, function* () {
    var _b;
    const invoice = yield ((_b = notice.data().invoice) === null || _b === void 0 ? void 0 : _b.get());
    if (!exists(invoice)) {
        return {
            header: `Refund initiated.`
        };
    }
    const { refund_amount, paid_outside_stripe } = invoice.data();
    if (paid_outside_stripe || !refund_amount)
        return {
            header: newspaperName,
            content: `Initiated a refund.`
        };
    return {
        header: newspaperName,
        content: `Initiated a refund for $${(refund_amount / 100).toFixed(2)}.`
    };
});
const renderAffidavituploadedEvent = (event, { newspaperName }) => __awaiter(void 0, void 0, void 0, function* () {
    const { uploadedBy } = event.data;
    const publisher = yield (uploadedBy === null || uploadedBy === void 0 ? void 0 : uploadedBy.get());
    return {
        header: getEventInitiatorString(publisher, newspaperName),
        content: `Uploaded the affidavit.`,
        userEmail: getUserEmail(publisher)
    };
});
const renderAffidavitReuploadedEvent = (event, { newspaperName }) => __awaiter(void 0, void 0, void 0, function* () {
    const { uploadedBy } = event.data;
    const publisher = yield (uploadedBy === null || uploadedBy === void 0 ? void 0 : uploadedBy.get());
    return {
        header: getEventInitiatorString(publisher, newspaperName),
        userEmail: getUserEmail(publisher),
        content: `Uploaded a new affidavit.`
    };
});
export const renderInvoiceCreatedEvent = (event, { newspaperName }) => __awaiter(void 0, void 0, void 0, function* () {
    var _c;
    const { createdBy, invoice } = event.data;
    const invoiceSnapshot = yield (invoice === null || invoice === void 0 ? void 0 : invoice.get());
    const invoicePDFStoragePath = (_c = invoiceSnapshot === null || invoiceSnapshot === void 0 ? void 0 : invoiceSnapshot.data()) === null || _c === void 0 ? void 0 : _c.invoice_pdf;
    const link = invoicePDFStoragePath
        ? { text: 'View PDF.', url: cdnIfy(invoicePDFStoragePath) }
        : undefined;
    if (createdBy) {
        const publisher = yield createdBy.get();
        return {
            header: getEventInitiatorString(publisher, newspaperName),
            content: `Created an invoice.`,
            userEmail: getUserEmail(publisher),
            link
        };
    }
    return {
        header: newspaperName,
        content: `Created an invoice.`,
        link
    };
});
const renderPaymentProcessedEvent = ({ advertiserSnap, advertiserOrgName }) => __awaiter(void 0, void 0, void 0, function* () {
    return {
        header: getEventInitiatorString(advertiserSnap, advertiserOrgName),
        content: `Paid the invoice.`,
        userEmail: getUserEmail(advertiserSnap)
    };
});
const renderCheckPaymentProcessedEvent = ({ advertiserSnap, advertiserOrgName }) => __awaiter(void 0, void 0, void 0, function* () {
    return {
        header: getEventInitiatorString(advertiserSnap, advertiserOrgName),
        userEmail: getUserEmail(advertiserSnap),
        content: `Paid the invoice by check.`
    };
});
const renderCardPaymentProcessedEvent = ({ advertiserSnap, advertiserOrgName }) => __awaiter(void 0, void 0, void 0, function* () {
    return {
        header: getEventInitiatorString(advertiserSnap, advertiserOrgName),
        userEmail: getUserEmail(advertiserSnap),
        content: `Paid the invoice by card.`
    };
});
const renderACHPaymentProcessedEvent = ({ advertiserSnap, advertiserOrgName }) => __awaiter(void 0, void 0, void 0, function* () {
    return {
        header: getEventInitiatorString(advertiserSnap, advertiserOrgName),
        userEmail: getUserEmail(advertiserSnap),
        content: `Paid the invoice by ACH.`
    };
});
const renderPaymentInitiatedEvent = ({ advertiserSnap, advertiserOrgName }) => __awaiter(void 0, void 0, void 0, function* () {
    return {
        header: getEventInitiatorString(advertiserSnap, advertiserOrgName),
        userEmail: getUserEmail(advertiserSnap),
        content: `Initiated payment.`
    };
});
const renderPaymentProcessedManualEvent = (event, { newspaperName }) => __awaiter(void 0, void 0, void 0, function* () {
    var _d;
    const { user, invoice } = event.data;
    const publisherSnap = yield (user === null || user === void 0 ? void 0 : user.get());
    const invoiceSnap = yield invoice.get();
    if ((_d = invoiceSnap.data()) === null || _d === void 0 ? void 0 : _d.paidByBulkInvoice) {
        return {
            header: COLUMN_SOFTWARE,
            content: `Invoice paid by bulk invoice.`
        };
    }
    return {
        header: getEventInitiatorString(publisherSnap, newspaperName),
        content: `Marked an invoice as paid out of platform.`,
        userEmail: getUserEmail(publisherSnap)
    };
});
const renderPaymentNoteEvent = (event, { newspaperName }) => __awaiter(void 0, void 0, void 0, function* () {
    const { user, note } = event.data;
    const publisherSnap = yield (user === null || user === void 0 ? void 0 : user.get());
    return {
        header: getEventInitiatorString(publisherSnap, newspaperName),
        userEmail: getUserEmail(publisherSnap),
        content: `Recorded a payment note: ${note}`
    };
});
const renderInvoicePaidOutsideEvent = ({ newspaperName }) => __awaiter(void 0, void 0, void 0, function* () {
    return {
        header: newspaperName,
        content: `Marked the invoice as to be paid outside Column.`
    };
});
const renderInvoiceCancelledEvent = (event, { newspaperName }) => __awaiter(void 0, void 0, void 0, function* () {
    const { cancelledBy } = event.data;
    const publisher = yield (cancelledBy === null || cancelledBy === void 0 ? void 0 : cancelledBy.get());
    return {
        header: getEventInitiatorString(publisher, newspaperName),
        content: `Cancelled an invoice.`,
        userEmail: getUserEmail(publisher)
    };
});
const renderInvoiceRefundedEvent = (event, { newspaperName }) => __awaiter(void 0, void 0, void 0, function* () {
    const { cancelledBy, refundedBy, refundAmount, refundReason } = event.data;
    let initiator = yield (refundedBy === null || refundedBy === void 0 ? void 0 : refundedBy.get());
    if (!exists(initiator)) {
        initiator = yield (cancelledBy === null || cancelledBy === void 0 ? void 0 : cancelledBy.get());
    }
    return {
        header: getEventInitiatorString(initiator, newspaperName),
        content: `Initiated a refund for $${(refundAmount / 100).toFixed(2)}.${refundReason ? ` Reason: ${refundReason}` : ''}`,
        userEmail: getUserEmail(initiator)
    };
});
const renderInvoiceReminderSentEvent = (event, { newspaperName, advertiserOrgName }) => __awaiter(void 0, void 0, void 0, function* () {
    var _e;
    const { user, email, byMail } = event.data;
    const userSnap = yield user.get();
    const isPublisher = ((_e = userSnap === null || userSnap === void 0 ? void 0 : userSnap.data()) === null || _e === void 0 ? void 0 : _e.occupation) === OccupationType.publishing.value;
    const initiatedBy = getEventInitiatorString(userSnap, isPublisher ? newspaperName : advertiserOrgName);
    if (byMail && email)
        return {
            header: initiatedBy,
            userEmail: getUserEmail(userSnap),
            content: `Sent an invoice reminder to ${email} and by mail.`
        };
    if (byMail)
        return {
            header: initiatedBy,
            userEmail: getUserEmail(userSnap),
            content: `Sent an invoice reminder by mail${isPublisher && advertiserOrgName ? ` to ${advertiserOrgName}` : ''}.`
        };
    return {
        header: initiatedBy,
        userEmail: getUserEmail(userSnap),
        content: `Sent an invoice reminder to ${email}.`
    };
});
const renderInvoiceMailSent = ({ newspaperName }) => __awaiter(void 0, void 0, void 0, function* () {
    return {
        header: newspaperName,
        content: `Sent the invoice by mail.`
    };
});
export const renderNoticeEditedEvent = (event, { newspaperName, advertiserOrgName, advertiserSnap }) => __awaiter(void 0, void 0, void 0, function* () {
    var _f, _g;
    const { editedBy } = event.data;
    const editor = yield (editedBy === null || editedBy === void 0 ? void 0 : editedBy.get());
    const isPublisher = ((_f = editor === null || editor === void 0 ? void 0 : editor.data()) === null || _f === void 0 ? void 0 : _f.occupation) === OccupationType.publishing.value;
    const afterProofStoragePath = (_g = event.data.afterData) === null || _g === void 0 ? void 0 : _g.proofStoragePath;
    const link = afterProofStoragePath
        ? { text: 'View proof.', url: cdnIfy(afterProofStoragePath) }
        : undefined;
    if (editedBy && isPublisher) {
        return {
            header: getEventInitiatorString(editor, newspaperName),
            content: `Edited this notice.`,
            userEmail: getUserEmail(editor),
            link
        };
    }
    if (editor && !isPublisher) {
        return {
            header: getEventInitiatorString(advertiserSnap, advertiserOrgName),
            content: `Edited this notice.`,
            userEmail: getUserEmail(advertiserSnap),
            link
        };
    }
    return {
        header: getEventInitiatorString(advertiserSnap, advertiserOrgName),
        content: `Notice was edited.`,
        userEmail: getUserEmail(advertiserSnap),
        link
    };
});
// We only show payout/transfer events to publishers
const renderNoticeTransferredEvent = ({ isPublisher }) => __awaiter(void 0, void 0, void 0, function* () {
    return isPublisher ? { header: 'Payout initiated.' } : { header: null };
});
const renderTriggerForSyncEvent = (event) => __awaiter(void 0, void 0, void 0, function* () {
    switch (event.type) {
        case EventTypes.CUSTOM_ID_UPDATED_SYNC:
            return 'on Order Number Change';
        case EventTypes.INVOICE_CREATED_SYNC:
            return 'on Invoice Creation';
        case EventTypes.INVOICE_PAYMENT_INITIATED_SYNC:
            return 'on Payment Initiated';
        case EventTypes.INVOICE_PAID_OUTSIDE_SYNC:
        case EventTypes.INVOICE_PAYMENT_PROCESSED_SYNC:
        case EventTypes.INVOICE_PAYMENT_PROCESSED_MANUAL_SYNC:
            return 'on Payment';
        case EventTypes.INVOICE_REFUNDED_SYNC:
            return 'on Refund';
        case EventTypes.NOTICE_AT_DEADLINE_SYNC:
            return 'at Deadline';
        case EventTypes.NOTICE_CANCELLED_SYNC:
            return 'on Cancellation';
        case EventTypes.NOTICE_CONFIRMED_SYNC:
            return 'on Confirmation';
        case EventTypes.NOTICE_CREATED_SYNC:
            return 'on Submission';
        case EventTypes.NOTICE_EDITED_SYNC:
            return 'on Edit';
        case EventTypes.INVOICE_UPFRONT_PAYMENT_WAIVER_SYNC:
            return 'on Payment Terms Changed';
        case EventTypes.MANUAL_SYNC_REQUEST_SYNC:
        case EventTypes.MANUAL_BUILD_AD_REQUEST_SYNC:
        case EventTypes.MANUAL_CANCEL_BUILD_AD_REQUEST_SYNC:
            return 'on Request';
        default:
            return 'on Unknown Trigger';
    }
});
const getSyncResult = (statusValue) => {
    const syncStatusCategory = getSyncStatusCategory(statusValue);
    switch (syncStatusCategory.value) {
        case 1:
        case 5: {
            return 'Error';
        }
        case 2: {
            return 'Success';
        }
        case 4: {
            return 'Blocked';
        }
        default: {
            return '';
        }
    }
};
const renderSyncEvent = (event, { isPublisher }) => __awaiter(void 0, void 0, void 0, function* () {
    const syncStatus = SyncStatusType.by_value(event.data.syncStatus);
    if (!isPublisher || !syncStatus) {
        return { header: null };
    }
    const { syncMessage, syncWarnings } = event.data;
    if (!syncMessage) {
        return { header: null };
    }
    const syncTriggerLabel = yield renderTriggerForSyncEvent(event);
    const syncResult = getSyncResult(event.data.syncStatus);
    const header = `Sync ${syncTriggerLabel}${syncResult ? ':' : ''} ${syncResult}`;
    if (syncStatus.value === SyncStatusType.success_with_warning.value) {
        return {
            header,
            content: `${syncMessage} ${syncWarnings
                .map((warning, idx) => `${idx + 1}) ${warning.inAppMessage}`)
                .join(' ')}`
        };
    }
    return {
        header,
        content: `${syncStatus.label}: ${syncMessage !== null && syncMessage !== void 0 ? syncMessage : ''}`
    };
});
const renderNoticeAtDeadlineEvent = (event, { isPublisher }) => __awaiter(void 0, void 0, void 0, function* () {
    const firstPublicationDate = moment(event.data.firstPublicationDate.toDate()).format('D MMM YYYY');
    return isPublisher
        ? {
            header: 'Notice at Deadline',
            content: `For first publication date: ${firstPublicationDate}`
        }
        : { header: null };
});
const renderManualSyncRequestEvent = (event, { newspaperName, isPublisher }) => __awaiter(void 0, void 0, void 0, function* () {
    const requestedBy = yield event.data.requestedBy.get();
    return isPublisher
        ? {
            header: getEventInitiatorString(requestedBy, newspaperName),
            content: 'Requested to sync the notice.',
            userEmail: getUserEmail(requestedBy)
        }
        : { header: null };
});
const renderNoticeNoteAddedEvent = (event) => __awaiter(void 0, void 0, void 0, function* () {
    var _h;
    const Note = yield getModelFromRef(NoteModel, getFirebaseContext(), event.data.note);
    const noteCreator = yield getOrThrow(Note.modelData.noteCreator);
    const noteCreatorOrganization = yield getOrThrow(Note.modelData.noteCreatorOrganization);
    const link = ((_h = Note.modelData.attachments) === null || _h === void 0 ? void 0 : _h.length)
        ? {
            text: 'View Attachment.',
            url: Note.modelData.attachments[0].downloadURL
        }
        : undefined;
    return {
        header: getEventInitiatorString(noteCreator, noteCreatorOrganization.data().name),
        content: `Note: ${Note.modelData.content}`,
        userEmail: getUserEmail(noteCreator),
        link
    };
});
const renderRunStatusChangeEvent = (event, { newspaperName }) => __awaiter(void 0, void 0, void 0, function* () {
    var _j, _k, _l;
    const { status } = event;
    if (![
        RunStatusType.MANUALLY_VERIFIED,
        RunStatusType.OCR_VERIFICATION_SUCCEEDED
    ].includes(status)) {
        return { header: null };
    }
    const run = yield event.ref.get();
    if (!exists(run)) {
        return { header: null };
    }
    const runDate = convertDateStringFormat(run.data().publicationDate, {
        from: 'YYYY-MM-DD',
        to: 'D MMM YYYY'
    });
    const changedBy = yield ((_j = event.statusChangedBy) === null || _j === void 0 ? void 0 : _j.get());
    const eedition = yield ((_l = (_k = event.data) === null || _k === void 0 ? void 0 : _k.eedition) === null || _l === void 0 ? void 0 : _l.get());
    const includeEEditionLink = exists(eedition);
    const header = getEventInitiatorString(changedBy, newspaperName);
    const content = `Verified publication for ${runDate}.`;
    const link = includeEEditionLink
        ? {
            text: 'View E-Edition.',
            url: cdnIfy(eedition.data().storagePath, {
                useImgix: true
            })
        }
        : undefined;
    return { header, content, link };
});
export const getEventDescription = (event, settings) => __awaiter(void 0, void 0, void 0, function* () {
    switch (event.type) {
        // Notice events
        case EventTypes.NOTICE_SUBMITTED_EVENT:
            return yield renderNoticeSubmittedEvent(event, settings);
        case EventTypes.NOTICE_CONFIRMED:
            return yield renderNoticeConfirmedEvent(event, settings);
        case EventTypes.NOTICE_EDITED:
            return yield renderNoticeEditedEvent(event, settings);
        case EventTypes.AFFIDAVIT_UPLOADED:
            return yield renderAffidavituploadedEvent(event, settings);
        case EventTypes.AFFIDAVIT_RE_UPLOADED:
            return yield renderAffidavitReuploadedEvent(event, settings);
        case EventTypes.NOTICE_CANCELLED:
            return yield renderNoticeCancelledEvent(settings);
        case EventTypes.NOTICE_CANCELLATION_REQUESTED:
            return yield renderNoticeCancellationRequestedEvent(event, settings);
        case EventTypes.NOTICE_CANCELLED_AND_REFUNDED:
            return yield renderNoticeRefundedEvent(settings);
        case EventTypes.NOTICE_TRANSFERRED:
            return yield renderNoticeTransferredEvent(settings);
        // Sync events
        case EventTypes.NOTICE_SYNC_EVENT:
            return settings.isPublisher
                ? { header: `Notice Synced.` }
                : { header: null };
        case EventTypes.NOTICE_SYNC_FAILURE_EVENT:
            return settings.isPublisher
                ? { header: `Notice Failed to Sync.` }
                : { header: null };
        // Invoice events
        case EventTypes.INVOICE_MAIL_SENT_EVENT:
            return yield renderInvoiceMailSent(settings);
        case EventTypes.INVOICE_CREATED:
            return yield renderInvoiceCreatedEvent(event, settings);
        case EventTypes.INVOICE_REMINDER_SENT:
            return yield renderInvoiceReminderSentEvent(event, settings);
        case EventTypes.INVOICE_PAYMENT_PROCESSED:
            switch (event.data.paymentMethod) {
                case 'ach':
                    return yield renderACHPaymentProcessedEvent(settings);
                case 'card':
                    return yield renderCardPaymentProcessedEvent(settings);
                case 'check':
                    return yield renderCheckPaymentProcessedEvent(settings);
                default:
                    return yield renderPaymentProcessedEvent(settings);
            }
        case EventTypes.INVOICE_PAYMENT_INITIATED:
            return yield renderPaymentInitiatedEvent(settings);
        case EventTypes.INVOICE_PAYMENT_PROCESSED_MANUAL:
            return yield renderPaymentProcessedManualEvent(event, settings);
        case EventTypes.INVOICE_PAYMENT_NOTE:
            return yield renderPaymentNoteEvent(event, settings);
        case EventTypes.INVOICE_PAID_OUTSIDE:
            return renderInvoicePaidOutsideEvent(settings);
        case EventTypes.INVOICE_CANCELLED:
            return yield renderInvoiceCancelledEvent(event, settings);
        case EventTypes.INVOICE_REFUNDED:
            return yield renderInvoiceRefundedEvent(event, settings);
        case EventTypes.NOTICE_NOTE_ADDED:
            return yield renderNoticeNoteAddedEvent(event);
        case EventTypes.NOTICE_AT_DEADLINE_SYNC:
        case EventTypes.NOTICE_CANCELLED_SYNC:
        case EventTypes.NOTICE_CONFIRMED_SYNC:
        case EventTypes.NOTICE_CREATED_SYNC:
        case EventTypes.NOTICE_EDITED_SYNC:
        case EventTypes.INVOICE_CREATED_SYNC:
        case EventTypes.INVOICE_PAID_OUTSIDE_SYNC:
        case EventTypes.INVOICE_PAYMENT_INITIATED_SYNC:
        case EventTypes.INVOICE_PAYMENT_PROCESSED_MANUAL_SYNC:
        case EventTypes.INVOICE_PAYMENT_PROCESSED_SYNC:
        case EventTypes.INVOICE_REFUNDED_SYNC:
        case EventTypes.INVOICE_UPFRONT_PAYMENT_WAIVER_SYNC:
        case EventTypes.CUSTOM_ID_UPDATED_SYNC:
        case EventTypes.MANUAL_SYNC_REQUEST_SYNC:
        case EventTypes.MANUAL_BUILD_AD_REQUEST_SYNC:
        case EventTypes.MANUAL_CANCEL_BUILD_AD_REQUEST_SYNC:
            return yield renderSyncEvent(event, settings);
        case EventTypes.NOTICE_AT_DEADLINE:
            return yield renderNoticeAtDeadlineEvent(event, settings);
        case EventTypes.MANUAL_SYNC_REQUEST:
            return yield renderManualSyncRequestEvent(event, settings);
        case EventTypes.RUN_STATUS_CHANGE:
            return yield renderRunStatusChangeEvent(event, settings);
        default:
            return { header: null };
    }
});
export const getEventDataFromEventSnaps = (noticeEventSnaps, notice, user) => __awaiter(void 0, void 0, void 0, function* () {
    const User = getModelFromSnapshot(UserModel, getFirebaseContext(), user);
    const advertiserSnap = yield getOrThrow(notice.data().filer);
    const publisherOrganization = yield getOrThrow(notice.data().newspaper);
    const advertiserOrganization = notice.data().filedBy
        ? yield getOrThrow(notice.data().filedBy)
        : null;
    const advertiserOrgName = (advertiserOrganization === null || advertiserOrganization === void 0 ? void 0 : advertiserOrganization.data().name) ||
        advertiserSnap.data().organizationName ||
        '';
    const newspaperName = publisherOrganization.data().name || '';
    const timezone = publisherOrganization.data().iana_timezone || '';
    const settings = {
        advertiserOrgName,
        advertiserSnap,
        newspaperName,
        timezone,
        notice,
        user,
        isPublisher: User.isPublisher
    };
    // Add a notice submitted event if it doesn't exist
    const eventSnaps = noticeEventSnaps.slice();
    const hasNoticeSubmitted = eventSnaps === null || eventSnaps === void 0 ? void 0 : eventSnaps.some(e => e.data().type === EventTypes.NOTICE_SUBMITTED_EVENT);
    if (!hasNoticeSubmitted) {
        const fakeEvent = {
            createdAt: notice.data().createTime || notice.data().confirmedAt,
            type: EventTypes.NOTICE_SUBMITTED_EVENT,
            notice: notice.ref,
            data: {
                newspaper: notice.data().newspaper,
                filer: notice.data().filer,
                publicationDates: notice.data().publicationDates,
                submittedNoticeData: {}
            }
        };
        // TODO: We really should not be doing this! If we want to fake a snapshot
        // we could use a SnapshotModel with a bogus path/id but that's also a bad
        // idea.
        eventSnaps.push({
            data: () => fakeEvent
        });
    }
    const unsortedEvents = yield awaitAllPromises(eventSnaps.map((eventSnap) => __awaiter(void 0, void 0, void 0, function* () {
        const event = eventSnap.data();
        const eventDescription = yield getEventDescription(eventSnap.data(), settings);
        return Object.assign(Object.assign({}, eventDescription), { hidden: event.hidden, timestamp: event.createdAt, dateString: moment(event.createdAt.toMillis())
                .tz(settings.timezone || 'America/Chicago')
                .format('D MMM YYYY [at] LT z'), type: event.type });
    })));
    const noticeEvents = getFulfilled(unsortedEvents);
    const sortedEvents = noticeEvents.sort((event1, event2) => {
        var _a, _b;
        if (event1.type === EventTypes.NOTICE_SUBMITTED_EVENT)
            return -1;
        if (event2.type === EventTypes.NOTICE_SUBMITTED_EVENT)
            return 1;
        if (event1.timestamp && event2.timestamp) {
            if (event1.timestamp.toMillis() > event2.timestamp.toMillis())
                return 1;
            if (event1.timestamp.toMillis() < event2.timestamp.toMillis())
                return -1;
        }
        else {
            logAndCaptureMessage('Event does not have createdAt property', {
                event1Link: (_a = event1.link) === null || _a === void 0 ? void 0 : _a.url,
                event2Link: (_b = event2.link) === null || _b === void 0 ? void 0 : _b.url
            });
        }
        return 0;
    });
    const filteredEvents = sortedEvents.filter(event => event.header !== null);
    return filteredEvents;
});
