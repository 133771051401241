import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { ColumnSelect } from 'lib/components/ColumnSelect';
function NumberQuestionInput({ question, value, onChange }) {
    var _a;
    const defaultValue = (_a = question === null || question === void 0 ? void 0 : question.default) !== null && _a !== void 0 ? _a : question.min;
    const valueString = value !== undefined ? `${value}` : undefined;
    const calculateOptions = () => {
        const res = [];
        for (let i = question.min; i <= question.max; i++) {
            res.push({
                label: `${i}`,
                value: `${i}`
            });
        }
        return res;
    };
    const options = calculateOptions();
    return (_jsx("div", { children: _jsx(ColumnSelect, { id: `number-${question.varName}`, allowUndefined: true, options: options, value: valueString, onChange: v => {
                var _a;
                onChange({
                    value: (_a = parseInt(v, 10)) !== null && _a !== void 0 ? _a : defaultValue,
                    varName: question.varName
                });
            }, labelText: question.prompt }) }));
}
export default NumberQuestionInput;
