var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { SnapshotModel } from '..';
import { Collections } from '../../constants';
export class PublicationIssueAttachmentModel extends SnapshotModel {
    get type() {
        return Collections.publicationIssueAttachments;
    }
    updateMetadata(name, value) {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.update({
                metadata: Object.assign(Object.assign({}, this.modelData.metadata), { [name]: value })
            });
        });
    }
    updateStatus(status) {
        return __awaiter(this, void 0, void 0, function* () {
            if (this.modelData.status === status) {
                return;
            }
            yield this.update({
                status
            });
        });
    }
    update(requestedParams) {
        const _super = Object.create(null, {
            update: { get: () => super.update }
        });
        return __awaiter(this, void 0, void 0, function* () {
            // TODO(goodpaul): Abstract this to be used by publicationIssue and publicationIssueAttachment
            const params = Object.assign(Object.assign({}, requestedParams), { 
                // Need to figure out how to use the EDocumentData/EPartialDocumentData to stop using as any
                modifiedAt: this.ctx.fieldValue().serverTimestamp() });
            if (params.status) {
                params.statusChanges = [
                    ...(this.modelData.statusChanges || []),
                    {
                        status: params.status,
                        // TODO(goodpaul): Move these to a subcollection so we can use the proper fieldValue timestamp
                        timestamp: this.ctx.timestamp()
                    }
                ];
            }
            yield _super.update.call(this, params);
        });
    }
}
