var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { isAnonymousOrder } from 'lib/types/order';
import { getModelFromSnapshot } from 'lib/model';
import { OrganizationModel } from 'lib/model/objects/organizationModel';
import { getFirebaseContext } from 'utils/firebase';
import { PublishingMedium } from 'lib/enums/PublishingMedium';
import { logAndCaptureMessage } from 'utils';
import { safeGetOrThrow } from 'lib/safeWrappers';
import { wrapError, wrapSuccess } from 'lib/types/responses';
import CategoryChoiceForm from './CategoryChoiceForm';
import { shouldExcludeNewspaperOrder } from './helpers';
function CategoryChoiceStep({ inputData, onInputDataChange, newspaperOrdersFormData, onSetNewspaperOrdersFormData, product, orderModel, onUserAlertChange }) {
    const context = getFirebaseContext();
    // Check if the newspaper supports the selected filing type
    const processNewspaperOrder = (newspaperOrder, filingType, product, anonymousOrder) => __awaiter(this, void 0, void 0, function* () {
        var _a, _b;
        const newspaperRef = newspaperOrder.newspaper;
        const { response: newspaperSnapshot, error: getNewspaperSnapshotError } = yield safeGetOrThrow(newspaperRef);
        if (getNewspaperSnapshotError) {
            logAndCaptureMessage(getNewspaperSnapshotError.message, {
                addNewspaper: (_a = newspaperOrder.newspaper) === null || _a === void 0 ? void 0 : _a.id
            });
            return wrapSuccess({ newspaperOrder, include: false });
        }
        const organizationModel = getModelFromSnapshot(OrganizationModel, context, newspaperSnapshot);
        const { response: matchedFilingType, error: filingTypeMatchError } = yield organizationModel.isFilingTypeAvailableForNewspaper({
            selectedFilingType: filingType,
            product,
            publishingMedium: PublishingMedium.Print,
            anonymousOrder
        });
        //  Remove the newspaper from the order if the filing type is unavailable
        if (shouldExcludeNewspaperOrder({
            matchedFilingType,
            filingTypeMatchError,
            anonymousOrder
        })) {
            onUserAlertChange(prevAlerts => [
                ...prevAlerts,
                `${organizationModel.modelData.name} does not support ${filingType}. It has been removed from your order. Please select another publisher or another category.`
            ]);
            return wrapSuccess({ newspaperOrder, include: false });
        }
        if (filingTypeMatchError) {
            logAndCaptureMessage(filingTypeMatchError.message, {
                addNewspaper: (_b = newspaperOrder.newspaper) === null || _b === void 0 ? void 0 : _b.id
            });
            wrapError(filingTypeMatchError);
        }
        // Otherwise, include the newspaper in the list
        return wrapSuccess({ newspaperOrder, include: !!matchedFilingType });
    });
    const handleFilingTypeChange = (filingType) => __awaiter(this, void 0, void 0, function* () {
        const anonymousOrder = isAnonymousOrder(orderModel.modelData);
        // update the filing type in the order
        onInputDataChange(Object.assign(Object.assign({}, inputData), { filingTypeLabel: filingType }));
        /*
         * If any newspapers have been selected, verify if each newspaper supports
         * the newly selected filing type. This verification is necessary when a user
         * selects one or more newspapers, navigates back to the category selection page,
         * and changes the filing type. Each selected newspaper must support the new
         * filing type to remain valid.
         */
        if (newspaperOrdersFormData.length > 0) {
            let results;
            try {
                results = yield Promise.all(newspaperOrdersFormData.map((newspaperOrder) => __awaiter(this, void 0, void 0, function* () {
                    var _c, _d;
                    try {
                        const { response: processedResult, error: processedError } = yield processNewspaperOrder(newspaperOrder, filingType, product, anonymousOrder);
                        if (processedError) {
                            logAndCaptureMessage(processedError, {
                                addNewspaper: (_c = newspaperOrder.newspaper) === null || _c === void 0 ? void 0 : _c.id,
                                filingType
                            });
                            return wrapError(processedError);
                        }
                        return wrapSuccess({
                            newspaperOrder: processedResult.newspaperOrder,
                            include: processedResult.include
                        });
                    }
                    catch (error) {
                        logAndCaptureMessage('Error checking if newspaper supports filing type', {
                            addNewspaper: (_d = newspaperOrder.newspaper) === null || _d === void 0 ? void 0 : _d.id,
                            filingType
                        });
                        return wrapError(new Error('Error checking if newspaper supports filing type'));
                    }
                })));
            }
            catch (error) {
                logAndCaptureMessage('There was an error handling filing type change change', {
                    filingType
                });
                throw error;
            }
            const updatedNewspaperOrdersFormData = results
                .filter(({ response, error }) => {
                return response !== null && response.include && !error;
            })
                .map(({ response, error }) => {
                if (error) {
                    logAndCaptureMessage(error.message, {
                        filingType
                    });
                    throw error;
                }
                return response.newspaperOrder;
            });
            onSetNewspaperOrdersFormData(updatedNewspaperOrdersFormData);
        }
    });
    return (_jsxs("div", Object.assign({ className: "flex flex-col items-center p-4" }, { children: [_jsx("h2", Object.assign({ className: "text-xl font-medium mb-4" }, { children: "Choose Category" })), _jsx("div", Object.assign({ className: "grid gap-4 w-full md:w-2/3 lg:w-1/2" }, { children: _jsx(CategoryChoiceForm, { onCategoryChange: handleFilingTypeChange, category: inputData.filingTypeLabel }) }))] })));
}
export default CategoryChoiceStep;
