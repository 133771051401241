import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { TableLayout } from 'lib/components/TableLayout';
import { ObituariesOverviewRow } from './ObituariesOverviewRow';
export function ObituariesOverviewTable(props) {
    const columns = ['Title', 'Customer Name', 'Deceased Name', 'Funeral Home'];
    const { obituaries, loading } = props;
    return (_jsx("div", Object.assign({ id: "obituaries-table", className: "overflow-scroll" }, { children: _jsx(TableLayout, { filterable: {
                searchEnabled: false,
                shouldShowTableItem: () => true
            }, loading: loading, renderRow: obituary => _jsx(ObituariesOverviewRow, { obituary: obituary }), header: {
                title: 'Obituaries',
                subtitle: 'All obituaries for this publication issue'
            }, columns: columns, data: obituaries, pagination: { pageSize: 15 } }) })));
}
