var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { SnapshotModel, getModelFromRef } from '..';
import { Collections } from '../../constants';
import { OrderModel } from './orderModel';
export class ObituaryModel extends SnapshotModel {
    get type() {
        return Collections.obituaries;
    }
    get isVerified() {
        const { deathVerification } = this.modelData;
        return !!deathVerification.verifiedAt;
    }
    getOrder() {
        return __awaiter(this, void 0, void 0, function* () {
            return getModelFromRef(OrderModel, this.ctx, this.modelData.order);
        });
    }
    setVerificationStatus(isVerified) {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.update({
                'deathVerification.verifiedAt': isVerified
                    ? this.ctx.fieldValue().serverTimestamp()
                    : this.ctx.fieldValue().delete()
            });
        });
    }
    // TODO(goodpaul): Move modifiedAt update to the SnapshotModel
    update(requestedParams) {
        const _super = Object.create(null, {
            update: { get: () => super.update }
        });
        return __awaiter(this, void 0, void 0, function* () {
            const params = Object.assign(Object.assign({}, requestedParams), { modifiedAt: this.ctx.fieldValue().serverTimestamp() });
            yield _super.update.call(this, params);
        });
    }
}
