var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { PublicationIssueModel } from './publicationIssueModel';
import { SnapshotModel, getModelFromRef } from '..';
import { Collections } from '../../constants';
import { getResponses, wrapError, wrapSuccess } from '../../types/responses';
import { asyncFilter } from '../../helpers';
import { NoticeStatusType } from '../../enums';
import { affidavitsAreManagedByColumnForNotice, isAffidavitDisabled } from '../../affidavits';
export class EEditionModel extends SnapshotModel {
    get type() {
        return Collections.eeditions;
    }
    getPublicationIssue() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const { publicationIssue: publicationIssueRef } = this.modelData;
                const publicationIssue = yield getModelFromRef(PublicationIssueModel, this.ctx, publicationIssueRef);
                return wrapSuccess(publicationIssue);
            }
            catch (err) {
                return wrapError(err);
            }
        });
    }
    getRunsToVerify() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const publicationIssueResp = yield this.getPublicationIssue();
                if (publicationIssueResp.error) {
                    return wrapError(publicationIssueResp.error);
                }
                const unfilteredRunsResp = yield publicationIssueResp.response.getRuns();
                if (unfilteredRunsResp.error) {
                    return wrapError(unfilteredRunsResp.error);
                }
                const unfilteredNoticesResps = yield Promise.all(unfilteredRunsResp.response.map(run => run.getNotice()));
                const noticeError = unfilteredNoticesResps.find(resp => !!resp.error);
                if (noticeError === null || noticeError === void 0 ? void 0 : noticeError.error) {
                    return wrapError(noticeError.error);
                }
                const unfilteredNotices = getResponses(unfilteredNoticesResps);
                const noticesToVerifyResp = yield asyncFilter(unfilteredNotices, (notice) => __awaiter(this, void 0, void 0, function* () {
                    // If the notice already has an affidavit (or is cancelled), then we don't need to verify it
                    const { noticeStatus } = notice.modelData;
                    if (noticeStatus !== NoticeStatusType.pending.value) {
                        return null;
                    }
                    // If the affidavit is not managed by column, then we don't need to verify it
                    if (!(yield affidavitsAreManagedByColumnForNotice(this.ctx, notice))) {
                        return null;
                    }
                    // If the affidavit is disabled, then we don't need to verify it
                    const newspaperSnap = yield notice.modelData.newspaper.get();
                    if (isAffidavitDisabled(notice, newspaperSnap)) {
                        return null;
                    }
                    return notice;
                }));
                if (noticesToVerifyResp.error) {
                    return wrapError(noticesToVerifyResp.error);
                }
                const runsToVerify = noticesToVerifyResp.response.map(notice => {
                    const run = unfilteredRunsResp.response.find(run => run.modelData.notice.id === notice.id);
                    // This shouldn't happen since the list of notices is derived from the list of runs to begin with,
                    // but the check helpe the compiler type the run correctly
                    if (!run) {
                        throw new Error('Run not found for notice');
                    }
                    return run;
                });
                return wrapSuccess(runsToVerify);
            }
            catch (err) {
                return wrapError(err);
            }
        });
    }
    updateStatus(status) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                yield this.update({
                    status
                });
                return wrapSuccess(undefined);
            }
            catch (err) {
                return wrapError(err);
            }
        });
    }
}
