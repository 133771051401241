import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { ColumnSelect } from 'lib/components/ColumnSelect';
function YesNoQuestionInput({ question, value, onChange }) {
    const yesNoValue = value !== undefined ? (value ? 'yes' : 'no') : undefined;
    return (_jsx("div", { children: _jsx(ColumnSelect, { id: `yesno-${question.varName}`, allowUndefined: true, options: [
                { label: 'Yes', value: 'yes' },
                { label: 'No', value: 'no' }
            ], value: yesNoValue, onChange: v => onChange({ varName: question.varName, value: v === 'yes' }), labelText: question.prompt }) }));
}
export default YesNoQuestionInput;
