var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { getActiveDiscountConfigForCustomer } from 'lib/notice/discounts';
import { createDBPricingObjectFromData } from 'lib/pricing';
import { getOrThrow } from 'lib/utils/refs';
/**
 * Generate a DBPricingObj based on the data in the placement flow, if available.
 */
export const createDbPricingObjFromPlacement = (placement, productType) => __awaiter(void 0, void 0, void 0, function* () {
    var _a, _b, _c, _d, _e, _f;
    if (!placement.rate) {
        throw new Error('Cannot price before placement.rate is set');
    }
    if (!placement.newspaper) {
        throw new Error('Cannot price before placement.newspaper is set');
    }
    if (!((_a = placement.publicationDates) === null || _a === void 0 ? void 0 : _a.length)) {
        throw new Error('Cannot price before placement.publicationDates is set');
    }
    if (typeof placement.noticeType !== 'number') {
        throw new Error('Cannot price before placement.noticeType is set');
    }
    const rateSnap = yield getOrThrow(placement.rate);
    const rate = rateSnap.data();
    const newspaperSnap = yield getOrThrow(placement.newspaper);
    const newspaper = newspaperSnap.data();
    const customer = yield ((_b = placement.customer) === null || _b === void 0 ? void 0 : _b.get());
    const customerOrg = yield ((_c = placement.customerOrganization) === null || _c === void 0 ? void 0 : _c.get());
    const discountConfig = getActiveDiscountConfigForCustomer(customer, customerOrg, new Date());
    return createDBPricingObjectFromData(productType, {
        noticeType: placement.noticeType,
        publicationDates: placement.publicationDates,
        columns: (_d = placement.columns) !== null && _d !== void 0 ? _d : 1
    }, newspaper, rate, (_e = placement.displayParams) !== null && _e !== void 0 ? _e : undefined, {
        settings: undefined,
        mail: (_f = placement.mail) !== null && _f !== void 0 ? _f : []
    }, discountConfig);
});
