import { isNoticeRate } from '../types/rates';
// (3.1, .25) => 3.25, (3.1, 0) => 3.1, (3.0, 0.25) => 3.0
export const roundUp = (x, r) => r ? Math.ceil(x / r) * r : x;
// Based on the following rate sheet: https://michiganpress.org/wp-content/uploads/2021/03/Long-Folio-Chart.pdf
export const getFolios = (words) => {
    const folios = Math.ceil(words / 100);
    return folios;
};
export const getColumnInches = (actualHeight, columns, roundOff) => {
    const possiblyRoundedHeight = roundUp(actualHeight, roundOff);
    return possiblyRoundedHeight * columns;
};
export const getColumnCentimeters = (height, columns, roundOff) => {
    return getColumnInches(height, columns, roundOff) * 2.54;
};
/**
 * Safely check if a maybe-number is a number (and not NaN or Infinity).
 */
export const valueIsNumber = (val) => {
    return typeof val === 'number' && !Number.isNaN(val) && Number.isFinite(val);
};
export const getOffset = (rate) => (isNoticeRate(rate) && rate.offset) || 0;
export const getLinesPerInch = (rate, rateIdentifier) => {
    var _a;
    const linesPerInch = isNoticeRate(rate)
        ? (_a = rate.images) === null || _a === void 0 ? void 0 : _a.linesPerInch
        : undefined;
    if (typeof linesPerInch !== 'number') {
        console.warn(`Attempting to price images in ${rateIdentifier} without images.linesPerInch set`);
        return 0;
    }
    return linesPerInch;
};
