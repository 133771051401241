import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Alert } from 'lib/components/Alert';
import LayoutSelector from './LayoutSelector';
import FormattedEditor from './FormattedEditor';
import { TopActionBar } from './TopActionBar';
import { NewspaperOrderInfo } from './NewspaperOrderInfo';
import ProofPreview from './ProofPreview';
function DraftContent({ adData, onAdChange, orderPricingComponent, order, version, onUpdateNewspaperOrdersFormData, newspaperOrdersFormData, reloadingPreview, canEditContent }) {
    // TODO: Pass this in depending on which newspaperOrder we're on
    const { layout } = newspaperOrdersFormData[0];
    if (!layout) {
        return (_jsx(Alert, { id: "missing-layout", description: "Layout is missing in draft content step. Please refresh the page." }));
    }
    const onLayoutChange = (newLayout) => {
        var _a, _b, _c;
        const newNewspaperOrdersFormData = newspaperOrdersFormData.map(newspaperOrder => (Object.assign(Object.assign({}, newspaperOrder), { layout: newLayout })));
        onUpdateNewspaperOrdersFormData(newNewspaperOrdersFormData);
        // If the new layout supports fewer photos than are currently on the order, we should remove the extra photos
        if (newLayout.photos < ((_b = (_a = adData.orderImages) === null || _a === void 0 ? void 0 : _a.length) !== null && _b !== void 0 ? _b : 0)) {
            const newAdData = Object.assign(Object.assign({}, adData), { orderImages: (_c = adData.orderImages) === null || _c === void 0 ? void 0 : _c.slice(0, newLayout.photos) });
            onAdChange(newAdData);
        }
    };
    return (_jsxs(_Fragment, { children: [_jsxs("div", Object.assign({ className: "w-full bg-white text-center border-b border-column-gray-100 p-4" }, { children: [_jsxs(TopActionBar, { children: [_jsx(NewspaperOrderInfo, { order: order }), orderPricingComponent, _jsx(ProofPreview, { newspaperOrdersFormData: newspaperOrdersFormData, reloadingPreview: reloadingPreview, order: order, version: version })] }), _jsx(LayoutSelector, { onLayoutChange: onLayoutChange, newspaperOrder: newspaperOrdersFormData[0], layout: layout, selectionDisabled: !canEditContent })] })), _jsxs("div", Object.assign({ className: "flex flex-wrap justify-center p-2" }, { children: [_jsx("div", Object.assign({ className: "flex justify-center w-full h-12" }, { children: _jsx("div", { id: "custom-toolbar-container" }) })), _jsx(FormattedEditor, { newspaperOrdersFormData: newspaperOrdersFormData, onAdChange: onAdChange, adData: adData, layout: layout, disableEditing: !canEditContent, product: order.modelData.product })] }))] }));
}
export default DraftContent;
