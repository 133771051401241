import { createSlice } from '@reduxjs/toolkit';
import { logAndCaptureException } from 'utils';
import { ENV, DEMO, PROD } from '../constants';
const initialState = {
    error: ''
};
export const errorSlice = createSlice({
    name: 'error',
    initialState,
    reducers: {
        setError: (state, action) => {
            state.error = action.payload;
        }
    }
});
const { setError } = errorSlice.actions;
export function handleError(errorData) {
    return dispatch => {
        var _a;
        dispatch(setError(errorData.error));
        if (ENV === DEMO || ENV === PROD) {
            logAndCaptureException(errorData.service, errorData.error, 'error', Object.assign({ env: ENV }, ((_a = errorData.tags) !== null && _a !== void 0 ? _a : {})));
        }
    };
}
export default errorSlice.reducer;
