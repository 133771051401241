import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import classNames from 'classnames';
/**
 * A simple text-only select item.
 */
export function ColumnSelectItem({ option, active, selected }) {
    return (_jsxs("div", Object.assign({ id: `${option.value}-option-label`, className: classNames({
            'bg-blue-50 text-blue-500': active || selected
        }, 'cursor-pointer rounded p-3 hover:bg-blue-50 hover:text-blue-500 focus:outline-none transition ease-in duration-100') }, { children: [_jsx("span", { children: option.label }), option.description && (_jsxs("span", Object.assign({ className: "opacity-75" }, { children: [" - ", option.description] })))] })));
}
