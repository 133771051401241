var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import moment from 'moment';
import { RateType, NoticeType, Day, LineItemType, Product } from '../enums';
import { exists } from '../types';
import { firestoreTimestampOrDateToDate, removeUndefinedFields } from '../helpers';
import { COLUMN_REP_FEE, ENOTICE_CONVENIENCE_FEE, ENOTICE_CONVENIENCE_FEE_GOVT } from '../constants';
import { getAffidavitFeeInCentsAndFeeSplitFromSettingsAndMail, getAffidavitFeeInCentsFromSettingsAndMail, getAffidavitPricingData, getShouldApplyColumnManagedAffidavitFees } from './affidavits';
import { getErrorReporter } from '../utils/errors';
import { getAffidavitAdditionalFeeLineItemsFromRate, getNonAffidavitAdditionalFeeLineItemsFromRate, getLastNonFeeAndNonDiscountLineItemIndex } from './additionalFees';
import { getOrThrow } from '../utils/refs';
import { isFlatAdditionalFee, isPercentAdditionalFee, isNoticeRate } from '../types/rates';
import { getActiveDiscountConfigForNotice, getUpdatedDiscountLineItemsForNotice } from '../notice/discounts';
import { dbToUICurrencyString } from './ui';
import { getColumnCentimeters, getColumnInches, getFolios, roundUp, valueIsNumber } from './math';
import { oklahoma } from './rateTypes/oklahoma';
import { getInheritedProperty } from '../utils/inheritance';
import { NOTICE_ASYNC_DESIGN_FINALIZED } from '../types/events';
import { isFeeSplitTypeFullWaiver } from '../types/feeSplit';
import { fuzzyStringContains } from '../utils/strings';
import findDayRate from './findDayRate';
import calculateSingleRunPrice from './calculateSingleRunPrice';
import getApplicableRate from './getApplicableRate';
// A lot of other files import 'roundUp' and other helpers from here
export * from './math';
export const DEFAULT_COLUMN_FEE_PCT = 10;
export const COLUMN_NAME = 'Column, PBC';
/**
 * Calculate the convenience fee in cents.
 */
export const calculateConvenienceFeeAndFeeSplit = (subtotal, fee, feeSplit) => {
    var _a;
    const { convenienceFeePct, convenienceFeeCap } = fee;
    const convenienceFeeAmountInCentsNoSplit = Math.round((subtotal * convenienceFeePct) / 100);
    let convenienceFeeAmountInCents = convenienceFeeAmountInCentsNoSplit;
    const splitType = feeSplit === null || feeSplit === void 0 ? void 0 : feeSplit.type;
    const feeSplitTypeIsFullWaiver = isFeeSplitTypeFullWaiver(feeSplit);
    if (feeSplitTypeIsFullWaiver) {
        convenienceFeeAmountInCents = 0;
    }
    const splitAmount = (_a = feeSplit === null || feeSplit === void 0 ? void 0 : feeSplit.amount) !== null && _a !== void 0 ? _a : 0;
    if (splitType === 'percent') {
        const splitCap = feeSplit === null || feeSplit === void 0 ? void 0 : feeSplit.cap;
        const initialSplitAmtInCents = Math.round((splitAmount / 100) * convenienceFeeAmountInCents);
        const splitAmtInCents = valueIsNumber(splitCap)
            ? Math.min(splitCap, initialSplitAmtInCents)
            : initialSplitAmtInCents;
        convenienceFeeAmountInCents = Math.max(convenienceFeeAmountInCents - splitAmtInCents, 0);
    }
    if (splitType === 'flat') {
        convenienceFeeAmountInCents = Math.max(convenienceFeeAmountInCents - splitAmount, 0);
    }
    const cappedConvenienceFeeAmountInCents = convenienceFeeCap
        ? Math.min(convenienceFeeAmountInCents, convenienceFeeCap)
        : convenienceFeeAmountInCents;
    const feeSplitAmountInCents = convenienceFeeAmountInCentsNoSplit - cappedConvenienceFeeAmountInCents;
    const invoiceTrackedConvenienceFeeSplitResult = !feeSplit
        ? undefined
        : Object.assign({ feeSplit, amountInCents: feeSplitTypeIsFullWaiver ? 0 : feeSplitAmountInCents }, (feeSplitTypeIsFullWaiver && { amountWaived: feeSplitAmountInCents }));
    return {
        convenienceFeeInCents: cappedConvenienceFeeAmountInCents,
        invoiceTrackedConvenienceFeeSplitResult
    };
};
export const calculateConvenienceFee = (subtotal, fee, feeSplit) => {
    const { convenienceFeeInCents } = calculateConvenienceFeeAndFeeSplit(subtotal, fee, feeSplit);
    return convenienceFeeInCents;
};
/**
 * Determines how much of the affidavit fee to charge to a particular notice based
 * on the subtotal. Right now we only charge the affidavit fee for notices that cost >$0
 * and have a convenience fee > 0%.
 *
 * TODO: this function should be deprecated once we have a better way of determining the
 * cost of additional fees for notices that cost $0.
 */
const getFinalAffidavitFeeInCents = (options) => {
    const shouldApplyColumnManagedAffidavitFees = getShouldApplyColumnManagedAffidavitFees(options.affidavitSettings, options.publicationDates);
    if (!shouldApplyColumnManagedAffidavitFees) {
        return 0;
    }
    if (options.subtotalInCents === 0) {
        return 0;
    }
    if (options.convenienceFee.convenienceFeePct <= 0) {
        return 0;
    }
    return options.defaultAffidavitFeeInCents;
};
const relevantDisplayParameterFromRate = (rateRecord, displayParameters, columns) => {
    if (rateRecord.rateType === RateType.flat.value)
        return null;
    if (rateRecord.rateType === RateType.per_run.value)
        return null;
    if (rateRecord.rateType === RateType.word_count.value)
        return displayParameters.words;
    if (rateRecord.rateType === RateType.inch.value)
        return roundUp(displayParameters.height * displayParameters.width, rateRecord.roundOff).toFixed(2);
    if (rateRecord.rateType === RateType.column_inch.value)
        return getColumnInches(displayParameters.height, columns, rateRecord.roundOff).toFixed(2);
    if (rateRecord.rateType === RateType.line.value)
        return displayParameters.lines;
    if (rateRecord.rateType === RateType.folio.value)
        return getFolios(displayParameters.words);
    if (rateRecord.rateType === RateType.nebraska.value)
        return displayParameters.lines;
    if (rateRecord.rateType === RateType.oklahoma.value)
        return displayParameters.lines;
    if (rateRecord.rateType === RateType.iowa_form.value)
        return displayParameters.lines;
    if (rateRecord.rateType === RateType.battle_born.value)
        return displayParameters.lines;
    if (rateRecord.rateType === RateType.berthoud_government.value)
        return `${displayParameters.lines} lines, ${getColumnInches(displayParameters.height, columns, rateRecord.roundOff).toFixed(2)} total column inches`;
    if (rateRecord.rateType === RateType.enterprise.value)
        // This is the only rate type where we round after calculating column inches
        return `${roundUp(getColumnInches(displayParameters.height, columns, null), rateRecord.roundOff).toFixed(2)} total column inches`;
    if (rateRecord.rateType === RateType.single_column_centimetre.value) {
        return `${getColumnCentimeters(displayParameters.height, columns, rateRecord.roundOff).toFixed(2)} total scm`;
    }
    throw new Error(`Unknown rate type ${rateRecord.rateType}`);
};
export const floatToDBPercent = (pct) => {
    if (pct === null || pct === undefined) {
        return 0;
    }
    const parsedPct = typeof pct === 'string' ? parseFloat(pct) : pct;
    const floatStr = parsedPct.toFixed(2);
    return parseFloat(floatStr);
};
export const calculateSubtotalFromLineItems = (lineItems) => {
    const subtotal = lineItems.reduce((acc, lineItem) => acc + lineItem.amount, 0);
    return subtotal;
};
const calculateTaxFromLineItems = (lineItems, taxPct) => {
    const taxAmt = lineItems.reduce((acc, lineItem) => acc + Math.round((lineItem.amount * taxPct) / 100), 0);
    return taxAmt;
};
/**
 * Check if two prices are functionally the same (not off by more than 0.5 cents)
 */
export const pricesAreTheSame = (amountAInCents, amountBInCents) => {
    return Math.abs(amountAInCents - amountBInCents) < 0.5;
};
export const getInvoiceAmountsBreakdown = (invoiceSnap) => {
    var _a, _b;
    const { pricing, inAppTaxPct, inAppLineItems, convenienceFeePct, convenienceFeeCap } = invoiceSnap.data();
    const { publisherAmountInCents, totalInCents, affidavitFeeInCents: possibleAffidavitFee, publisherFeeSplits } = pricing;
    const affidavitFeeInCents = possibleAffidavitFee || 0;
    // TODO:
    // - Should this handle distributed fees differently?
    const subtotalInCents = calculateSubtotalFromLineItems(inAppLineItems);
    const convenienceFeeInCents = calculateConvenienceFee(subtotalInCents, {
        convenienceFeePct,
        convenienceFeeCap
    }, (_a = publisherFeeSplits === null || publisherFeeSplits === void 0 ? void 0 : publisherFeeSplits.convenienceFeeSplitResult) === null || _a === void 0 ? void 0 : _a.feeSplit);
    const calculatedColumnAmountInCents = affidavitFeeInCents + convenienceFeeInCents;
    const taxesInCents = calculateTaxFromLineItems(inAppLineItems, inAppTaxPct);
    const calculatedPublisherAmount = subtotalInCents + taxesInCents;
    if (totalInCents &&
        !pricesAreTheSame(calculatedPublisherAmount + calculatedColumnAmountInCents, totalInCents)) {
        const delta = totalInCents -
            (calculatedPublisherAmount + calculatedColumnAmountInCents);
        const tagsForSentry = {
            invoiceID: invoiceSnap.id,
            noticeID: (_b = invoiceSnap.data().noticeId) !== null && _b !== void 0 ? _b : 'unknown',
            totalInCents: `${totalInCents}`,
            subtotalInCents: `${subtotalInCents}`,
            taxesInCents: `${taxesInCents}`,
            feesInCents: `${calculatedColumnAmountInCents}`,
            delta: `${delta}`
        };
        if (!pricesAreTheSame(publisherAmountInCents, calculatedPublisherAmount)) {
            getErrorReporter().logAndCaptureWarning('[getInvoiceAmountsBreakdown] Publisher amount on invoice is not the same as calculated publisher amount', tagsForSentry);
        }
        if (pricesAreTheSame(totalInCents, subtotalInCents) ||
            pricesAreTheSame(totalInCents, calculatedPublisherAmount)) {
            getErrorReporter().logAndCaptureWarning('[getInvoiceAmountsBreakdown] Invoice total and subtotal are equal, even though the fee is non-zero', tagsForSentry);
        }
        else {
            getErrorReporter().logAndCaptureWarning(`[getInvoiceAmountsBreakdown] Invoice subtotal plus calculated fees does not match invoice total (${Math.abs(delta) > 0.5
                ? 'Delta greater than $0.50'
                : 'Delta no more than $0.50'})`, tagsForSentry);
        }
    }
    const columnAmountInCents = totalInCents
        ? totalInCents - calculatedPublisherAmount
        : calculatedColumnAmountInCents;
    return {
        subtotalInCents,
        taxesInCents,
        publisherAmountInCents,
        convenienceFeeInCents,
        automatedAffidavitFeeInCents: affidavitFeeInCents,
        columnAmountInCents,
        totalInCents: totalInCents || calculatedPublisherAmount + columnAmountInCents
    };
};
/**
 * Get the rate from a notice, falling back to the newspaper's
 * default liner or default display rate as appropriate.
 */
const getNoticeRateRef = (notice, newspaper) => {
    const { rate, noticeType } = notice;
    if (rate) {
        return rate;
    }
    const defaultRate = noticeType === NoticeType.display_ad.value
        ? newspaper.defaultDisplayRate
        : newspaper.defaultLinerRate;
    if (!defaultRate) {
        throw new Error(`Newspaper ${newspaper.name} missing default rates`);
    }
    return defaultRate;
};
const ordinalSuffix = (num) => {
    const j = num % 10;
    const k = num % 100;
    if (j === 1 && k !== 11) {
        return 'st';
    }
    if (j === 2 && k !== 12) {
        return 'nd';
    }
    if (j === 3 && k !== 13) {
        return 'rd';
    }
    return 'th';
};
const getRelevantRateDescriptionNoBold = (rateRecord, numPublicationDates, currency) => {
    var _a;
    const rateType = RateType.by_value(rateRecord.rateType);
    if (rateType === null || rateType === void 0 ? void 0 : rateType.long_description) {
        return rateType.long_description;
    }
    if (rateRecord.runBased) {
        const publishNum = numPublicationDates;
        let ratesDesc = '';
        for (let i = 1; i <= publishNum; i++) {
            ratesDesc += `${currency}${dbToUICurrencyString(getApplicableRate(numPublicationDates, rateRecord, i))} / ${(_a = RateType.by_value(rateRecord.rateType)) === null || _a === void 0 ? void 0 : _a.singular} for ${i}${ordinalSuffix(i)} run${i !== publishNum ? ', ' : ''}`;
        }
        return ratesDesc;
    }
    return `${currency}${dbToUICurrencyString(getApplicableRate(numPublicationDates, rateRecord, 1))} / ${rateType === null || rateType === void 0 ? void 0 : rateType.singular}`;
};
/**
 * Give a human readable rate description.
 *
 * Ex:
 * '$1.00 / Column Inch'
 */
export const getRelevantRateDescription = (rateRecord, numPublicationDates, currency) => {
    if (!rateRecord) {
        return '';
    }
    let boldDescription = '';
    if (rateRecord.bold_words) {
        boldDescription = `. Bolded Styling -
    ${currency}${dbToUICurrencyString(rateRecord.bold_words)} / Words.`;
    }
    else if (rateRecord.line_with_bold_words) {
        boldDescription = `. Bolded Styling -
    ${currency}${dbToUICurrencyString(rateRecord.line_with_bold_words)} / Line with bolding.`;
    }
    return `${getRelevantRateDescriptionNoBold(rateRecord, numPublicationDates, currency)}${boldDescription}`;
};
export const getRelevantRateString = (rateRecord, displayParameters, columns) => {
    var _a;
    const ratePlural = (_a = RateType.by_value(rateRecord.rateType)) === null || _a === void 0 ? void 0 : _a.plural;
    const relevantParam = relevantDisplayParameterFromRate(rateRecord, displayParameters, columns);
    if (rateRecord.rateType === RateType.oklahoma.value)
        return `Tabular Lines: ${oklahoma.getTabularLines(displayParameters)}, Body Words: ${oklahoma.getBodyWords(displayParameters)}`;
    if (rateRecord.rateType === RateType.single_column_centimetre.value)
        return `Single column centimetres: ${relevantParam}`;
    if (rateRecord.rateType === RateType.per_run.value)
        return '';
    return rateRecord.rateType === RateType.column_inch.value
        ? `Height: ${roundUp(displayParameters.height, rateRecord.roundOff).toFixed(2)} / Columns: ${columns}`
        : `${ratePlural}: ${relevantParam ? `${relevantParam}` : 'n/a'}`;
};
const DEFAULT_DISPLAY_PARAMS = {
    imgs: [],
    columns: 0,
    words: 0,
    lines: 0,
    area: 0,
    height: 0,
    width: 0,
    headerLines: 0,
    headerWords: 0,
    nonTableBoldedLines: 0,
    boldWords: 0,
    justifications: {
        RIGHT_ALIGN: {
            lines: 0,
            words: 0
        },
        LEFT_ALIGN: {
            lines: 0,
            words: 0
        },
        CENTER_ALIGN: {
            lines: 0,
            words: 0
        },
        LEFT_JUSTIFIED: {
            lines: 0,
            words: 0
        }
    },
    areAnyTablesTooWide: false,
    isTableOverflow: false,
    maxHeightExceeded: false
};
/**
 * Additional fee line items are publisher fees on the organiztion object
 * Fee line items will NOT start with a date string in the description (ex. description: Affidavit Fee)
 * Non-fee line item will start with a date string (ex. 07/01/2022: Custom Notice)
 *
 * @param lineItem
 * @returns boolean
 */
export const isAdditionalFeeLineItem = (lineItem) => {
    // Prefer the type field when it exists
    if (typeof lineItem.type === 'number') {
        return lineItem.type === LineItemType.fee.value;
    }
    // no description means not custom
    if (!lineItem.description)
        return false;
    // return false on line items of the format mm/dd/yyyy:
    if (lineItem.description.match(/^\d{2}\/\d{2}\/\d{4}/))
        return false;
    return true;
};
export const isPublicationLineItem = (lineItem) => {
    if (typeof lineItem.type === 'number') {
        return lineItem.type === LineItemType.publication.value;
    }
    return false;
};
/**
 * Get or infer the LineItemType from a LineItem
 */
export const getLineItemType = (item) => {
    const { type } = item;
    if (typeof type === 'number') {
        return type;
    }
    if (item.singleNoticeId) {
        return LineItemType.bulk_invoice.value;
    }
    return isAdditionalFeeLineItem(item)
        ? LineItemType.fee.value
        : LineItemType.publication.value;
};
/**
 * Retrieves the distribute Fee settings either from the rate or the newspaper respectively
 */
export const getDistributeFeeSettings = (newspaper, rate) => {
    var _a, _b;
    return (((_a = rate === null || rate === void 0 ? void 0 : rate.data()) === null || _a === void 0 ? void 0 : _a.distributeEnoticeFee) || ((_b = newspaper.data()) === null || _b === void 0 ? void 0 : _b.distributeEnoticeFee));
};
/**
 * Transform the line items of a notice's 'pricing' field into invoice line items
 * which can be edited by the user in the InvoiceForm UI.
 */
export const getInvoiceLineItems = (pricing, activeOrganization, rateSnap) => {
    // Calculate the total additional fees by parsing line items
    let additionalFeeTotal = 0;
    pricing.lineItems.forEach(item => {
        if (!isPublicationLineItem(item)) {
            additionalFeeTotal += item.amount;
        }
    });
    additionalFeeTotal = Math.round(additionalFeeTotal);
    return pricing.lineItems.map((item, i) => {
        var _a;
        const { description, unitPricing } = item;
        const amount = Math.round(item.amount);
        const date = firestoreTimestampOrDateToDate(item.date);
        const type = getLineItemType(item);
        if (((_a = getDistributeFeeSettings(activeOrganization, rateSnap)) === null || _a === void 0 ? void 0 : _a.finalLineItem) &&
            rateSnap.data().finalLineItemPricing &&
            rateSnap.data().rateType === RateType.flat.value) {
            return {
                amount: i === 0
                    ? pricing.subtotal - additionalFeeTotal
                    : !isPublicationLineItem(item)
                        ? amount
                        : 0,
                unitPricing,
                date,
                description,
                type
            };
        }
        if (rateSnap.data().rateType === RateType.flat.value) {
            return {
                amount,
                unitPricing,
                date,
                description,
                type
            };
        }
        return {
            amount,
            unitPricing,
            date,
            description,
            type
        };
    });
};
/**
 * Get all the line items from an invoice representing single publications.
 */
export const getPublicationLineItems = (invoice) => {
    const { inAppLineItems } = invoice.data();
    return inAppLineItems
        .filter(item => {
        if (item.refund || item.singleNoticeId) {
            return false;
        }
        if (isAdditionalFeeLineItem(item)) {
            return false;
        }
        return true;
    })
        .sort((a, b) => {
        const aDate = firestoreTimestampOrDateToDate(a.date);
        const bDate = firestoreTimestampOrDateToDate(b.date);
        return aDate.getTime() - bDate.getTime();
    });
};
export const getFeeLineItems = (invoice) => {
    const { inAppLineItems } = invoice.data();
    return inAppLineItems.filter(item => isAdditionalFeeLineItem(item));
};
/**
 * Find the line item on an invoice that corresponds to publication
 * for a given date.
 */
export const getPublicationLineItemForDate = (invoice, publicationDate) => {
    const publicationLineItems = getPublicationLineItems(invoice);
    const sameDayLineItems = publicationLineItems.filter(item => {
        const itemDate = firestoreTimestampOrDateToDate(item.date);
        return moment(itemDate).isSame(moment(publicationDate), 'day');
    });
    if (sameDayLineItems.length === 0) {
        getErrorReporter().logAndCaptureWarning('Invoice has no line items for day', {
            invoiceId: invoice.id,
            publicationDate: publicationDate.toISOString()
        });
        return;
    }
    if (sameDayLineItems.length >= 2) {
        getErrorReporter().logAndCaptureWarning('Invoice has multiple line items for one day', {
            invoiceId: invoice.id,
            publicationDate: publicationDate.toISOString()
        });
        return undefined;
    }
    return sameDayLineItems[0];
};
/**
 * Find the the notice publication date corresponding to a given line item on an invoice.
 */
export const getPubDateForPublicationLineItem = (lineItem, publicationDates) => {
    const samePublicationDates = publicationDates.filter(pubDate => {
        const lineItemDate = firestoreTimestampOrDateToDate(lineItem.date);
        const pubDateDate = firestoreTimestampOrDateToDate(pubDate);
        return moment(lineItemDate).isSame(moment(pubDateDate), 'day');
    });
    return samePublicationDates[0];
};
/**
 * Find notice pub dates that aren't on a given invoice
 */
export const getNoticePubDatesNotOnInvoice = (invoice, publicationDates) => {
    if (!publicationDates || publicationDates.length === 0) {
        return [];
    }
    return publicationDates.filter(pubDate => {
        const pubDateAsDate = firestoreTimestampOrDateToDate(pubDate);
        return !getPublicationLineItemForDate(invoice, pubDateAsDate);
    });
};
/**
 * Find the difference in run dates
 */
export const getPubDateAndPublicationLineItemsDifference = (invoice, publicationDates) => {
    const pubDateDiffs = getNoticePubDatesNotOnInvoice(invoice, publicationDates);
    const publicationLineItemDiffs = getPublicationLineItems(invoice).filter(lineItem => {
        return !getPubDateForPublicationLineItem(lineItem, publicationDates);
    });
    return { pubDateDiffs, publicationLineItemDiffs };
};
/**
 * Get the invoiced price for a single run of a notice.
 *
 * Note that this does not attempt to reverse any fee-embedding, so
 * when calling this function be sure to understand the newspaper context!
 */
export const getSingleRunPriceFromInvoice = (notice, invoice, runNumber) => {
    const { publicationDates } = notice.data();
    if (!publicationDates || publicationDates.length <= runNumber) {
        throw new Error(`Notice ${notice.id} does not have ${runNumber} publication dates!`);
    }
    const publicationDate = firestoreTimestampOrDateToDate(publicationDates[runNumber]);
    const matchingLineItem = getPublicationLineItemForDate(invoice, publicationDate);
    if (!matchingLineItem) {
        return undefined;
    }
    return matchingLineItem.amount;
};
/**
 * Determine the fee percentage and cap for a given rate, considering first
 * if the rate is for governments or not.
 *
 * Note: this method is not suitable for calculating the final convenience fee
 * for a notice as it does not include the column rep fee which may be present
 * as an additional fee on the newspaper.
 */
const rateToConvenienceFee = (rate) => {
    if (!isNoticeRate(rate)) {
        return {
            convenienceFeePct: ENOTICE_CONVENIENCE_FEE,
            convenienceFeeCap: undefined
        };
    }
    const { isGovernment, enotice_fee_pct, columnRepFeePct } = rate;
    const basePercentage = isGovernment
        ? ENOTICE_CONVENIENCE_FEE_GOVT
        : enotice_fee_pct !== null && enotice_fee_pct !== void 0 ? enotice_fee_pct : ENOTICE_CONVENIENCE_FEE;
    // We charge an additional fee if all of the below are true:
    //  - The rate has columnRepFeePct
    //  - The original percentage was > 0
    const additionalPercentage = !!columnRepFeePct && basePercentage ? columnRepFeePct : 0;
    const convenienceFeePct = basePercentage + additionalPercentage;
    return {
        convenienceFeePct,
        convenienceFeeCap: rate.convenienceFeeCap
    };
};
/**
 * Get the column rep fee percentage specified in a newspaper's additional fees.
 */
const getColumnRepFeePctFromNewspaper = (convenienceFee, newspaper) => {
    const { additionalFees } = newspaper;
    const { convenienceFeePct } = convenienceFee;
    const columnRepFeeOnNewspaper = additionalFees === null || additionalFees === void 0 ? void 0 : additionalFees.find(af => af.description === COLUMN_REP_FEE);
    // We charge an additional fee if all of the below are true:
    //  - The newspaper has a COLUMN_REP_FEE
    //  - The existing convenience fee percentage is > 0
    //  - A column rep had contact with the notice in a meaningful way
    const columnRepPercentage = !!columnRepFeeOnNewspaper &&
        isPercentAdditionalFee(columnRepFeeOnNewspaper) &&
        convenienceFeePct > 0
        ? columnRepFeeOnNewspaper.feePercentage
        : 0;
    return columnRepPercentage;
};
/**
 * Calculate notice pricing based on the intrinsic notice propreties
 * and the line items for each publication.
 */
export const createDBPricingObjectFromDataAndPublicationLineItems = (productType, productData, newspaper, rate, publicationLineItems, affidavitData, discountConfig) => {
    var _a, _b;
    const { publicationDates } = productData;
    const lastIndex = publicationDates.length - 1;
    const lastPubDatePlusOneMinute = moment(publicationDates[lastIndex].toDate()).add(1, 'm');
    // allow for flat additional line items at the newspaper level
    let percentAdditionalFees = [];
    // allow for additional line items at the newspaper level
    let lineItems = publicationLineItems;
    // Add discount line items
    if (discountConfig) {
        const updatedLineItems = getUpdatedDiscountLineItemsForNotice(discountConfig, lineItems);
        lineItems = updatedLineItems;
    }
    // allow for additional fees for custom affidavits
    // this enables additional fees for Ogden papers that
    // are requesting notice-type-specific pricing
    if (newspaper.customAffidavitFee) {
        const item = {
            date: lastPubDatePlusOneMinute.toDate(),
            amount: newspaper.customAffidavitFee,
            description: 'Custom Affidavit Fee',
            type: LineItemType.fee.value
        };
        lineItems = [item].concat(lineItems);
    }
    const affidavitAdditionalFeeLineItems = getAffidavitAdditionalFeeLineItemsFromRate(rate, affidavitData.mail || [], lastPubDatePlusOneMinute.toDate());
    lineItems = lineItems.concat(affidavitAdditionalFeeLineItems);
    const additionalFeeLineItems = getNonAffidavitAdditionalFeeLineItemsFromRate(rate, productData, lastPubDatePlusOneMinute.toDate(), lineItems);
    lineItems = lineItems.concat(additionalFeeLineItems);
    /**
     * The cross-paper fees should be calculated after all other fees in order
     * to ensure that any percent additional fees are calculated with all other fees
     */
    if (newspaper.additionalFees) {
        const flatAdditionalFees = newspaper.additionalFees.filter(isFlatAdditionalFee);
        percentAdditionalFees = newspaper.additionalFees.filter(isPercentAdditionalFee);
        const percentAdditionalFeesWithoutRepFee = percentAdditionalFees.filter(naf => naf.description !== COLUMN_REP_FEE);
        if (percentAdditionalFees.length !== percentAdditionalFeesWithoutRepFee.length) {
            getErrorReporter().logInfo('Removed Column Rep fee from percentAdditionalFees.');
            percentAdditionalFees = percentAdditionalFeesWithoutRepFee;
        }
        lineItems = lineItems.concat(flatAdditionalFees.map(fee => ({
            date: lastPubDatePlusOneMinute.toDate(),
            amount: fee.amount,
            description: fee.description,
            type: LineItemType.fee.value
        })));
    }
    // Percent additional fees should be calculated based on the sum of all previous line items
    let runningSubtotal = calculateSubtotalFromLineItems(lineItems);
    percentAdditionalFees.forEach(additionalFee => {
        const item = {
            date: lastPubDatePlusOneMinute.toDate(),
            amount: (additionalFee.feePercentage / 100) * runningSubtotal,
            description: additionalFee.description,
            type: LineItemType.fee.value
        };
        lineItems = lineItems.concat(item);
        runningSubtotal += item.amount;
    });
    // Tax will always be 0% for now for obits & classifieds: https://columnpbc.slack.com/archives/C063V00UK6W/p1712161715255259
    const isPublicNotice = productType === Product.Notice;
    const taxPct = isPublicNotice ? newspaper.taxPct || 0 : 0;
    const totalAcrossRuns = lineItems.reduce((acc, lineItem) => acc + lineItem.amount, 0);
    const subtotal = Math.max(totalAcrossRuns, (_a = rate.minimum) !== null && _a !== void 0 ? _a : 0);
    const lastNonFeeNonDiscountLineItemIndex = getLastNonFeeAndNonDiscountLineItemIndex(lineItems);
    // enforce that all line items are cents
    let roundedSubtotal = 0;
    for (const item of lineItems) {
        const centTotal = Math.floor(item.amount);
        item.amount = centTotal;
        roundedSubtotal += centTotal;
    }
    // put the impact of rounding onto the last non-fee line item
    lineItems[lastNonFeeNonDiscountLineItemIndex].amount +=
        subtotal - roundedSubtotal;
    let additionalFeeTotal = 0;
    let changedFinalLineItem = false;
    if (isNoticeRate(rate) && rate.finalLineItemPricing) {
        for (let i = lineItems.length - 1; i >= 0; i -= 1) {
            if (!isPublicationLineItem(lineItems[i])) {
                additionalFeeTotal += lineItems[i].amount;
            }
            else if (changedFinalLineItem) {
                lineItems[i].amount = 0;
            }
            else {
                lineItems[i].amount = totalAcrossRuns - additionalFeeTotal;
                changedFinalLineItem = true;
            }
        }
    }
    const taxAmt = calculateTaxFromLineItems(lineItems, taxPct);
    const convenienceFeeFromRate = rateToConvenienceFee(rate);
    const columnRepFeePctFromNewspaper = getColumnRepFeePctFromNewspaper(convenienceFeeFromRate, newspaper);
    const combinedConvenienceFeePct = convenienceFeeFromRate.convenienceFeePct + columnRepFeePctFromNewspaper;
    if (combinedConvenienceFeePct > convenienceFeeFromRate.convenienceFeePct) {
        getErrorReporter().logInfo('Column rep fee from newspaper added to convenience fee', {
            newspaperName: newspaper.name,
            oldConvenienceFeePct: `${convenienceFeeFromRate.convenienceFeePct}`,
            newConvenienceFeePct: `${combinedConvenienceFeePct}`
        });
    }
    const convenienceFee = {
        // NOTE: this value for convenienceFee.convenienceFeePct, which is returned in
        // this createDBPricingObjectFromDataAndPublicationLineItems function,
        // is not further adjusted for the actual convenienceFeeInCents value output by calculateConvenienceFee below
        // (viz., as the value may be adjusted by a convenience fee cap or any feeSplits).
        convenienceFeePct: combinedConvenienceFeePct,
        convenienceFeeCap: convenienceFeeFromRate.convenienceFeeCap
    };
    const { convenienceFeeSplit } = isNoticeRate(rate)
        ? rate
        : { convenienceFeeSplit: undefined };
    // This is where we apply to the convenience fee any fee splits that live on the relevant rate.
    const convenienceFeeInCents = calculateConvenienceFee(subtotal, convenienceFee, convenienceFeeSplit);
    // This is the only time we should call this function as the notice data has not yet been set.
    // Otherwise, use `getAffidavitFeeInCentsForNotice`.
    // If the subtotal is zero, we don't need to calculate the affidavit fee and automatically set to zero.
    // Fee splits with respect to the Column affidavit fee (stored on ARS and inherited using the usual hierarchy) will be applied here.
    const defaultAffidavitFeeInCents = getAffidavitFeeInCentsFromSettingsAndMail(affidavitData.settings, (_b = affidavitData.mail) !== null && _b !== void 0 ? _b : []);
    const affidavitFeeInCents = getFinalAffidavitFeeInCents({
        subtotalInCents: subtotal,
        defaultAffidavitFeeInCents,
        convenienceFee,
        affidavitSettings: affidavitData.settings,
        publicationDates: productData.publicationDates
    });
    const total = subtotal + taxAmt + convenienceFeeInCents + affidavitFeeInCents;
    return Object.assign(Object.assign(Object.assign({ lineItems,
        subtotal, taxPct: floatToDBPercent(taxPct), taxAmt, 
        // note -- below convenienceFeePct value is not adjusted for convenience fee caps or feeSplits
        convenienceFeePct: floatToDBPercent(convenienceFee.convenienceFeePct), convenienceFee: convenienceFeeInCents }, (convenienceFeeSplit && { convenienceFeeSplit })), (affidavitFeeInCents && { affidavitFeeInCents })), { total });
};
export const getDescriptionForLineItem = (adDataForPricing, date, newspaper, rate) => {
    var _a;
    const { dayRate, dayEnum } = findDayRate(rate, date);
    if (dayRate) {
        return `${moment(date.toDate()).format('MM/DD/YYYY')}: Custom notice (${((_a = Day.by_value(dayEnum)) === null || _a === void 0 ? void 0 : _a.label) || ''} Rate)`;
    }
    if (rate.product === Product.Obituary ||
        rate.product === Product.Classified) {
        return `${newspaper.name} ${moment(date.toDate()).format('MM/DD/YYYY')} Ad`;
    }
    return undefined;
};
/**
 * This is the one true pre-invoice notice pricing function!
 * Final pricing can only be determined after invoicing: see calculateInvoicePricing()
 */
export const createDBPricingObjectFromData = (productType, adDataForPricing, newspaper, rate, displayParams, affidavitPricingData, discountConfig) => {
    const displayParameters = displayParams !== null && displayParams !== void 0 ? displayParams : DEFAULT_DISPLAY_PARAMS;
    const { publicationDates } = adDataForPricing;
    const publicationLineItems = publicationDates.map((date, i) => {
        const amount = calculateSingleRunPrice(adDataForPricing, displayParameters, newspaper, rate, i);
        const description = getDescriptionForLineItem(adDataForPricing, date, newspaper, rate);
        return Object.assign(Object.assign({ date: date.toDate(), amount }, (description && { description })), { type: LineItemType.publication.value });
    });
    return createDBPricingObjectFromDataAndPublicationLineItems(productType, adDataForPricing, newspaper, rate, publicationLineItems, affidavitPricingData, discountConfig);
};
/**
 * Price a notice, allowing overrides of the displayParams and the rate. Useful
 * in places like the placement flow where those fields are dynamic.
 */
export const createDBPricingObject = (ctx, noticeSnap, displayParams, rateOverride) => __awaiter(void 0, void 0, void 0, function* () {
    if (!exists(noticeSnap)) {
        throw new Error('Notice not set');
    }
    const displayParameters = displayParams !== null && displayParams !== void 0 ? displayParams : DEFAULT_DISPLAY_PARAMS;
    const notice = noticeSnap.data();
    const newspaperSnap = yield getOrThrow(noticeSnap.data().newspaper);
    const newspaper = newspaperSnap.data();
    const rateRef = rateOverride !== null && rateOverride !== void 0 ? rateOverride : getNoticeRateRef(notice, newspaper);
    const rate = (yield getOrThrow(rateRef)).data();
    const affidavitPricingData = yield getAffidavitPricingData(ctx, noticeSnap);
    const discountConfig = yield getActiveDiscountConfigForNotice(ctx, noticeSnap.data());
    return createDBPricingObjectFromData(Product.Notice, notice, newspaper, rate, displayParameters, affidavitPricingData, discountConfig);
});
/**
 * Price a notice using all its internal data (display params, rate, etc)
 * See: createDBPricingObject
 */
export const createDBPricingFromNotice = (ctx, noticeSnap) => __awaiter(void 0, void 0, void 0, function* () {
    const { displayParams } = noticeSnap.data();
    return createDBPricingObject(ctx, noticeSnap, displayParams, 
    /* rateOverride= */ undefined);
});
/**
 * Sum the convenience fee and the affidavit fee.
 */
export const getCombinedColumnFeeInCentsFromDbPricingObj = (dbPricingObj) => {
    var _a, _b;
    return (((_a = dbPricingObj.convenienceFee) !== null && _a !== void 0 ? _a : 0) + ((_b = dbPricingObj.affidavitFeeInCents) !== null && _b !== void 0 ? _b : 0));
};
export const getDistributedLineItems = (lineItems, distributeEnoticeFee, rate, { feeToDistribute, expectedSubtotal }) => {
    const { finalLineItem = false, evenly = false } = distributeEnoticeFee;
    const { finalLineItemPricing = false, rateType } = rate;
    const itemsToDistributeOver = lineItems.filter(l => isPublicationLineItem(l));
    if (finalLineItem &&
        finalLineItemPricing &&
        rateType === RateType.flat.value) {
        let additionalFeeTotal = 0;
        lineItems.forEach(item => {
            if (!isPublicationLineItem(item)) {
                additionalFeeTotal += item.amount;
            }
        });
        return lineItems.map((item, i) => {
            var _a, _b;
            const amount = i === 0
                ? expectedSubtotal - additionalFeeTotal
                : !isPublicationLineItem(item)
                    ? item.amount
                    : 0;
            const unitPricing = {
                price: amount,
                quantity: (_b = (_a = item.unitPricing) === null || _a === void 0 ? void 0 : _a.quantity) !== null && _b !== void 0 ? _b : 1
            };
            return Object.assign(Object.assign({}, item), { amount, unitPricing });
        });
    }
    if (finalLineItem &&
        !finalLineItemPricing &&
        rateType === RateType.flat.value) {
        const finalLineItemAmount = feeToDistribute + itemsToDistributeOver[0].amount;
        return lineItems.map((item, i) => {
            var _a, _b;
            const amount = i === itemsToDistributeOver.length - 1
                ? finalLineItemAmount
                : !isPublicationLineItem(item)
                    ? item.amount
                    : 0;
            const unitPricing = {
                price: amount,
                quantity: (_b = (_a = item.unitPricing) === null || _a === void 0 ? void 0 : _a.quantity) !== null && _b !== void 0 ? _b : 1
            };
            return Object.assign(Object.assign({}, item), { amount, unitPricing });
        });
    }
    if (finalLineItem &&
        !finalLineItemPricing &&
        rateType !== RateType.flat.value) {
        const finalLineItemAmount = feeToDistribute + itemsToDistributeOver[0].amount;
        return lineItems.map((item, i) => {
            var _a, _b;
            const amount = i === itemsToDistributeOver.length - 1
                ? finalLineItemAmount
                : item.amount;
            const unitPricing = {
                price: amount,
                quantity: (_b = (_a = item.unitPricing) === null || _a === void 0 ? void 0 : _a.quantity) !== null && _b !== void 0 ? _b : 1
            };
            return Object.assign(Object.assign({}, item), { amount, unitPricing });
        });
    }
    if ((evenly && (rateType === RateType.flat.value || finalLineItemPricing)) ||
        (finalLineItem && finalLineItemPricing)) {
        return lineItems.map(item => {
            var _a, _b;
            const amount = item.amount !== 0 && isPublicationLineItem(item)
                ? item.amount + feeToDistribute
                : item.amount;
            const unitPricing = {
                price: amount,
                quantity: (_b = (_a = item.unitPricing) === null || _a === void 0 ? void 0 : _a.quantity) !== null && _b !== void 0 ? _b : 1
            };
            return Object.assign(Object.assign({}, item), { amount, unitPricing });
        });
    }
    const totalToDistributeOver = itemsToDistributeOver.reduce((a, b) => a + b.amount, 0);
    const distributedFee = feeToDistribute / itemsToDistributeOver.length;
    let finalLineItemAmount;
    // if there is only one value to distribute over, put the whole fee on it
    if (itemsToDistributeOver.length === 1) {
        finalLineItemAmount = totalToDistributeOver + distributedFee;
    }
    // otherwise we need to be careful with rounding
    else {
        const initialDistributed = itemsToDistributeOver
            .slice(0, -1)
            .reduce((a, b) => a + b.amount + Math.round(distributedFee), 0);
        finalLineItemAmount =
            totalToDistributeOver + feeToDistribute - initialDistributed;
    }
    const lastNonFeeNonDiscountLineItemIndex = getLastNonFeeAndNonDiscountLineItemIndex(lineItems);
    return lineItems.map((item, i) => {
        var _a, _b;
        let amount;
        if (!isPublicationLineItem(item)) {
            // don't include the fee on custom items
            amount = item.amount;
        }
        else if (i === lastNonFeeNonDiscountLineItemIndex) {
            // handle rounding on the final non-fee item
            amount = finalLineItemAmount;
        }
        else {
            // otherwise include the default spread
            amount = item.amount + Math.round(distributedFee);
        }
        const unitPricing = {
            price: amount,
            quantity: (_b = (_a = item.unitPricing) === null || _a === void 0 ? void 0 : _a.quantity) !== null && _b !== void 0 ? _b : 1
        };
        return Object.assign(Object.assign({}, item), { amount, unitPricing });
    });
};
/**
 * Checks if one of the distribute settings is turned on, other wise it returns false
 */
export const shouldDistributeFee = (distributeFeeSettings) => {
    return (!!distributeFeeSettings &&
        Object.values(distributeFeeSettings).some(setting => setting));
};
const distributeDbPrice = (dbPricingObj, distributeEnoticeFee, rate) => {
    var _a, _b, _c, _d, _e;
    const shouldDistributeConvenienceFee = !!(distributeEnoticeFee === null || distributeEnoticeFee === void 0 ? void 0 : distributeEnoticeFee.evenly) || !!(distributeEnoticeFee === null || distributeEnoticeFee === void 0 ? void 0 : distributeEnoticeFee.finalLineItem);
    const affidavitFeeIndex = dbPricingObj.lineItems.findIndex(({ description = '' }) => fuzzyStringContains(description, 'affidavit'));
    const shouldDistributeAffidaviteFee = 
    // Distribution settings are set to distribute the affidavit fee
    !!(distributeEnoticeFee === null || distributeEnoticeFee === void 0 ? void 0 : distributeEnoticeFee.affidavitFee) &&
        // The pricing object has a column affidavit fee to embed
        !!dbPricingObj.affidavitFeeInCents &&
        // There is a publisher fee line item into which we can embed the affidavit fee
        affidavitFeeIndex !== -1;
    const combinedFee = getCombinedColumnFeeInCentsFromDbPricingObj(dbPricingObj);
    const feeToDistribute = shouldDistributeAffidaviteFee
        ? (_a = dbPricingObj.convenienceFee) !== null && _a !== void 0 ? _a : 0
        : combinedFee;
    const taxAmt = calculateTaxFromLineItems(dbPricingObj.lineItems, dbPricingObj.taxPct);
    const { total } = dbPricingObj;
    let { subtotal } = dbPricingObj;
    let distributedFee = 0;
    // If we're distributing the convenience fee, then it should be included in the subtotal
    if (shouldDistributeConvenienceFee) {
        subtotal += (_b = dbPricingObj.convenienceFee) !== null && _b !== void 0 ? _b : 0;
        distributedFee += (_c = dbPricingObj.convenienceFee) !== null && _c !== void 0 ? _c : 0;
    }
    // If we're distributing the affidavit fee (separately or as part of the convenience fee),
    // then it should be included in the subtotal
    if (shouldDistributeAffidaviteFee || shouldDistributeConvenienceFee) {
        subtotal += (_d = dbPricingObj.affidavitFeeInCents) !== null && _d !== void 0 ? _d : 0;
        distributedFee += (_e = dbPricingObj.affidavitFeeInCents) !== null && _e !== void 0 ? _e : 0;
    }
    const baseLineItems = shouldDistributeConvenienceFee && rate
        ? getDistributedLineItems(dbPricingObj.lineItems, distributeEnoticeFee, rate, {
            feeToDistribute,
            expectedSubtotal: subtotal
        })
        : dbPricingObj.lineItems;
    const lineItems = baseLineItems.map((item, idx) => {
        var _a;
        if (!shouldDistributeAffidaviteFee || idx !== affidavitFeeIndex) {
            return item;
        }
        const { amount: publisherAffidavitFee, unitPricing } = item;
        const columnAffidavitFee = (_a = dbPricingObj.affidavitFeeInCents) !== null && _a !== void 0 ? _a : 0;
        const amount = publisherAffidavitFee + columnAffidavitFee;
        if (!unitPricing) {
            return Object.assign(Object.assign({}, item), { amount, unitPricing: { price: amount, quantity: 1 } });
        }
        const { quantity } = unitPricing;
        const newUnitPricing = {
            price: amount / quantity,
            quantity
        };
        return Object.assign(Object.assign({}, item), { amount, unitPricing: newUnitPricing });
    });
    return Object.assign(Object.assign({}, dbPricingObj), { lineItems, affidavitFeeInCents: 0, convenienceFee: shouldDistributeConvenienceFee
            ? null
            : dbPricingObj.convenienceFee, convenienceFeePct: shouldDistributeConvenienceFee
            ? null
            : dbPricingObj.convenienceFeePct, subtotal,
        taxAmt,
        total, distributed: true, distributedFee });
};
export const maybeDistributeDbPrice = (dbPricingObj, distributeEnoticeFee, rate) => {
    if (!shouldDistributeFee(distributeEnoticeFee) || dbPricingObj.distributed) {
        return dbPricingObj;
    }
    return distributeDbPrice(dbPricingObj, distributeEnoticeFee, rate);
};
export const invoiceDataToDBPricingObject = (inAppLineItems, convenienceFeePct, convenienceFeeCap, optionalAffidavitFeeInCents, inAppTaxPct, distributeEnoticeFee, rate, appliedBalance) => {
    const subtotal = inAppLineItems
        .map(item => item.amount)
        .reduce((pre, cur) => pre + cur, 0);
    const { convenienceFeeSplit } = rate || {};
    const convenienceFee = calculateConvenienceFee(subtotal, {
        convenienceFeePct,
        convenienceFeeCap
    }, convenienceFeeSplit);
    const affidavitFeeInCents = optionalAffidavitFeeInCents || 0;
    const taxAmt = calculateTaxFromLineItems(inAppLineItems, inAppTaxPct);
    const total = subtotal +
        taxAmt +
        convenienceFee +
        affidavitFeeInCents -
        (appliedBalance || 0);
    let obj = {
        lineItems: inAppLineItems
            .map(item => {
            // TODO(COREDEV-1031): This brings in extra properties from LineItem
            // which are not on the LineItem type!
            return Object.assign(Object.assign({}, item), { type: getLineItemType(item), date: firestoreTimestampOrDateToDate(item.date) });
        })
            .sort((a, b) => a.date.getTime() - b.date.getTime()),
        taxPct: inAppTaxPct,
        taxAmt,
        convenienceFeePct,
        convenienceFee,
        affidavitFeeInCents,
        subtotal,
        total
    };
    // Distribute fees (if applicable)
    obj = maybeDistributeDbPrice(obj, distributeEnoticeFee !== null && distributeEnoticeFee !== void 0 ? distributeEnoticeFee : undefined, rate);
    return obj;
};
export const invoiceToDBPricingObject = ({ invoiceSnap, rateSnap, newspaperSnap }) => {
    const { inAppLineItems, convenienceFeePct, convenienceFeeCap, pricing: { affidavitFeeInCents }, inAppTaxPct } = invoiceSnap.data();
    return invoiceDataToDBPricingObject(inAppLineItems, convenienceFeePct, convenienceFeeCap, affidavitFeeInCents, inAppTaxPct, getDistributeFeeSettings(newspaperSnap, rateSnap), rateSnap.data());
};
export const maybeGetColumnRepFeePctFromNewspaper = (newspaper) => {
    var _a;
    const columnRepFee = (_a = newspaper
        .data()
        .additionalFees) === null || _a === void 0 ? void 0 : _a.find(fee => fee.description === COLUMN_REP_FEE);
    return columnRepFee && isPercentAdditionalFee(columnRepFee)
        ? columnRepFee.feePercentage
        : undefined;
};
export const maybeGetColumnRepFeePctFromNotice = (notice, rate) => __awaiter(void 0, void 0, void 0, function* () {
    const ratePct = rate.data().columnRepFeePct;
    if (typeof ratePct === 'number') {
        return ratePct;
    }
    const newspaper = yield getOrThrow(notice.data().newspaper);
    return maybeGetColumnRepFeePctFromNewspaper(newspaper);
});
/**
 * Calculate the advertiser and publisher Column Reps fees for a notice.
 * Rep fee can be on the rate or newspaper. Rep fees on rates override rep fee on the newspaper
 */
export const calculateColumnRepsFee = (notice, invoice) => __awaiter(void 0, void 0, void 0, function* () {
    const noFees = {
        advertiserFeeInCents: 0,
        publisherFeeInCents: 0
    };
    const rate = yield getOrThrow(notice.data().rate);
    const columnRepFeePctFromNotice = yield maybeGetColumnRepFeePctFromNotice(notice, rate);
    const percentage = columnRepFeePctFromNotice;
    if (!(percentage && percentage > 0)) {
        return noFees;
    }
    const { subtotalInCents, convenienceFeeInCents } = getInvoiceAmountsBreakdown(invoice);
    // If the advertiser paid any convenience fee, it should have included the Column Rep fee
    if (convenienceFeeInCents > 0) {
        return {
            advertiserFeeInCents: convenienceFeeInCents,
            publisherFeeInCents: 0
        };
    }
    // Otherwise, the publisher has to pay the column rep fee
    const repFeeInCents = calculateConvenienceFee(subtotalInCents, {
        convenienceFeePct: percentage,
        convenienceFeeCap: undefined
    }, undefined // no feeSplit on rep fee
    );
    return {
        publisherFeeInCents: repFeeInCents,
        advertiserFeeInCents: 0
    };
});
/**
 * Calculate the fee that should be paid by the publisher based on async design work.
 */
export const calculateAsyncDesignFee = (ctx, notice, invoice) => __awaiter(void 0, void 0, void 0, function* () {
    var _a, _b, _c, _d, _e, _f;
    const config = yield getInheritedProperty(notice.data().newspaper, 'asyncDesignPricing');
    if (!config) {
        return 0;
    }
    // Check the events log for the notice. If the notice has any matching events
    // it is eligible for a fee. If there are multiple matches we look for the
    // latest one.
    const events = yield ctx
        .eventsRef()
        .where('type', '==', NOTICE_ASYNC_DESIGN_FINALIZED)
        .where('notice', '==', notice.ref)
        .orderBy('createdAt', 'desc')
        .limit(1)
        .get();
    if (events.docs.length === 0) {
        return 0;
    }
    if (config.type === 'percent') {
        const { subtotalInCents } = getInvoiceAmountsBreakdown(invoice);
        const feeInCents = Math.round((subtotalInCents * config.subtotalPct) / 100);
        const pages = (_a = events.docs[0].data().data.pages) !== null && _a !== void 0 ? _a : 1;
        const perPageCap = ((_b = config.cap) === null || _b === void 0 ? void 0 : _b.perPage)
            ? config.cap.perPage * pages
            : Number.MAX_SAFE_INTEGER;
        const totalCap = (_d = (_c = config.cap) === null || _c === void 0 ? void 0 : _c.total) !== null && _d !== void 0 ? _d : Number.MAX_SAFE_INTEGER;
        return Math.min(feeInCents, perPageCap, totalCap);
    }
    if (config.type === 'sized') {
        const modularSize = yield ((_e = notice.data().modularSize) === null || _e === void 0 ? void 0 : _e.get());
        const price = ((_f = modularSize === null || modularSize === void 0 ? void 0 : modularSize.data()) === null || _f === void 0 ? void 0 : _f.designFeeInCents) || 0;
        return price;
    }
    return 0;
});
/**
 * Calculating the total is tricky, because the data.publisherAmountInCents is meant to reflect what we
 * intend to send to the publisher, and thus includes both the sum of all line items (subtotal)
 * plus any applicable tax (subtotal * tax percent). However, we need to calculate our convenience
 * fee from the original subtotal.
 *
 * (Eventually we should store the actual subtotal and tax amount in separate fields on our
 * invoices, but don't want to expand the scope of this hotfix too much)
 *
 * The steps to calculate the proper total are:
 * - Recalculate the subtotal from all line items.
 * - Apply any discounts to the subtotal.
 * - Calculate the tax amount as a percentage of the subtotal.
 * - Calculate our convenience fee as a percentage of the subtotal.
 * - Sum all of the above (plus any affidavit notarization fee) to get the total
 */
export const calculateInvoicePricing = (newspaper, data) => {
    var _a, _b, _c;
    const subtotalInCents = data.inAppLineItems.reduce((acc, lineItem) => acc + lineItem.amount, 0);
    const taxPct = (_b = (_a = data.inAppTaxPct) !== null && _a !== void 0 ? _a : newspaper.data().taxPct) !== null && _b !== void 0 ? _b : 0;
    const taxInCents = calculateTaxFromLineItems(data.inAppLineItems, taxPct);
    const { convenienceFeeSplit } = ((_c = data.rate) === null || _c === void 0 ? void 0 : _c.data()) || {};
    const { convenienceFeeInCents, invoiceTrackedConvenienceFeeSplitResult } = calculateConvenienceFeeAndFeeSplit(subtotalInCents, data.convenienceFee, convenienceFeeSplit);
    const { affidavitFeeInCents: defaultAffidavitFeeInCents, invoiceTrackedAffidavitFeeSplitResult } = getAffidavitFeeInCentsAndFeeSplitFromSettingsAndMail(data.affidavitReconciliationSettings, data.mail);
    const affidavitFeeInCents = getFinalAffidavitFeeInCents({
        subtotalInCents,
        defaultAffidavitFeeInCents,
        convenienceFee: data.convenienceFee,
        affidavitSettings: data.affidavitReconciliationSettings,
        publicationDates: data.noticePublicationDates
    });
    const totalInCents = subtotalInCents + convenienceFeeInCents + affidavitFeeInCents + taxInCents;
    const publisherFeeSplits = removeUndefinedFields({
        convenienceFeeSplitResult: invoiceTrackedConvenienceFeeSplitResult,
        autoAffidavitFeeSplitResult: invoiceTrackedAffidavitFeeSplitResult
    });
    return Object.assign({ totalInCents,
        subtotalInCents,
        convenienceFeeInCents,
        affidavitFeeInCents,
        taxInCents }, (Object.keys(publisherFeeSplits).length > 0 && { publisherFeeSplits }));
};
export const getColumnRepFeeFromNewspaper = (newspaperSnap) => {
    const additionalFees = newspaperSnap.data().additionalFees || [];
    const existingColumnRepFee = additionalFees === null || additionalFees === void 0 ? void 0 : additionalFees.find(fee => fee.description === COLUMN_REP_FEE);
    return existingColumnRepFee;
};
export const isColumnRepFee = (additionalFee) => {
    return (additionalFee === null || additionalFee === void 0 ? void 0 : additionalFee.description) === COLUMN_REP_FEE;
};
/**
 * Returns a ConvenienceFee with Column Rep fee:
 * - When it is a non-government rate, ex. the convenience fee on a rate > 0.
 * - In the case where there are rep fees on the rate and newspaper only one rep fee should be added.
 * - The rep fee on the rate overrides the rep fee on the newspaper.
 */
export const getConvenienceFeeWithRepFeeFromRateOrNewspaper = (newspaper, rate) => {
    const convenienceFeeFromRate = rateToConvenienceFee(rate.data());
    // If either of the following is true, the rate controls the convenience fee:
    // 1) The rate has the base "convenienceFeePct" set to 0
    // 2) The rate has the "columnRepFeePct" set to > 0
    if (convenienceFeeFromRate.convenienceFeePct === 0 ||
        (convenienceFeeFromRate.convenienceFeePct > 0 &&
            rate.data().columnRepFeePct)) {
        return convenienceFeeFromRate;
    }
    const convenienceFee = Object.assign({}, convenienceFeeFromRate);
    const columnRepFeeFromNewspaper = maybeGetColumnRepFeePctFromNewspaper(newspaper);
    if (columnRepFeeFromNewspaper) {
        convenienceFee.convenienceFeePct += columnRepFeeFromNewspaper;
    }
    return convenienceFee;
};
/**
 * Methods only exported for testing
 */
export const __private = {
    distributeDbPrice,
    rateToConvenienceFee,
    getColumnRepFeePctFromNewspaper
};
