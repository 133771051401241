var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import moment from 'moment';
import { wrapError, wrapSuccess } from '../../types/responses';
import { PUBLICATION_ISSUE_STATUS_CHANGE } from '../../types/events';
import { SnapshotModel, getModelFromSnapshot, getModelFromRef } from '..';
import { Collections } from '../../constants';
import { PublicationIssueStatus } from '../../types/publicationIssue';
import { PublicationIssueAttachmentModel } from './publicationIssueAttachmentModel';
import { PublicationIssueSectionModel } from './publicationIssueSectionModel';
import { PublicationIssueAttachmentStatus } from '../../types/publicationIssueAttachment';
import { getDateStringForDateInTimezone } from '../../utils/dates';
import { getOrThrow } from '../../utils/refs';
import { getRunModelsFromQuery } from '../../services/runService';
import { NoticeStatusType } from '../../enums';
import { EEditionModel } from './EEditionModel';
import { OrganizationModel } from './organizationModel';
import { getNewSectionStatus } from '../../publicationIssues/publicationIssueSections';
import { safeAsync } from '../../safeWrappers';
import { getProductDeadlineTimeForPaper } from '../../utils/deadlines';
import { getErrorReporter } from '../../utils/errors';
import { PublishingMedium } from '../../enums/PublishingMedium';
export class PublicationIssueModel extends SnapshotModel {
    get type() {
        return Collections.publicationIssues;
    }
    isAtLeastNDaysOld(days) {
        return moment(this.modelData.publicationDate, 'YYYY-MM-DD').isBefore(moment().subtract(days, 'days'));
    }
    get isPast() {
        return this.isAtLeastNDaysOld(0);
    }
    getAttachments() {
        return __awaiter(this, void 0, void 0, function* () {
            const publicationIssueAttachmentQuery = yield this.ctx
                .publicationIssueAttachmentsRef(this.ref)
                .orderBy('createdAt', 'asc')
                .get();
            const filteredPublicationIssueAttachmentSnaps = publicationIssueAttachmentQuery.docs.filter(doc => doc.data().status !== PublicationIssueAttachmentStatus.DELETED);
            const publicationIssueAttachments = filteredPublicationIssueAttachmentSnaps.map(snap => new PublicationIssueAttachmentModel(this.ctx, { snap }));
            return publicationIssueAttachments;
        });
    }
    /**
     * Currently this method will return all non-disabled, non-cancelled runs associated with the publication issue.
     * This includes runs for notices that are archived (affidavit uploaded), so the runs returned here may not match
     * the notices returned from slowGetNoticesForPublicationIssue.
     *
     * We may update this in the future but for now devs should be aware of this distinction!
     */
    getRuns(options = {
        includeDisabled: false,
        includeCancelled: false
    }) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const runsQuery = this.ctx
                    .runsRef()
                    .where('publicationIssue', '==', this.ref);
                const runs = yield getRunModelsFromQuery(this.ctx, runsQuery, options);
                return wrapSuccess(runs);
            }
            catch (err) {
                return wrapError(err);
            }
        });
    }
    getEEditions() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const eeditionsQuery = yield this.ctx
                    .eeditionsRef()
                    .where('publicationIssue', '==', this.ref)
                    .get();
                const eeditions = eeditionsQuery.docs.map(snap => getModelFromSnapshot(EEditionModel, this.ctx, snap));
                return wrapSuccess(eeditions);
            }
            catch (err) {
                return wrapError(err);
            }
        });
    }
    get publicationIssueAttachments() {
        return this.ctx.publicationIssueAttachmentsRef(this.ref);
    }
    /**
     * Updates the status and sends an event logging who made the change and what it was changed to
     */
    updateStatus(changedBy, status) {
        return __awaiter(this, void 0, void 0, function* () {
            const beforeStatus = this.modelData.status;
            if (beforeStatus === status) {
                return {
                    error: new Error('Publication issue already in this status'),
                    response: null
                };
            }
            try {
                yield this.update({
                    status
                });
            }
            catch (err) {
                return {
                    error: err,
                    response: null
                };
            }
            try {
                const attachments = yield this.getAttachments();
                yield this.ctx.eventsRef().add({
                    type: PUBLICATION_ISSUE_STATUS_CHANGE,
                    publicationIssue: this.ref,
                    createdAt: this.ctx.timestamp(),
                    newspaper: this.modelData.publisher,
                    data: {
                        beforeStatus,
                        afterStatus: status,
                        changedBy: changedBy.ref,
                        attachmentData: attachments.map(attachment => attachment.modelData)
                    }
                });
                return {
                    error: null,
                    response: true
                };
            }
            catch (err) {
                return {
                    error: err,
                    response: null
                };
            }
        });
    }
    updateDeadline(params) {
        var _a;
        return __awaiter(this, void 0, void 0, function* () {
            const { deadlineTimestamp } = params;
            if (((_a = this.modelData.deadlineTimestamp) === null || _a === void 0 ? void 0 : _a.toMillis()) !==
                deadlineTimestamp.toMillis()) {
                yield this.update({
                    deadlineTimestamp
                });
            }
        });
    }
    update(requestedParams) {
        const _super = Object.create(null, {
            update: { get: () => super.update }
        });
        return __awaiter(this, void 0, void 0, function* () {
            // TODO(goodpaul): Abstract this to be used by publicationIssue and publicationIssueAttachment
            const params = Object.assign(Object.assign({}, requestedParams), { 
                // Need to figure out how to use the EDocumentData/EPartialDocumentData to stop using as any
                modifiedAt: this.ctx.fieldValue().serverTimestamp() });
            if (params.status) {
                params.statusChanges = [
                    ...(this.modelData.statusChanges || []),
                    {
                        status: params.status,
                        // TODO(goodpaul): Move these to a subcollection so we can use the proper fieldValue timestamp
                        timestamp: this.ctx.timestamp()
                    }
                ];
            }
            yield _super.update.call(this, params);
        });
    }
    /**
     * Loads all of the notices associated with a publication issue
     * TODO: replace this with an indexed query once publicationIssues are on our usernotice model
     */
    slowGetNoticesForPublicationIssue() {
        return __awaiter(this, void 0, void 0, function* () {
            const { publisher } = this.modelData;
            const noticeQuery = yield this.ctx
                .userNoticesRef()
                .where('newspaper', '==', publisher)
                .where('isArchived', '==', false)
                .where('noticeStatus', '!=', NoticeStatusType.cancelled.value)
                .get();
            const { publicationDate } = this.modelData;
            const publisherSnapshot = yield getOrThrow(publisher);
            const publisherTimezone = publisherSnapshot.data().iana_timezone;
            if (!publisherTimezone) {
                return {
                    response: null,
                    error: new Error('Publisher does not have a timezone')
                };
            }
            const noticesPublishingOnDate = noticeQuery.docs.filter(doc => {
                const { publicationDates, noticeStatus } = doc.data();
                // Notice status is only set after notice confirmation - don't show
                // notices that are still in draft stage
                if (!noticeStatus)
                    return false;
                if (!publicationDates)
                    return false;
                return publicationDates.some(noticeDate => {
                    const noticeDateString = getDateStringForDateInTimezone({
                        date: noticeDate.toDate(),
                        timezone: publisherTimezone
                    });
                    return noticeDateString === publicationDate;
                });
            });
            return {
                response: noticesPublishingOnDate,
                error: null
            };
        });
    }
    /**
     * Updates the deadline of the given section if the publication schedule has changed
     * @param publicationIssueSection - The publication issue section
     * @param deadlineMoment - The deadline according to the current publication schedule
     */
    updatePublicationSectionDeadline(publicationIssueSection, deadlineMoment) {
        var _a;
        return __awaiter(this, void 0, void 0, function* () {
            const publicationDateMoment = moment(this.modelData.publicationDate, 'YYYY-MM-DD');
            const now = moment();
            // Do not update the deadline for past publication issue sections
            if (publicationDateMoment.isBefore(now)) {
                return;
            }
            // Compare the existing deadline with the newspaper deadline
            const deadlineTimestamp = deadlineMoment.valueOf();
            const deadlineFirebaseTimestamp = this.ctx.timestampFromDate(deadlineMoment.toDate());
            const existingDeadlineTimestamp = (_a = publicationIssueSection.modelData.deadlineTimestamp) === null || _a === void 0 ? void 0 : _a.toMillis();
            if (deadlineTimestamp !== existingDeadlineTimestamp) {
                // Update the deadline if required
                yield publicationIssueSection.ref.update({
                    deadlineTimestamp: deadlineFirebaseTimestamp
                });
            }
        });
    }
    /**
     * This function returns the publication issue section of the current issue for the given section type (Obit, Classified,..) if
     * the newspaper is configured for that product. If the section does not exist, it will create a new section.
     */
    maybeGetOrCreateSection(sectionType) {
        return __awaiter(this, void 0, void 0, function* () {
            // TODO: Pass this in
            const publishingMedium = PublishingMedium.Print;
            const publicationIssueSectionQuery = yield this.ctx
                .publicationIssueSectionsRef(this.ref)
                .where('type', '==', sectionType)
                .get();
            const newspaperSnap = yield getOrThrow(this.modelData.publisher);
            const newspaper = getModelFromSnapshot(OrganizationModel, this.ctx, newspaperSnap);
            const publisherTimezone = newspaper.modelData.iana_timezone;
            const { response: deadlineResult, error: deadlineResultError } = yield getProductDeadlineTimeForPaper(newspaper, sectionType, publishingMedium, this.modelData.publicationDate);
            if (deadlineResultError) {
                return wrapError(deadlineResultError);
            }
            if (deadlineResult === null) {
                getErrorReporter().logInfo('Newspaper not configured for product', {
                    newspaperId: newspaper.id,
                    product: sectionType
                });
                return wrapSuccess(null);
            }
            const { deadlineMoment, deadlineSettings } = deadlineResult;
            const isNewspaperPublishing = !!(deadlineSettings === null || deadlineSettings === void 0 ? void 0 : deadlineSettings.publish);
            // Return the section if exists
            if (!publicationIssueSectionQuery.empty) {
                const publicationIssueSection = getModelFromSnapshot(PublicationIssueSectionModel, this.ctx, publicationIssueSectionQuery.docs[0]);
                // TODO: Move state management out of the maybeGetOrCreateSection method.  What should drive this?
                if (!isNewspaperPublishing &&
                    publicationIssueSection.modelData.status ===
                        PublicationIssueStatus.PENDING) {
                    yield publicationIssueSection.updateStatus(null, PublicationIssueStatus.DISABLED);
                }
                else if (isNewspaperPublishing &&
                    publicationIssueSection.modelData.status ===
                        PublicationIssueStatus.DISABLED) {
                    yield publicationIssueSection.updateStatus(null, PublicationIssueStatus.PENDING);
                }
                // Update the section current deadline if the deadline has changed
                yield this.updatePublicationSectionDeadline(publicationIssueSection, deadlineMoment);
                return wrapSuccess(publicationIssueSection);
            }
            // Create a new section
            // Determine the deadline of the publication date
            // Obituaries use a different deadline settings
            const deadlineTimestamp = this.ctx.timestampFromDate(deadlineMoment.toDate());
            // Insert the new section
            const newSectionStatus = getNewSectionStatus(isNewspaperPublishing, this.modelData.publicationDate, publisherTimezone);
            const newSectionRef = yield this.ctx
                .publicationIssueSectionsRef(this.ref)
                .add({
                status: newSectionStatus,
                deadlineTimestamp,
                type: sectionType
            });
            const sectionModel = yield getModelFromRef(PublicationIssueSectionModel, this.ctx, newSectionRef);
            return wrapSuccess(sectionModel);
        });
    }
    static fromRef(ctx, ref) {
        return __awaiter(this, void 0, void 0, function* () {
            const safeGet = safeAsync(getModelFromRef);
            return safeGet(PublicationIssueModel, ctx, ref);
        });
    }
}
