import Joi from 'joi';
import { z } from 'zod';
import { PaywayCardTransactionDataSchema } from './payway';
import { FirebaseTimestampSchema } from './firebase';
export const GatewayTransactionDataSchema = PaywayCardTransactionDataSchema;
export const GatewaySessionResponseSchema = Joi.object({
    sessionToken: Joi.string().required()
});
export const InvoiceTransactionsCreateSessionResponseSchema = Joi.object({
    gatewaySessionResponse: GatewaySessionResponseSchema.required(),
    idempotencyKey: Joi.string().required()
});
// TODO(goodpaul): Move InvoiceTransactionsCreateChargeRequest to zod then we can deduplicate types
export const InvoiceTransactionsAuthorizeRequestSchema = z.object({
    amountInCents: z.number(),
    idempotencyKey: z.string(),
    customerEmail: z.string(),
    invoiceId: z.string(),
    paymentMethodToken: z.string(),
    paymentMethodType: z.string(),
    paymentSessionToken: z.string().optional()
});
export const PaymentGatewayResponseSchema = Joi.object({
    rawResponse: Joi.string().required(),
    responseCode: Joi.string().required(),
    responseMessage: Joi.string().required(),
    isSuccessful: Joi.boolean().required(),
    sourceGatewayTransactionId: Joi.string(),
    gatewayTransactionId: Joi.string(),
    paymentMethodId: Joi.string(),
    gatewayTransactionDate: Joi.string(),
    amountInCents: Joi.number(),
    surchargeInCents: Joi.number()
});
export var InvoiceTransactionStatus;
(function (InvoiceTransactionStatus) {
    InvoiceTransactionStatus["Failed"] = "failed";
    InvoiceTransactionStatus["Initialized"] = "initialized";
    InvoiceTransactionStatus["Pending"] = "pending";
    /**
     * Completed statuses below
     * Why not just 'completed'? Having explicit completed statuses allows us to
     * have a single discriminator for type narrowing instead of type and status
     */
    InvoiceTransactionStatus["Authorized"] = "authorized";
    InvoiceTransactionStatus["Captured"] = "captured";
    InvoiceTransactionStatus["Charged"] = "charged";
    InvoiceTransactionStatus["Refunded"] = "refunded";
    InvoiceTransactionStatus["Credited"] = "credited";
})(InvoiceTransactionStatus || (InvoiceTransactionStatus = {}));
export const CompletedInvoiceTransactionStatuses = [
    InvoiceTransactionStatus.Authorized,
    InvoiceTransactionStatus.Captured,
    InvoiceTransactionStatus.Charged,
    InvoiceTransactionStatus.Refunded,
    InvoiceTransactionStatus.Credited
];
export var InvoiceTransactionType;
(function (InvoiceTransactionType) {
    InvoiceTransactionType["Charge"] = "charge";
    InvoiceTransactionType["Refund"] = "refund";
    InvoiceTransactionType["Authorize"] = "authorize";
    InvoiceTransactionType["Capture"] = "capture";
    InvoiceTransactionType["Credit"] = "credit";
})(InvoiceTransactionType || (InvoiceTransactionType = {}));
export function isCompletedInvoiceTransactionStatus(status) {
    return CompletedInvoiceTransactionStatuses.includes(status);
}
const InvoiceTransactionBase = z.object({
    createdAt: FirebaseTimestampSchema,
    modifiedAt: FirebaseTimestampSchema,
    // The idempotency key for this transaction. Used by the FE to prevent duplicate payments
    idempotencyKey: z.string(),
    /**
     * Payment gateway for transaction, previously set only on the invoice
     * TODO(goodpaul) Remove optionality after migration
     */
    gateway: z.string().optional(),
    status: z.nativeEnum(InvoiceTransactionStatus),
    type: z.nativeEnum(InvoiceTransactionType)
});
export const InitializedInvoiceTransactionSchema = InvoiceTransactionBase.extend({
    status: z.literal(InvoiceTransactionStatus.Initialized)
});
// TODO(goodpaul): Add gatewayRequest prop
export const PendingInvoiceTransactionSchema = InvoiceTransactionBase.extend({
    status: z.literal(InvoiceTransactionStatus.Pending),
    amountSubmittedInCents: z.number(),
    paymentMethodId: z.string().optional(),
    paymentMethodType: z.string().optional(),
    customerEmail: z.string().optional()
});
export const FailedInvoiceTransactionSchema = InvoiceTransactionBase.extend({
    status: z.literal(InvoiceTransactionStatus.Failed),
    amountSubmittedInCents: z.number(),
    gatewayRawResponse: z.string(),
    gatewayResponse: z.string(),
    gatewayTransactionDate: z.string().optional(),
    sourceGatewayTransactionId: z.string().optional()
});
const CompletedInvoiceTransactionSchemaBase = InvoiceTransactionBase.extend({
    status: z.enum(CompletedInvoiceTransactionStatuses),
    // The amount the payment gateway says was processed
    amountProcessedInCents: z.number(),
    // The amount we sent to the payment gateway
    amountSubmittedInCents: z.number(),
    // Records a stringified version of the gateway response
    gatewayRawResponse: z.string(),
    // The response message from the gateway
    gatewayResponse: z.string(),
    // Unique identifier for the gateway transaction, for Stripe authCapture this is the payment intent
    gatewayTransactionId: z.string(),
    // Date of gateway transaction according to the payment gateway (requires migration to make non-nullable)
    gatewayTransactionDate: z.string().optional()
});
export const ChargedInvoiceTransactionSchema = CompletedInvoiceTransactionSchemaBase.extend({
    status: z.literal(InvoiceTransactionStatus.Charged),
    type: z.literal(InvoiceTransactionType.Charge),
    // Gateway reference for the payment information
    paymentMethodId: z.string(),
    // Payment method type (e.g. card, ach)
    paymentMethodType: z.string(),
    // Surcharge amount in cents, if any applied (currently, only Elavon for Lee Adpoint applies a surcharge)
    surchargeInCents: z.number().optional()
});
export const RefundedInvoiceTransactionSchema = CompletedInvoiceTransactionSchemaBase.extend({
    status: z.literal(InvoiceTransactionStatus.Refunded),
    type: z.literal(InvoiceTransactionType.Refund),
    // gatewayTransactionId referenced for current transaction e.g. charge id for a refund, not present for charges
    sourceGatewayTransactionId: z.string(),
    refundReason: z.string().optional()
});
export const AuthorizedInvoiceTransactionSchema = CompletedInvoiceTransactionSchemaBase.extend({
    status: z.literal(InvoiceTransactionStatus.Authorized),
    type: z.literal(InvoiceTransactionType.Authorize),
    customerEmail: z.string(),
    // Gateway reference for the payment information
    paymentMethodId: z.string(),
    // Payment method type (e.g. card, ach)
    paymentMethodType: z.string(),
    // TODO(goodpaul): Remove this non-optionality assertion after this prop is added to all invoice transacitons
    gateway: z.string()
});
export const CapturedInvoiceTransactionSchema = CompletedInvoiceTransactionSchemaBase.extend({
    status: z.literal(InvoiceTransactionStatus.Captured),
    type: z.literal(InvoiceTransactionType.Capture)
});
export const CreditedInvoiceTransactionSchema = CompletedInvoiceTransactionSchemaBase.extend({
    status: z.literal(InvoiceTransactionStatus.Credited),
    type: z.literal(InvoiceTransactionType.Credit)
});
// Union of all InvoiceTransaction Types
export const InvoiceTransactionSchema = z.discriminatedUnion('status', [
    InitializedInvoiceTransactionSchema,
    PendingInvoiceTransactionSchema,
    FailedInvoiceTransactionSchema,
    ChargedInvoiceTransactionSchema,
    RefundedInvoiceTransactionSchema,
    AuthorizedInvoiceTransactionSchema,
    CapturedInvoiceTransactionSchema,
    CreditedInvoiceTransactionSchema
]);
/**
 * This is a typeguard that, in conjunction with the map above, helps narrow the type of functions
 * that return an invoice transaction of a particular status
 */
export function isInvoiceTransactionOfStatus(model, status) {
    return model.modelData.status === status;
}
/* eslint-disable default-case */
/**
 * We no longer use both type and status as a discriminator and have moved to descriptive "completed" status. This function
 * translates the invoice transaction type to the equivalent "completed" status.
 * We disable the default-case lint rule so we get a compile-time error if we add a new type and forget to add it here.
 */
export function invoiceTransactionTypeToCompletedStatus(type) {
    switch (type) {
        case InvoiceTransactionType.Authorize:
            return InvoiceTransactionStatus.Authorized;
        case InvoiceTransactionType.Capture:
            return InvoiceTransactionStatus.Captured;
        case InvoiceTransactionType.Refund:
            return InvoiceTransactionStatus.Refunded;
        case InvoiceTransactionType.Charge:
            return InvoiceTransactionStatus.Charged;
        case InvoiceTransactionType.Credit:
            return InvoiceTransactionStatus.Credited;
    }
}
