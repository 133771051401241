var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import Firebase from 'EnoticeFirebase';
import { getLogger } from 'utils/logger';
import { HEADER_SESSION_ID_KEY } from 'lib/types/api';
class Api {
    getRestUrl(path) {
        return Firebase.functions()._url(`api/${path}`);
    }
    /**
     * When calling our API we send a Firebase ID token which has a 1-hour lifetime.
     * The Firebase Auth SDK automatically refreshes tokens when they're within 5m of
     * expiring and makes sure tokens returned from getIdTokenI() have at least 30s
     * remaining.
     *
     * However if the client and server have significant clock skew then the Firebase
     * Auth SDK can fail to detect that the token is actually expired and the server
     * will reject it.
     */
    checkForClockSkew(resp) {
        var _a;
        const dateHeader = (_a = resp === null || resp === void 0 ? void 0 : resp.headers) === null || _a === void 0 ? void 0 : _a.get('date');
        if (!dateHeader) {
            return;
        }
        const serverTime = new Date(dateHeader).getTime();
        if (isNaN(serverTime)) {
            return;
        }
        const clientTime = new Date().getTime();
        const diff = clientTime - serverTime;
        if (Math.abs(diff) > 60000) {
            console.error(`Client time and server time differ by more than 1m (${diff}ms)`);
        }
    }
    getToken() {
        return __awaiter(this, void 0, void 0, function* () {
            const userAuth = Firebase.auth().currentUser;
            if (!userAuth)
                throw new Error('No userauth set');
            // If the ID token has less than 5 minutes of validity remaining, we
            // forcibly refresh it. This should normally be hanled by the Firebase
            // SDK but some users were still getting expired tokens.
            const idTokenRes = yield userAuth.getIdTokenResult();
            const expirationDate = new Date(idTokenRes.expirationTime);
            const timeToExpiry = expirationDate.getTime() - new Date().getTime();
            if (timeToExpiry < 5 * 60 * 1000) {
                return yield userAuth.getIdToken(true);
            }
            return idTokenRes.token;
        });
    }
    getCommonHeaders() {
        return __awaiter(this, void 0, void 0, function* () {
            const headers = {};
            try {
                const token = yield this.getToken();
                headers.Authorization = `Bearer ${token}`;
            }
            catch (err) { }
            const sessionId = getLogger().getSessionId();
            if (sessionId) {
                headers[HEADER_SESSION_ID_KEY] = sessionId;
            }
            return headers;
        });
    }
    get(path, params, headersOverride) {
        return __awaiter(this, void 0, void 0, function* () {
            const headers = yield this.getCommonHeaders();
            let apiUrl = this.getRestUrl(path);
            if (params) {
                const searchParams = new URLSearchParams(Object.assign({}, params));
                apiUrl += `?${searchParams.toString()}`;
            }
            const resp = yield fetch(apiUrl, {
                headers: Object.assign(Object.assign({}, headers), headersOverride)
            });
            this.checkForClockSkew(resp);
            if (resp.status !== 200)
                throw new Error(`${resp.status} error: ${yield resp.text()}`);
            return yield resp.json();
        });
    }
    post(path, data) {
        return __awaiter(this, void 0, void 0, function* () {
            const headers = yield this.getCommonHeaders();
            headers['Content-Type'] = 'application/json';
            const url = this.getRestUrl(path);
            const resp = yield fetch(url, {
                method: 'POST',
                body: JSON.stringify(data || {}),
                headers
            });
            this.checkForClockSkew(resp);
            if (resp.status !== 200)
                throw new Error(`${resp.status} error: ${yield resp.text()}`);
            return yield resp.json();
        });
    }
}
export default new Api();
