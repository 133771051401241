var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { CheckCircleIcon, ExclamationCircleIcon, XMarkIcon } from '@heroicons/react/24/outline';
import classNames from 'classnames';
import { useFetchAvailablePublishers } from 'hooks/useFetchAvailablePublishers';
import { Autocomplete } from 'lib/components/Autocomplete';
import { Badge } from 'lib/components/Badge';
import { Product } from 'lib/enums';
import { useContext, useState } from 'react';
import { PublisherLocationFilter } from 'routes/placeScroll/ConfirmPublisher/PublisherLocationFilter';
import { getFirebaseContext } from 'utils/firebase';
import { NewspaperOrderStatus } from 'lib/types/newspaperOrder';
import { PublishingMedium } from 'lib/enums/PublishingMedium';
import { PRODUCT_TO_NAME } from 'lib/enums/Product';
import { getModelFromSnapshot } from 'lib/model';
import { getOrThrow } from 'lib/utils/refs';
import { OrganizationModel } from 'lib/model/objects/organizationModel';
import { isAnonymousOrder } from 'lib/types/order';
import { Alert } from 'lib/components/Alert';
import { logAndCaptureMessage, logAndCaptureException } from 'utils';
import { CLASSIFIED_LAYOUTS } from 'lib/orders/layouts';
import { getPublisherOrgOrderTemplate } from 'lib/utils/templates';
import { ColumnService } from 'lib/services/directory';
import MultiStepHeader from '../components/MultiStepHeader';
import { NewspapersContext } from '../contexts/NewspapersContext';
import NewspaperOrgImage from '../components/PublisherImage';
import { shouldExcludeNewspaperOrder } from './CategoryChoice/helpers';
function PublisherBadge({ newspaper, onClose }) {
    return (_jsx("span", Object.assign({ role: "button", className: "m-1 cursor-default" }, { children: _jsx(Badge, Object.assign({ endIcon: _jsx(XMarkIcon, { role: "button", className: "w-4 h-4 text-xs", onClick: onClose }), status: "info" }, { children: newspaper.data().name })) })));
}
function PublisherCard({ newspaper, selected }) {
    return (_jsxs("div", Object.assign({ className: classNames('flex items-center justify-center border rounded-md relative col-span-6 md:col-span-4 xl:col-span-3 h-20 px-2 py-2', {
            'border-primary-500': selected,
            'bg-white': selected
        }) }, { children: [_jsx(NewspaperOrgImage, { newspaper: newspaper, className: "max-h-full" }), _jsx("div", Object.assign({ className: "absolute px-1 py-1 top-0 right-0" }, { children: _jsx(CheckCircleIcon, { className: "h-4 w-4 text-primary-500" }) }))] })));
}
function SelectPublication({ newspaperOrdersFormData, onNewspaperOrdersFormDataChange, product, inputData, orderModel, userAlerts, setUserAlerts }) {
    const productTypeName = PRODUCT_TO_NAME[product].singular.toLowerCase();
    const context = getFirebaseContext();
    const organizations = context.organizationsRef();
    const [searchedNewspaperId, setSearchedNewspaperId] = useState(undefined);
    const [addNewspaperAlert, setAddNewspaperAlert] = useState('');
    const anonymousOrder = isAnonymousOrder(orderModel.modelData);
    const newspapers = useContext(NewspapersContext);
    const searchedNewspaperLoaded = newspapers.find(o => o.id === searchedNewspaperId);
    const { loading, stateOptions, stateFilter, setStateFilter, publisherOptions } = useFetchAvailablePublishers({
        restrictedSingleState: undefined,
        isUserPublisher: false,
        restrictedPublisherIds: [],
        product
    });
    function addNewspaper(newspaperId) {
        return __awaiter(this, void 0, void 0, function* () {
            if (newspaperOrdersFormData.find(o => { var _a; return ((_a = o.newspaper) === null || _a === void 0 ? void 0 : _a.id) === newspaperId; })) {
                return;
            }
            const newspaperRef = organizations.doc(newspaperId);
            // We need the filing type model out of the block below
            // TODO: refactor this with obit category select unification. We should move this into a helper to set layout and filing type for a newspaper order.
            let classifiedFilingType;
            // Check if newspaper supports category (aka filing type) selected. We only need to do this for classifieds
            if (product === Product.Classified && (inputData === null || inputData === void 0 ? void 0 : inputData.filingTypeLabel)) {
                const newspaperSnapshot = yield getOrThrow(newspaperRef);
                const organizationModel = getModelFromSnapshot(OrganizationModel, context, newspaperSnapshot);
                const selectedFilingType = inputData.filingTypeLabel;
                const { response: matchedFilingType, error: filingTypeMatchError } = yield organizationModel.isFilingTypeAvailableForNewspaper({
                    selectedFilingType: selectedFilingType,
                    product,
                    publishingMedium: PublishingMedium.Print,
                    anonymousOrder
                });
                /*
                 * Set add error alert if filing type is unavailable for selected newspaper.
                 */
                if (shouldExcludeNewspaperOrder({
                    matchedFilingType,
                    filingTypeMatchError,
                    anonymousOrder
                })) {
                    setAddNewspaperAlert(`${organizationModel.modelData.name} selected does not support ${inputData.filingTypeLabel}. Please select another publisher.`);
                    return;
                }
                // Internal error
                if (filingTypeMatchError) {
                    logAndCaptureMessage(filingTypeMatchError.message, {
                        addNewspaper: newspaperId
                    });
                    throw new Error(filingTypeMatchError.message);
                }
                if (matchedFilingType) {
                    classifiedFilingType = matchedFilingType;
                }
                setAddNewspaperAlert('');
            }
            const { response: adTemplate, error } = yield getPublisherOrgOrderTemplate(getFirebaseContext(), newspaperRef, product);
            if (error) {
                logAndCaptureException(ColumnService.OBITS, error, 'Unable to get the template for order', { newspaperId: newspaperRef.id, product });
                return;
            }
            onNewspaperOrdersFormDataChange(prevOrders => [
                ...prevOrders,
                Object.assign(Object.assign({ newspaper: newspaperRef, adTemplate, publishingDates: [], status: NewspaperOrderStatus.DRAFT, 
                    // TODO: pass this in
                    publishingMedium: PublishingMedium.Print }, (product === Product.Classified
                    ? {
                        layout: CLASSIFIED_LAYOUTS[0]
                    }
                    : {})), (classifiedFilingType
                    ? {
                        filingType: classifiedFilingType.ref
                    }
                    : {}))
            ]);
            setSearchedNewspaperId(newspaperId);
        });
    }
    function removeNewspaper(newspaperId) {
        return __awaiter(this, void 0, void 0, function* () {
            const existingIndex = newspaperOrdersFormData.findIndex(o => { var _a; return ((_a = o.newspaper) === null || _a === void 0 ? void 0 : _a.id) === newspaperId; });
            const updatedNewspaperOrders = [
                ...newspaperOrdersFormData.slice(0, existingIndex),
                ...newspaperOrdersFormData.slice(existingIndex + 1)
            ];
            onNewspaperOrdersFormDataChange(updatedNewspaperOrders);
        });
    }
    const formattedPublisherOptions = publisherOptions.map(o => ({
        label: o.name,
        value: o.id
    }));
    const title = `Where would you like to place this ${productTypeName}?`;
    const description = 'Select one or more publications to place your listing.';
    return (_jsxs(_Fragment, { children: [_jsx(MultiStepHeader, { title: title, description: description }), addNewspaperAlert && (_jsx(Alert, { id: "add-newspaper-error", onDismiss: () => setAddNewspaperAlert(''), title: addNewspaperAlert, status: "error", icon: _jsx(ExclamationCircleIcon, { className: "h-5 w-5" }) })), userAlerts.length > 0 && (_jsx(Alert, { id: "ad-newspaper-removed-alert", status: "info", title: 'One or more publications have been removed from your order.', description: userAlerts.map(alert => (_jsx("div", Object.assign({ className: "mt-4" }, { children: alert }), alert))), onDismiss: () => {
                    setUserAlerts([]);
                }, icon: _jsx(ExclamationCircleIcon, { className: "h-5 w-5" }) })), _jsxs("div", Object.assign({ className: "grid grid-cols-12 gap-6" }, { children: [_jsxs("div", Object.assign({ className: "col-span-12 md:col-span-6 xl:col-span-8" }, { children: [_jsx(Autocomplete, { id: "selectPublisher", labelText: "Publisher", placeholder: "Select a publisher", value: searchedNewspaperLoaded && !loading ? searchedNewspaperId : '', options: formattedPublisherOptions, onChange: addNewspaper, loading: loading, required: newspapers.length === 0, validationMessages: {
                                    valueMissing: 'Please select a publisher'
                                } }), newspaperOrdersFormData.map(newspaperOrder => {
                                const newspaper = newspapers.find(o => { var _a; return o.id === ((_a = newspaperOrder.newspaper) === null || _a === void 0 ? void 0 : _a.id); });
                                if (newspaper) {
                                    return (_jsx(PublisherBadge, { newspaper: newspaper, onClose: () => {
                                            void removeNewspaper(newspaper.id);
                                        } }, newspaper.id));
                                }
                                return null;
                            })] })), _jsx("div", Object.assign({ className: "col-span-12 md:col-span-6 xl:col-span-4 pt-8" }, { children: _jsx(PublisherLocationFilter, { stateOptions: stateOptions, onStateChange: state => {
                                setStateFilter(state);
                            }, activeFilters: { stateFilter } }) })), newspaperOrdersFormData.map(newspaperOrder => {
                        /**
                         * We are traversing newspaperOrders instead of the newspapers context
                         * so the soft deleting of newspaperOrders can be reflected in the UI
                         */
                        const newspaper = newspapers.find(o => { var _a; return o.id === ((_a = newspaperOrder.newspaper) === null || _a === void 0 ? void 0 : _a.id); });
                        if (!newspaper)
                            return null;
                        return (_jsx(PublisherCard, { newspaper: newspaper, selected: true }, newspaper.id));
                    })] }))] }));
}
export default SelectPublication;
