import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { ColumnSelect } from 'lib/components/ColumnSelect';
import moment from 'moment';
import React, { useCallback } from 'react';
const getDaysInMonth = (month, year) => {
    const DEFAULT_DAYS_IN_MONTH = 31;
    if (isNaN(month) || isNaN(year)) {
        return DEFAULT_DAYS_IN_MONTH;
    }
    return new Date(year, month, 0).getDate();
};
const monthsOptions = moment.months().map((name, index) => ({
    label: name,
    value: (index + 1).toString()
}));
const yearsOptions = Array.from({ length: 121 }, (_, i) => {
    const year = new Date().getFullYear() - i;
    return {
        label: year.toString(),
        value: year.toString()
    };
}).reverse();
function ThreeFieldDateSelect({ id, value, onChange, required, checkAggregateDateValidation }) {
    const daysInMonth = getDaysInMonth(value.month, value.year);
    const handleSelectChange = useCallback((name, newValue) => {
        const adjustedValue = parseInt(newValue, 10);
        const updatedValue = Object.assign(Object.assign({}, value), { [name]: adjustedValue });
        if (name === 'month' || name === 'year') {
            const newDaysInMonth = getDaysInMonth(updatedValue.month, updatedValue.year);
            if (updatedValue.day > newDaysInMonth) {
                // Adjust the day if it exceeds the number of days in the new month
                onChange(Object.assign(Object.assign({}, updatedValue), { day: newDaysInMonth }));
                return;
            }
        }
        onChange(updatedValue);
    }, [value, onChange]);
    const daysOptions = Array.from({ length: daysInMonth }, (_, i) => ({
        label: (i + 1).toString().padStart(2, '0'),
        value: (i + 1).toString()
    }));
    return (_jsxs(_Fragment, { children: [_jsx(ColumnSelect, { id: `${id}-day`, labelText: "Day", value: value.day.toString(), onChange: value => handleSelectChange('day', value), options: daysOptions, size: "small", allowUndefined: true, placeholder: "Day", required: required, checkDependentValidations: checkAggregateDateValidation }), _jsx(ColumnSelect, { id: `${id}-month`, labelText: "Month", value: value.month.toString(), onChange: value => handleSelectChange('month', value), options: monthsOptions, size: "small", allowUndefined: true, placeholder: "Month", required: required }), _jsx(ColumnSelect, { id: `${id}-year`, labelText: "Year", value: value.year.toString(), onChange: value => handleSelectChange('year', value), options: yearsOptions, size: "small", allowUndefined: true, placeholder: "Year", required: required })] }));
}
export default ThreeFieldDateSelect;
