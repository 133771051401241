var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import { TableLayout } from 'lib/components/TableLayout';
import { getDeadlineOverrideKeyFromDate } from 'lib/types/deadlines';
import moment from 'moment';
import { useState } from 'react';
import { useAppDispatch } from 'redux/hooks';
import ToastActions from 'redux/toast';
import { ScheduleChangesFilterDialog } from './FilterDialog';
import { ScheduleChangeForm } from './Form';
import { ScheduleChangesRow } from './TableRow';
import { DEFAULT_SCHEDULE_CHANGES_FILTER, getFilterHasChanges, getNumScheduleChangesFilters, getScheduleChangeSettingsForNewPublicationDate, publisherDeadlineOverridesToScheduleChangesTableData, shouldShowScheduleChangesTableItem } from './helpers';
export function ScheduleChanges({ productLine, activeOrganization, onChange, deadlineOverrides }) {
    const dispatch = useAppDispatch();
    const [editedScheduleChange, setEditedScheduleChange] = useState();
    const [editedScheduleChangeKey, setEditedScheduleChangeKey] = useState('');
    const [filter, setFilter] = useState(DEFAULT_SCHEDULE_CHANGES_FILTER);
    const [updatedFilter, setUpdatedFilter] = useState(filter);
    const filterHasChanges = getFilterHasChanges(filter, updatedFilter);
    if (editedScheduleChange) {
        return (_jsx(ScheduleChangeForm, { productLine: productLine, activeOrganization: activeOrganization, scheduleChange: editedScheduleChange, existingScheduleChangeKey: editedScheduleChangeKey, onClose: () => {
                setEditedScheduleChange(undefined);
                setEditedScheduleChangeKey('');
            }, onSubmit: (dayLabel, updatedScheduleChange, updatedDeadlineSettings) => __awaiter(this, void 0, void 0, function* () {
                const { publicationDate } = updatedScheduleChange;
                const newKey = getDeadlineOverrideKeyFromDate(publicationDate);
                const deleteKey = editedScheduleChangeKey;
                const newOverrides = Object.assign({}, deadlineOverrides);
                if (deleteKey) {
                    delete newOverrides[deleteKey];
                }
                newOverrides[newKey] = updatedDeadlineSettings;
                yield onChange(newOverrides);
                setEditedScheduleChange(undefined);
                setEditedScheduleChangeKey('');
                dispatch(ToastActions.toastSuccess({
                    headerText: 'Schedule Change Saved',
                    bodyText: `Your schedule change for ${dayLabel} has been saved successfully.`
                }));
            }) }));
    }
    return (_jsx(TableLayout, { filterable: {
            shouldShowTableItem: (item, search) => shouldShowScheduleChangesTableItem(item, search, filter),
            searchEnabled: false,
            additionalFilters: {
                applyFilterChanges: () => setFilter(updatedFilter),
                filterHasChanges,
                numFiltersActive: getNumScheduleChangesFilters(filter),
                resetFilters: () => {
                    setUpdatedFilter(DEFAULT_SCHEDULE_CHANGES_FILTER);
                    setFilter(DEFAULT_SCHEDULE_CHANGES_FILTER);
                },
                renderDialog: () => (_jsx(ScheduleChangesFilterDialog, { onUpdate: setUpdatedFilter, value: updatedFilter }))
            }
        }, header: {
            title: 'Schedule Changes',
            subtitle: 'Apply one-time schedule changes for specific publication dates.'
        }, renderRow: deadlineSetting => (_jsx(ScheduleChangesRow, { activeOrganization: activeOrganization, deadlineSetting: deadlineSetting })), archivable: {
            renderWarning: scheduleChange => ({
                header: `Delete the schedule change for ${moment(scheduleChange.publicationDate).format('dddd, M/D/YY')}`,
                body: 'Once deleted, the regular deadline for this publication date will apply.',
                buttonText: 'Delete'
            }),
            onArchive: ({ publicationDate }) => __awaiter(this, void 0, void 0, function* () {
                const newOverrides = Object.assign({}, deadlineOverrides);
                const deleteKey = getDeadlineOverrideKeyFromDate(publicationDate);
                delete newOverrides[deleteKey];
                yield onChange(newOverrides);
            }),
            enabledArchiveTooltip: 'Delete',
            isArchiveDisabled: false
        }, editable: {
            onEdit: scheduleChange => {
                setEditedScheduleChange(scheduleChange);
                setEditedScheduleChangeKey(getDeadlineOverrideKeyFromDate(scheduleChange.publicationDate));
            },
            editTooltip: 'Edit'
        }, creatable: {
            createButtonText: 'Add Schedule Change',
            onCreate: () => {
                const publicationDate = moment().add({ day: 1 }).toDate();
                setEditedScheduleChange(getScheduleChangeSettingsForNewPublicationDate(publicationDate, activeOrganization));
            }
        }, data: publisherDeadlineOverridesToScheduleChangesTableData(deadlineOverrides), columns: [
            'Publication Date',
            'Publishing',
            'Deadline Date',
            'Deadline Time'
        ] }));
}
