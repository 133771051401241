var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { keyBy, merge, values } from 'lodash';
import { Day } from '../enums';
import { wrapErrorAsColumnError, InternalServerError } from '../errors/ColumnErrors';
import { PublishingSettingModel } from '../model/objects/publishingSettingModel';
import { safeAsync, safeGetOrThrow } from '../safeWrappers';
import { FilingTypeModel } from '../model/objects/filingTypeModel';
import { safeGetModelArrayFromRefs, safeGetModelFromRef } from '../model/getModel';
import { isRef } from '../model/refs';
import { DEFAULT_TEMPLATE_ID_V2 } from '../constants';
const DEFAULT_DEADLINES = Day.items().map(day => ({
    dayEnum: day.value,
    publish: false,
    deadline: { dayEnum: day.value, time: '12:00' }
}));
export class PublishingSettingsService {
    constructor(ctx) {
        this.ctx = ctx;
    }
    fetchFilingTypesFromPublishingSettings(publishingSettings) {
        return __awaiter(this, void 0, void 0, function* () {
            const filingTypeRefs = publishingSettings.flatMap(({ modelData }) => modelData.filingTypes);
            return safeGetModelArrayFromRefs(FilingTypeModel, this.ctx, filingTypeRefs);
        });
    }
    createPublishingSetting(data, organizationRef) {
        return __awaiter(this, void 0, void 0, function* () {
            const { response: defaultTemplate, error } = yield safeGetOrThrow(this.ctx.adTemplatesRef().doc(DEFAULT_TEMPLATE_ID_V2));
            if (error) {
                return wrapErrorAsColumnError(error);
            }
            const { response: newTemplateRef, error: addTemplateError } = yield safeAsync(() => this.ctx.adTemplatesRef().add(defaultTemplate.data()))();
            if (addTemplateError) {
                return wrapErrorAsColumnError(addTemplateError);
            }
            const { response: newPublishingSettings, error: addError } = yield safeAsync(() => this.ctx.publishingSettingsRef().add(Object.assign({ deadlines: DEFAULT_DEADLINES, filingTypes: [], deadlineOverrides: {}, authorizedOrganization: organizationRef, adTemplate: newTemplateRef }, data)))();
            if (addError) {
                return wrapErrorAsColumnError(addError, InternalServerError);
            }
            return safeGetModelFromRef(PublishingSettingModel, this.ctx, newPublishingSettings);
        });
    }
    mergePublishingSettingsWithDefaults(oldSettings, defaultSettings) {
        if (!oldSettings) {
            return defaultSettings;
        }
        const fullSettings = oldSettings;
        fullSettings.deadlines = values(merge(keyBy(defaultSettings.deadlines, 'dayEnum'), keyBy(oldSettings.deadlines, 'dayEnum')));
        fullSettings.filingTypes = values(merge(keyBy(defaultSettings.filingTypes, 'label'), keyBy(oldSettings.filingTypes, 'label'))
        // Need to filter here because the display project was throwing a lint error
        ).filter((value) => isRef(value));
        return fullSettings;
    }
}
