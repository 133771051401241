var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
import { withStyles, createStyles } from '@material-ui/core/styles';
import { Typography, Box } from '@material-ui/core';
import { logAndCaptureException, logAndCaptureMessage } from 'utils';
import { useElements, useStripe, CardElement, Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { InvoiceType } from 'lib/types/invoices';
import { apiPost } from 'api/typed';
import { logInfo } from 'utils/logger';
import { InvoiceTransactionType } from 'lib/types/invoiceTransaction';
import { ColumnService } from 'lib/services/directory';
import { STRIPE_VARS } from '../../../../../../constants';
import PayInvoiceButton from '../buttons/PayInvoiceButton';
import { processStripeAuthorizationWithCard, processStripePaymentWithCard } from '../../helpers/processStripeInvoicePayment';
const styles = () => createStyles({
    flex: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    bold: {
        fontWeight: 'bold'
    }
});
function CheckoutForm({ payInvoiceData, invoicePricingData, disableSavedCards, enableAuthCapture, handleSuccessfulPayment, classes }) {
    const stripe = useStripe();
    const elements = useElements();
    const [userErr, setUserErr] = useState('');
    const [saveCreditCard, setSaveCreditCard] = useState(!disableSavedCards && !enableAuthCapture);
    const [idempotencyKey, setIdempotencyKey] = useState('');
    const [requestingSessionToken, setRequestingSessionToken] = useState(false);
    const [processingPayment, setProcessingPayment] = useState(false);
    const loading = requestingSessionToken || processingPayment;
    const { invoice } = payInvoiceData;
    const { id: invoiceId } = invoice;
    let customerToSaveCardOnName;
    if (payInvoiceData.type === InvoiceType.PUBLIC_NOTICE) {
        customerToSaveCardOnName = payInvoiceData.customerToSaveCardOnName;
    }
    if (!customerToSaveCardOnName && !disableSavedCards) {
        const errMsg = 'customerToSaveCardOnName is undefined, but saved cards are enabled!';
        logAndCaptureException(ColumnService.PAYMENTS, new Error(errMsg), errMsg, {
            invoiceId
        });
    }
    const requestSessionToken = () => __awaiter(this, void 0, void 0, function* () {
        if (!enableAuthCapture) {
            return;
        }
        setRequestingSessionToken(true);
        try {
            const response = yield apiPost('invoice-transactions/create-session', {
                invoiceId,
                sessionType: InvoiceTransactionType.Authorize
            });
            setIdempotencyKey(response.idempotencyKey);
            logInfo('Loaded Stripe payment session');
        }
        catch (err) {
            logAndCaptureException(ColumnService.PAYMENTS, err, 'Unable to get payment session token for Stripe', { invoiceId });
            setUserErr('Unable to start payment session. Try refreshing this page or contact help@column.us for support.');
        }
        setRequestingSessionToken(false);
    });
    useEffect(() => {
        void requestSessionToken();
    }, [enableAuthCapture]);
    /**
     * Currently this function handles both auth/capture as well as the charge flow. We may refactor that when we get to
     * final implementation, but for now this serves as an early window into the auth / capture data flow to work with the
     * new invoice and invoice transaction statuses/types
     */
    const handleStripeSubmission = () => __awaiter(this, void 0, void 0, function* () {
        var _a;
        setProcessingPayment(true);
        setUserErr('');
        if (!stripe || !elements) {
            // Stripe.js has not loaded yet. Make sure to disable
            // form submission until Stripe.js has loaded.
            return requestSessionToken();
        }
        const stripeElement = elements.getElement(CardElement);
        if (!stripeElement) {
            return requestSessionToken();
        }
        // Use your card Element with other Stripe.js APIs
        const { error, paymentMethod } = yield stripe.createPaymentMethod({
            type: 'card',
            card: stripeElement
        });
        if (error) {
            // We don't want to log simple user errors with entering their credit card
            // information, so we ignore some known codes.
            const shouldLogError = !(error.type === 'validation_error' &&
                ['incomplete_number', 'incomplete_zip', 'incomplete_cvc'].includes((_a = error.code) !== null && _a !== void 0 ? _a : ''));
            if (shouldLogError) {
                logAndCaptureException(ColumnService.PAYMENTS, error, 'Failed to create Stripe payment method', {
                    invoiceId
                });
            }
            setUserErr(error.message || '');
            setProcessingPayment(false);
            return requestSessionToken();
        }
        try {
            if (enableAuthCapture) {
                yield processStripeAuthorizationWithCard(paymentMethod.id, 'card', payInvoiceData, invoicePricingData, idempotencyKey);
            }
            else {
                yield processStripePaymentWithCard(paymentMethod.id, 'card', payInvoiceData, invoicePricingData, saveCreditCard);
            }
            handleSuccessfulPayment();
        }
        catch (err) {
            const userMessage = typeof err === 'string'
                ? err
                : 'There was a problem processing your payment';
            logAndCaptureMessage(err.message || err);
            setUserErr(userMessage);
            void requestSessionToken();
        }
        finally {
            setProcessingPayment(false);
        }
    });
    return (_jsxs("form", Object.assign({ onSubmit: e => {
            e.preventDefault();
            void handleStripeSubmission();
        } }, { children: [_jsx("div", Object.assign({ style: {
                    boxSizing: 'border-box',
                    border: '1px solid #DADADA',
                    boxShadow: '0px 1px 3px rgba(230, 235, 241, 0.25)',
                    borderRadius: '4px',
                    color: '#A9B7C4',
                    lineHeight: '19px',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center'
                } }, { children: _jsx("div", Object.assign({ style: {
                        width: '90%',
                        height: '30px'
                    } }, { children: _jsx("div", Object.assign({ style: {
                            marginTop: '6px'
                        } }, { children: _jsx(CardElement, { options: { hidePostalCode: true } }) })) })) })), !disableSavedCards && customerToSaveCardOnName && (_jsxs("div", Object.assign({ className: "pt-1 flex items-start", onClick: () => setSaveCreditCard(!saveCreditCard) }, { children: [_jsx("div", { children: _jsx("input", { className: "form-checkbox focus:ring-blue-400 h-4 w-4 text-blue-500 border-gray-300 rounded-md", defaultChecked: saveCreditCard, type: "checkbox" }) }), _jsxs("span", Object.assign({ className: "font-medium text-sm text-gray-600 pl-2 pt-0.5" }, { children: ["Save this card for all payments from ", customerToSaveCardOnName, "."] }))] }))), _jsx(Box, Object.assign({ mt: 1 }, { children: _jsx(PayInvoiceButton, { loading: loading, type: 'submit', disabled: loading, id: "pay-invoice-stripe" }) })), userErr && (_jsx(Box, Object.assign({ mt: 1, className: classes.flex }, { children: _jsx(Typography, Object.assign({ color: "error", variant: "caption" }, { children: userErr })) })))] })));
}
const stripePromise = loadStripe(STRIPE_VARS.key);
function StripeCardCheckoutForm(props) {
    return (_jsx(Elements, Object.assign({ stripe: stripePromise }, { children: _jsx(CheckoutForm, Object.assign({}, props)) })));
}
export default withStyles(styles)(StripeCardCheckoutForm);
