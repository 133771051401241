var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import React, { useEffect } from 'react';
import { push } from 'connected-react-router';
import { PUBLISHER_PAYMENT_SETTINGS } from 'redux/router';
import { selectActiveOrganization } from 'redux/auth';
import { logAndCaptureException } from 'utils';
import { getLocationParams } from 'lib/frontend/utils/browser';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import LoadingState from 'components/LoadingState';
import ToastActions from 'redux/toast';
import { exists } from 'lib/types';
import { apiPost } from 'api/typed';
import { ColumnService } from 'lib/services/directory';
export default function StripeConnect() {
    const activeOrganization = useAppSelector(selectActiveOrganization);
    const oauthCode = getLocationParams().get('code');
    const dispatch = useAppDispatch();
    useEffect(() => {
        const setOAuthCode = () => __awaiter(this, void 0, void 0, function* () {
            if (!exists(activeOrganization) || !oauthCode)
                return;
            try {
                const result = yield apiPost('payments/update-connected-account', {
                    code: oauthCode
                });
                if (result.success) {
                    yield activeOrganization.ref.update({ accountId: result.accountId });
                    dispatch(ToastActions.toastSuccess({
                        headerText: 'Stripe account linked successfully',
                        bodyText: 'All payouts to this publication will be sent to the linked account.'
                    }));
                }
                else {
                    dispatch(ToastActions.toastError({
                        headerText: 'Failed to link Stripe account',
                        bodyText: `${result.error}. Please try again in a few minutes or contact support.`
                    }));
                    throw new Error(result.error);
                }
                dispatch(push(PUBLISHER_PAYMENT_SETTINGS));
            }
            catch (err) {
                logAndCaptureException(ColumnService.PAYMENTS, err, 'Failed to link Stripe account', {
                    activeOrganization: activeOrganization === null || activeOrganization === void 0 ? void 0 : activeOrganization.id
                });
            }
        });
        void setOAuthCode();
    }, [oauthCode, activeOrganization === null || activeOrganization === void 0 ? void 0 : activeOrganization.id]);
    return _jsx(LoadingState, {});
}
