var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { SnapshotModel } from '..';
import { Collections } from '../../constants';
import { NotFoundError, wrapErrorAsColumnError } from '../../errors/ColumnErrors';
import { safeGetOrThrow } from '../../safeWrappers';
import { ObituaryFilingTypeNames } from '../../types/obituary';
import { wrapSuccess } from '../../types/responses';
import { DEATH_NOTICE_LAYOUTS, OBITUARY_LAYOUTS, CLASSIFIED_LAYOUTS } from '../../orders/layouts';
export class FilingTypeModel extends SnapshotModel {
    get type() {
        return Collections.filingTypes;
    }
    getRate() {
        return __awaiter(this, void 0, void 0, function* () {
            const { response: rate, error: rateError } = yield safeGetOrThrow(this.modelData.rate);
            if (rateError) {
                return wrapErrorAsColumnError(rateError, NotFoundError);
            }
            return wrapSuccess(rate);
        });
    }
    get supportedLayouts() {
        if (this.modelData.label === ObituaryFilingTypeNames.DeathNotice) {
            return DEATH_NOTICE_LAYOUTS;
        }
        if (this.modelData.label === ObituaryFilingTypeNames.Obituary) {
            return [...CLASSIFIED_LAYOUTS, ...OBITUARY_LAYOUTS];
        }
        return [...DEATH_NOTICE_LAYOUTS, ...CLASSIFIED_LAYOUTS];
    }
}
