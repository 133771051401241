import { isNoticeRate } from '../../types/rates';
import { getOffset, roundUp } from '../math';
import { floatToP2Float } from '../ui';
export const priceWordCount = options => {
    var _a;
    const { displayParameters, rateRecord, applicableRate } = options;
    const isNoticeRateRecord = isNoticeRate(rateRecord);
    const offset = getOffset(rateRecord);
    const processedWordCount = Math.max(roundUp((_a = displayParameters.words) !== null && _a !== void 0 ? _a : 0, isNoticeRateRecord ? rateRecord.folioSize : undefined) - offset, 0);
    return floatToP2Float(processedWordCount * applicableRate);
};
