var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { isNotNull } from '../helpers';
import { wrapErrorAsColumnError } from '../errors/ColumnErrors';
export const wrapSuccess = (response) => ({
    response,
    error: null
});
export const wrapError = (error) => ({
    response: null,
    error
});
const isResponse = (responseOrError) => {
    return isNotNull(responseOrError.response);
};
const isError = (responseOrError) => {
    return isNotNull(responseOrError.error);
};
export const chainResponseOrError = (prev, fn) => __awaiter(void 0, void 0, void 0, function* () {
    if (prev.error) {
        return wrapError(prev.error);
    }
    if (isResponse(prev)) {
        return yield fn(prev.response);
    }
    return wrapError(new Error('Input was neither a response nor an error, unable to chain responses.'));
});
export const getResponses = (responsesOrErrors) => {
    return responsesOrErrors
        .filter(isResponse)
        .map(response => response.response);
};
export const getErrors = (responsesOrErrors) => {
    return responsesOrErrors.filter(isError).map(error => error.error);
};
export const sendResponseOrError = ({ response, error }, res) => {
    if (!error) {
        return res.status(200).json({ response, error: null });
    }
    const { error: columnError } = wrapErrorAsColumnError(error);
    return res.status(columnError.status).send({
        response: null,
        error: columnError.message
    });
};
