var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import { isDefined, removeUndefinedFields, replaceUndefinedWithDelete } from 'lib/helpers';
import { isAdvertiserOrder, isAnonymousOrder, isPublisherAsAdvertiserOrder } from 'lib/types/order';
import { merge } from 'lodash';
import { useState } from 'react';
import { centsToExtendedCurrency } from 'routes/settings/publisher/rates/ratesTable/ratesTableSettingsUtils';
import { logAndCaptureException } from 'utils';
import { getFirebaseContext } from 'utils/firebase';
import { ColumnService } from 'lib/services/directory';
import { getOrThrow } from 'lib/utils/refs';
import { ENOTICE_SUPPORT_EMAIL } from '../../../constants';
import { CustomerTypeString } from '../filters/helpers';
import FormWrapper from './FormWrapper';
import Placemat from './Placemat';
import { PricePreview } from './components/PricePreview/PricePreview';
import { useAdForm } from './contexts/AdFormStatusProvider';
import { requestNewOrderInvoice } from './helpers/paymentProcessing';
import { usePricing } from './hooks/usePricing';
import { PlacementFlowStep } from './placementFlowStep';
import DraftContent from './steps/DraftContent';
import FlowChoiceStep from './steps/FlowChoice';
import OrderSummary from './steps/OrderSummary';
import PersonalDetail, { MINIMUM_ORDER } from './steps/PersonalDetail';
import ProvideVerification from './steps/ProvideVerification';
import SelectPublication from './steps/SelectPublication';
import SelectSchedules from './steps/SelectSchedules';
import getOrCreateCustomerWithCustomerOrganization from './helpers/getOrCreateCustomerWithCustomerOrganization';
import CategoryChoiceStep from './steps/CategoryChoice';
const getCustomerUserId = (orderFormData) => __awaiter(void 0, void 0, void 0, function* () {
    if (isPublisherAsAdvertiserOrder(orderFormData)) {
        const customerSnapshot = yield getOrThrow(orderFormData.advertiserCustomer);
        return customerSnapshot.data().user.id;
    }
    return orderFormData.user.id;
});
function PlacementFlowStepSelector({ orderModel, adModel, draftNewspaperOrders, steps, product, version, editData }) {
    const isInitialPlacementFlow = orderModel.modelData.activeVersion === adModel.modelData.orderVersion;
    const { currentStep, updateCurrentStep } = useAdForm();
    const [newspaperOrdersFormData, setNewspaperOrdersFormData] = useState(draftNewspaperOrders || []);
    const [orderFormData, setOrderFormData] = useState(orderModel.modelData);
    const [adFormData, setAdFormData] = useState(adModel.modelData);
    const [userError, setUserError] = useState('');
    const [userAlerts, setUserAlerts] = useState([]);
    const [loading, setLoading] = useState(false);
    const { consolidatedOrderPricing, priceLoading, handleRecalculateOrderPrice, priceError, priceIsStale } = usePricing({
        newspaperOrdersFormData,
        orderModel,
        adFormData,
        adModel,
        orderFormData,
        version
    });
    const error = priceError || userError;
    const chosenFlow = isAdvertiserOrder(orderFormData)
        ? CustomerTypeString.FUNERAL_HOME
        : CustomerTypeString.INDIVIDUAL;
    const advertiserCustomer = isPublisherAsAdvertiserOrder(orderFormData)
        ? orderFormData.advertiserCustomer
        : undefined;
    const goToNextStep = () => {
        setUserError('');
        updateCurrentStep(currentStep + 1);
    };
    const updateModels = () => __awaiter(this, void 0, void 0, function* () {
        yield orderModel.ref.update(replaceUndefinedWithDelete(getFirebaseContext(), isAdvertiserOrder(orderFormData)
            ? orderFormData
            : merge(Object.assign({}, MINIMUM_ORDER), orderFormData)));
        // TODO(goodpaul): handle delete sentinel values in the model code
        yield orderModel.refreshData();
        yield adModel.ref.update(removeUndefinedFields(adFormData));
        /**
         * This is a solution for the fact that, when we edit order content in the Draft Content
         * step, we aren't updating `newspaperOrdersFormData` with a new `pdfStoragePath`, because the
         * PDF is generated asynchronously in the back end (see `priceAdWithSettings` in `updateNewspaperOrderPricing`).
         * Because of that, we were adding the new storage path to the newspaper orders in Firestore when
         * handling price recalculation, but then reverting it to the original PDF when the user proceeded
         * to the next step. This was fixed when the user generated an invoice, as the price was recalculated
         * and the correct proof was re-attached to the newspaper orders, but this was still causing a strange
         * bug where if the user clicked "Back" to the Draft Content step, the proof would have reverted
         * to the previous version.
         */
        const sanitizedNewspaperOrdersFormData = newspaperOrdersFormData.map(nofd => {
            const sanitized = Object.assign({}, nofd);
            delete sanitized.pdfStoragePath;
            return sanitized;
        });
        yield orderModel.updateNewspaperOrders(removeUndefinedFields(sanitizedNewspaperOrdersFormData), version);
    });
    const onSubmit = () => __awaiter(this, void 0, void 0, function* () {
        setUserError('');
        setLoading(true);
        try {
            yield updateModels();
            const { onClickNext } = stepMap[steps[currentStep - 1]];
            if (onClickNext) {
                yield onClickNext();
            }
        }
        catch (e) {
            logAndCaptureException(ColumnService.OBITS, e, `Failure during ${product} placement flow ${steps[currentStep - 1]} step`, Object.assign(Object.assign({}, (isAnonymousOrder(orderModel.modelData)
                ? { contactEmail: orderModel.modelData.contactEmail }
                : { userId: orderModel.modelData.user.id })), { adId: adModel.id, product, service: ColumnService.OBITS }));
            setUserError(`Could not load order. Please try again or contact ${ENOTICE_SUPPORT_EMAIL} for assistance.`);
            return;
        }
        finally {
            setLoading(false);
        }
        if (currentStep < steps.length) {
            goToNextStep();
        }
    });
    const orderPricingComponent = (_jsx(PricePreview, { loading: priceLoading, consolidatedOrderPricing: consolidatedOrderPricing, priceIsStale: priceIsStale }));
    const stepMap = {
        [PlacementFlowStep.CustomerType]: {
            component: (_jsx(FlowChoiceStep, { chosenCustomer: advertiserCustomer, onChosenCustomerChange: (advertiser) => {
                    setOrderFormData(Object.assign(Object.assign({}, orderFormData), { firstName: undefined, lastName: undefined, contactEmail: undefined, advertiserCustomer: advertiser === null || advertiser === void 0 ? void 0 : advertiser.ref, advertiserOrganization: advertiser === null || advertiser === void 0 ? void 0 : advertiser.organization.ref }));
                } }))
        },
        [PlacementFlowStep.Category]: {
            component: (_jsx(CategoryChoiceStep, { product: product, inputData: orderFormData, onInputDataChange: setOrderFormData, newspaperOrdersFormData: newspaperOrdersFormData, onSetNewspaperOrdersFormData: setNewspaperOrdersFormData, orderModel: orderModel, onUserAlertChange: setUserAlerts }))
        },
        [PlacementFlowStep.Details]: {
            component: (_jsx(Placemat, { children: _jsx(PersonalDetail, { inputData: orderFormData, setInputData: setOrderFormData }) }))
        },
        [PlacementFlowStep.Publication]: {
            component: (_jsx(Placemat, { children: _jsx(SelectPublication, { newspaperOrdersFormData: newspaperOrdersFormData, onNewspaperOrdersFormDataChange: setNewspaperOrdersFormData, product: product, orderModel: orderModel, inputData: orderFormData, userAlerts: userAlerts, setUserAlerts: setUserAlerts }) })),
            onClickNext: () => __awaiter(this, void 0, void 0, function* () {
                if (isAdvertiserOrder(orderFormData)) {
                    const promises = newspaperOrdersFormData
                        .map(newspaperOrder => { var _a; return (_a = newspaperOrder.newspaper) === null || _a === void 0 ? void 0 : _a.id; })
                        .filter(isDefined)
                        .map((newspaperId) => __awaiter(this, void 0, void 0, function* () {
                        const userId = yield getCustomerUserId(orderFormData);
                        yield getOrCreateCustomerWithCustomerOrganization({
                            userId,
                            organizationId: orderFormData.advertiserOrganization.id,
                            publisherId: newspaperId
                        });
                    }));
                    yield Promise.all(promises);
                }
            })
        },
        [PlacementFlowStep.Schedule]: {
            component: (_jsx(SelectSchedules, { onFilingTypeChange: (label) => {
                    setOrderFormData(Object.assign(Object.assign({}, orderFormData), { filingTypeLabel: label }));
                }, product: product, newspaperOrdersFormData: newspaperOrdersFormData, onNewspaperOrdersFormDataChange: setNewspaperOrdersFormData, isInitialPlacementFlow: isInitialPlacementFlow, editData: editData }))
        },
        [PlacementFlowStep.Verification]: {
            component: (_jsx(Placemat, { children: _jsx(ProvideVerification, { chosenFlow: chosenFlow, inputData: adFormData, setInputData: value => {
                        // TODO: remove as
                        setAdFormData(value);
                    } }) }))
        },
        [PlacementFlowStep.Content]: {
            component: (_jsx(DraftContent, { adData: adFormData, onAdChange: setAdFormData, newspaperOrdersFormData: newspaperOrdersFormData, onUpdateNewspaperOrdersFormData: setNewspaperOrdersFormData, orderPricingComponent: orderPricingComponent, reloadingPreview: priceLoading, order: orderModel, version: version, canEditContent: isInitialPlacementFlow ||
                    !!(editData && Object.values(editData).every(ed => ed.canEdit)) })),
            onClickNext: () => __awaiter(this, void 0, void 0, function* () {
                // Calculate final price before loading the order summary page
                if (priceIsStale) {
                    yield handleRecalculateOrderPrice();
                }
            })
        },
        [PlacementFlowStep.Summary]: {
            component: (_jsx(Placemat, { children: _jsx(OrderSummary, { newspaperOrdersFormData: newspaperOrdersFormData, orderModel: orderModel, filingTypeLabel: orderFormData.filingTypeLabel || '', adData: adFormData, product: product, version: version, consolidatedOrderPricing: consolidatedOrderPricing }) })),
            onClickNext: () => __awaiter(this, void 0, void 0, function* () {
                const { response: invoiceId, error: invoiceError } = yield requestNewOrderInvoice(orderModel.id, version);
                if (invoiceError) {
                    setUserError(invoiceError.message);
                    return;
                }
                // Open the pay invoice page in the current tab, not a new window
                window.location.href = `${window.location.origin}/invoices/${invoiceId}/pay`;
            })
        }
    };
    return (_jsx(FormWrapper, Object.assign({ onSubmit: onSubmit, submitText: currentStep < steps.length || !consolidatedOrderPricing
            ? 'Next'
            : `Pay $${centsToExtendedCurrency(consolidatedOrderPricing.totalInCents)}`, steps: steps, loading: loading, newspaperOrdersFormData: newspaperOrdersFormData, userError: error, product: product }, { children: stepMap[steps[currentStep - 1]].component })));
}
export default PlacementFlowStepSelector;
