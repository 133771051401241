var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { OrganizationType, Product } from '../enums';
import { getModelFromRef } from '../model';
import { safeGetModelArrayFromQuery } from '../model/getModel';
import { ObituaryModel } from '../model/objects/obituaryModel';
import { wrapError, wrapSuccess } from '../types/responses';
import { getErrorReporter } from '../utils/errors';
import { getOrThrow } from '../utils/refs';
import { ColumnService } from './directory';
import { OrderService } from './orderService';
const isAnonymousOrderInfo = (info) => !Object.keys(info).includes('user');
const isAdvertiserOrder = (info) => Object.keys(info).includes('advertiserOrganization');
export class ObituaryService {
    constructor(context) {
        this.context = context;
        this.orderService = new OrderService(this.context);
    }
    create(orderInfo) {
        return __awaiter(this, void 0, void 0, function* () {
            const initialVersion = this.context.timestamp().toMillis();
            const orderRef = yield this.orderService.create(orderInfo, Product.Obituary, initialVersion);
            let deathVerification = {};
            if (!isAnonymousOrderInfo(orderInfo)) {
                const authorizedOrganization = yield getOrThrow(orderInfo.authorizedOrganization);
                // If the obit was placed by a funeral home, then verification is automatic
                if (authorizedOrganization.data().organizationType ===
                    OrganizationType.funeral_home.value) {
                    deathVerification = {
                        verifiedAt: this.context.timestamp()
                    };
                }
            }
            if (isAdvertiserOrder(orderInfo)) {
                const advertiserOrganization = yield getOrThrow(orderInfo.advertiserOrganization);
                // If the obit was placed by a funeral home, then we get the funeral home name from the organization
                if (advertiserOrganization.data().organizationType ===
                    OrganizationType.funeral_home.value) {
                    deathVerification = Object.assign(Object.assign({}, deathVerification), { funeralHomeName: advertiserOrganization.data().name, funeralHomePhone: advertiserOrganization.data().phone });
                }
            }
            const obituaryRef = yield this.context.obituariesRef().add({
                order: orderRef,
                orderVersion: initialVersion,
                deathVerification,
                createdAt: this.context.timestamp(),
                modifiedAt: this.context.timestamp()
            });
            return getModelFromRef(ObituaryModel, this.context, obituaryRef);
        });
    }
    getByOrderAndVersion(orderRef, version) {
        return __awaiter(this, void 0, void 0, function* () {
            const query = this.context
                .obituariesRef()
                .where('order', '==', orderRef)
                .where('orderVersion', '==', version)
                .limit(2);
            const queryResult = yield safeGetModelArrayFromQuery(ObituaryModel, this.context, query);
            if (queryResult.error) {
                return queryResult;
            }
            const adsForOrder = queryResult.response;
            if (adsForOrder.length !== 1) {
                const errorMessage = `Expected 1 obituary for order and version; instead got ${adsForOrder.length > 1 ? 'multiple' : '0'}`;
                const error = Error(errorMessage);
                getErrorReporter().logAndCaptureError(ColumnService.OBITS, error, errorMessage, {
                    orderId: orderRef.id,
                    version: `${version}`
                });
                return wrapError(error);
            }
            return wrapSuccess(adsForOrder[0]);
        });
    }
    cloneForEditFlow(antecedentObituary, newVersion) {
        return __awaiter(this, void 0, void 0, function* () {
            const newObitRef = yield this.context.obituariesRef().add(Object.assign(Object.assign({}, antecedentObituary.modelData), { orderVersion: newVersion, createdAt: this.context.timestamp(), modifiedAt: this.context.timestamp() }));
            getErrorReporter().logInfo('Created new obituary for edit flow', {
                antecedentObitId: antecedentObituary.id,
                newObitId: newObitRef.id,
                newVersion: `${newVersion}`
            });
        });
    }
}
