var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import { ExclamationCircleIcon } from '@heroicons/react/24/outline';
import LoadingState from 'components/LoadingState';
import { Alert } from 'lib/components/Alert';
import { useFirestoreDocumentListener } from 'lib/frontend/hooks/useFirestoreDocumentListener';
import { useFirestoreQueryListener } from 'lib/frontend/hooks/useFirestoreQueryListener';
import { getModelFromSnapshot } from 'lib/model';
import { OrderModel } from 'lib/model/objects/orderModel';
import { ColumnService } from 'lib/services/directory';
import { NewspaperOrderModel } from 'lib/model/objects/newspaperOrderModel';
import { exists } from 'lib/types';
import { matchPath } from 'react-router-dom';
import { getFirebaseContext } from 'utils/firebase';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import AuthActions, { selectAuthLoading } from 'redux/auth';
import { apiPost } from 'api/typed';
import useAsyncEffect from 'lib/frontend/hooks/useAsyncEffect';
import AdDetailContents from './AdDetailContents';
export default function AdDetailWrapper() {
    var _a, _b;
    const context = getFirebaseContext();
    const dispatch = useAppDispatch();
    const queryParams = new URLSearchParams(location.search);
    const accessCode = (_a = queryParams.get('accessCode')) !== null && _a !== void 0 ? _a : undefined;
    const authEmail = (_b = queryParams.get('authEmail')) !== null && _b !== void 0 ? _b : undefined;
    const { isLoading: authenticatingIndividual } = useAsyncEffect({
        fetchData: () => __awaiter(this, void 0, void 0, function* () {
            if (!accessCode || !authEmail) {
                return;
            }
            const accessResult = yield apiPost('orders/grant-access', {
                accessCode,
                authEmail,
                forFuneralHomeVerification: false
            });
            if (!accessResult.success) {
                throw Error(accessResult.error);
            }
            const { token } = accessResult;
            dispatch(AuthActions.loginToken(token));
        }),
        dependencies: [accessCode, authEmail]
    });
    const isAuthLoading = useAppSelector(selectAuthLoading) || authenticatingIndividual;
    const { params: { orderId } } = matchPath(window.location.pathname, {
        path: '/:path(classified|obituary)/:orderId/',
        exact: true,
        strict: false
    }) || { params: {} };
    const orderSnap = useFirestoreDocumentListener(orderId && !isAuthLoading ? context.ordersRef().doc(orderId) : undefined);
    const orderModel = orderSnap
        ? getModelFromSnapshot(OrderModel, context, orderSnap)
        : null;
    const newspaperOrders = useFirestoreQueryListener(orderModel && !isAuthLoading
        ? orderModel.getNewspaperOrdersQuery()
        : undefined, [isAuthLoading, orderModel === null || orderModel === void 0 ? void 0 : orderModel.modelData.activeVersion]);
    const newspaperOrderModels = newspaperOrders === null || newspaperOrders === void 0 ? void 0 : newspaperOrders.docs.map(doc => getModelFromSnapshot(NewspaperOrderModel, context, doc));
    if (orderSnap === null || isAuthLoading) {
        return _jsx(LoadingState, { context: { service: ColumnService.OBITS } });
    }
    if (!exists(orderModel)) {
        return (_jsx(Alert, { id: "order-detail-alert", title: "Order not found", icon: _jsx(ExclamationCircleIcon, { className: "h-5" }) }));
    }
    return (_jsx(AdDetailContents, { order: orderModel, anonymousUserAuthInfo: authEmail && accessCode ? { authEmail, accessCode } : undefined, newspaperOrders: newspaperOrderModels !== null && newspaperOrderModels !== void 0 ? newspaperOrderModels : [] }));
}
