var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
import { logAndCaptureException } from 'utils';
import { apiPost } from 'api/typed';
import { Product } from 'lib/enums';
import { ColumnService } from 'lib/services/directory';
import { ObituariesOverviewTable } from './ObituariesOverviewTable';
import { ClassifiedsOverviewTable } from './ClassifiedsOverviewTable';
export function OrdersTab(props) {
    const { publicationIssue, product } = props;
    const [orders, setOrders] = useState([]);
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        function getOrdersForPublicationIssue() {
            return __awaiter(this, void 0, void 0, function* () {
                setLoading(true);
                const res = yield apiPost(`publication-issue/orders`, {
                    publicationIssueId: publicationIssue.id,
                    product
                });
                if (!res.success) {
                    logAndCaptureException(ColumnService.PAGINATION, res.error, `Error getting ${product} for publication issue`);
                }
                else {
                    setOrders(res.orders);
                }
                setLoading(false);
            });
        }
        void getOrdersForPublicationIssue();
    }, [publicationIssue.id]);
    return (_jsxs(_Fragment, { children: [product === Product.Obituary && (_jsx(ObituariesOverviewTable, { obituaries: orders, loading: loading })), product === Product.Classified && (_jsx(ClassifiedsOverviewTable, { classifieds: orders, loading: loading }))] }));
}
