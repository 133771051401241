var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import moment from 'moment';
import LoadingState from 'components/LoadingState';
import { cdnIfy, dateToDateString } from 'lib/helpers';
import Drawer from 'lib/components/Drawer';
import useAsyncEffect from 'lib/frontend/hooks/useAsyncEffect';
import { EHandlebars } from 'lib/headers_footers/shared';
import { State } from 'lib/enums';
import { AffidavitTemplateDrawerHeader } from './AffidavitTemplateDrawerHeader';
import { AffidavitTemplateDrawerFooter } from './AffidavitTemplateDrawerFooter';
/**
 * Pulls out the default set of handlebars used in affidavits for rendering the
 * preview in the layout settings page
 * @param activeOrganization Organization we are pulling settings from
 * @returns
 */
export const getAffidavitHandlebarsForActiveOrganization = (activeOrganization) => {
    var _a, _b;
    return {
        PUBLICATION_ADDRESS: activeOrganization.data().address,
        PUBLICATION_NUMBER: activeOrganization.data().phone,
        PUBLICATION_DATES: [
            `<div class="margin-bottom-small">
        ${dateToDateString(moment().toDate())}
      </div>`
        ],
        PUBLICATION_NAME: activeOrganization.data().name,
        AGENT_NAME: activeOrganization.data().signatoryName || '',
        AGENT_ROLE: activeOrganization.data().signatoryRole || '',
        NOTICE_ID: 'NOTICE_ID',
        CITY: activeOrganization.data().city,
        STATE: (_a = State.by_value(activeOrganization.data().state)) === null || _a === void 0 ? void 0 : _a.label,
        PUBLICATION_FEE: 'X.XX',
        STATE_UPPER: (_b = State.by_value(activeOrganization.data().state)) === null || _b === void 0 ? void 0 : _b.label.toUpperCase(),
        COUNTY: activeOrganization.data().county,
        COUNTY_UPPER: activeOrganization.data().county.toUpperCase(),
        COMMISSION_EXPIRATION_DATE: activeOrganization.data().commissionExpirationDate || ''
    };
};
/**
 * Attempts to compile the affidavit HTML against a standard set of handlebars.
 * If it fails, it returns the empty string. This empty string is then caught in the
 * frontend and an alert is shown to the user designating the failure.
 * @param affidavitHTML html to compile
 * @param activeOrganization current organization
 * @returns {string} compiled html
 */
export const compileAffidavitHTML = (affidavitHTML, activeOrganization) => {
    try {
        return EHandlebars.compile(affidavitHTML)(getAffidavitHandlebarsForActiveOrganization(activeOrganization));
    }
    catch (err) {
        return '';
    }
};
/**
 * This horrendous function scales the internals of the iframe to fit within our drawer view.
 * TODO: figure out how to replace this with a CSS prop on the iFrame itself!
 */
const scaleTemplatePreview = (templatePreviewHTML) => {
    return templatePreviewHTML.replace('<html>', '<html style="zoom: 0.75;">');
};
const loadAffidavitContentFromStoragePath = (drawerTemplate, activeOrganization) => __awaiter(void 0, void 0, void 0, function* () {
    const affidavitTemplate = yield fetch(cdnIfy(drawerTemplate.data().storagePath));
    const templateBlob = yield affidavitTemplate.blob();
    const templateHTML = yield templateBlob.text();
    const templatePreview = compileAffidavitHTML(templateHTML, activeOrganization);
    const scaledTemplatePreview = scaleTemplatePreview(templatePreview);
    return scaledTemplatePreview;
});
// Preview height for our affidavit pdf in the drawer
const TEMPLATE_HEIGHT = 800;
export function LegacyAffidavitTemplateDrawer({ activeOrganization, drawerTemplate, onCloseDrawer, onEdit }) {
    const { value: affidavitTemplatePreview, isLoading } = useAsyncEffect({
        fetchData: () => loadAffidavitContentFromStoragePath(drawerTemplate, activeOrganization),
        dependencies: [drawerTemplate.data().storagePath]
    });
    return (_jsx(Drawer, Object.assign({ onClose: () => onCloseDrawer(), header: _jsx(AffidavitTemplateDrawerHeader, { activeOrganization: activeOrganization, drawerTemplate: drawerTemplate }), open: true, className: "h-screen flex flex-col" }, { children: !isLoading && affidavitTemplatePreview ? (_jsxs(_Fragment, { children: [_jsx("div", Object.assign({ className: "w-full p-2 flex items-center" }, { children: _jsx("div", Object.assign({ className: "w-full p-1 column-shadow-3 rounded-sm bg-column-gray-100" }, { children: _jsx("iframe", { height: TEMPLATE_HEIGHT, title: "in-app-affidavit-html-editor", className: "bg-white w-full", srcDoc: affidavitTemplatePreview }) })) })), _jsx(AffidavitTemplateDrawerFooter, { onEdit: onEdit })] })) : (_jsx(LoadingState, {})) })));
}
