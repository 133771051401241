var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import { ArrowDownTrayIcon } from '@heroicons/react/24/outline';
import { ColumnButton } from 'lib/components/ColumnButton';
import React from 'react';
import { makeCsvContent, noticeID, noticeName, preview } from 'lib/utils/csv';
import moment from 'moment';
import { getFirebaseContext } from 'utils/firebase';
import { getQueryResultsWhereKeyInArray } from 'lib/utils/firebase';
import { cdnIfy } from 'lib/helpers';
import { downloadFileContentsInBrowser } from 'lib/frontend/utils/browser';
import { useLoading } from 'lib/components/hooks/useLoading';
import { logAndCaptureException } from 'utils';
import { ColumnService } from 'lib/services/directory';
import { sortRunDataByNotice } from './hooks/useSortRunDataByNotice';
const SPREADSHEET_DATE_FORMAT = 'MM/DD/YY';
const DATABASE_DATE_FORMAT = 'YYYY-MM-DD';
export default function DownloadReportButton({ activeOrganization, publicationIssuesAndRuns, setSuccessMessage }) {
    const getCsvRowForRun = ({ notice, run, availableEEditions }) => [
        noticeID(notice),
        noticeName(notice),
        moment(run.modelData.publicationDate, DATABASE_DATE_FORMAT).format(SPREADSHEET_DATE_FORMAT),
        run.isVerified() ? 'True' : 'False',
        `"${availableEEditions
            .filter(eedition => eedition.data().publicationIssue.id ===
            run.modelData.publicationIssue.id)
            .map(eedition => cdnIfy(eedition.data().storagePath))
            .join(', ')}"`,
        `https://www.column.us/notice/${notice.id}`,
        preview(notice)
    ];
    const handleSubmit = () => __awaiter(this, void 0, void 0, function* () {
        if (!publicationIssuesAndRuns) {
            return;
        }
        try {
            const noticeAndRunData = yield sortRunDataByNotice(publicationIssuesAndRuns, {
                limitToNoticesWithUnverifiedRuns: false
            });
            const eeditions = yield getQueryResultsWhereKeyInArray(getFirebaseContext().eeditionsRef(), 'publicationIssue', (publicationIssuesAndRuns || []).map(pubIssueAndRun => pubIssueAndRun.publicationIssue.ref));
            const headers = [
                'Notice ID',
                'Notice Name',
                'Run Date',
                'Verified',
                'E-Edition',
                'Column URL',
                'Notice Preview'
            ];
            const body = noticeAndRunData
                .flatMap(({ noticeModel, noticeRuns }) => noticeRuns.map(run => ({
                notice: noticeModel,
                run,
                availableEEditions: eeditions
            })))
                .sort((a, b) => b.run.modelData.publicationDate.localeCompare(a.run.modelData.publicationDate))
                .map(getCsvRowForRun);
            const csvData = makeCsvContent(headers, body);
            const csvName = `${activeOrganization.data().name}_upload_validation_${new Date().toDateString()}.csv`;
            downloadFileContentsInBrowser(csvName, csvData, 'text/csv');
            setSuccessMessage({
                header: 'Report downloaded',
                body: 'Your affidavit report was generated successfully and downloaded to your computer.'
            });
        }
        catch (err) {
            logAndCaptureException(ColumnService.AFFIDAVITS, err, 'Error downloading run verification report', {
                newspaperId: activeOrganization.id
            });
        }
    });
    const [submitLoading, handleSubmitWithLoading] = useLoading();
    return (_jsx(ColumnButton, { tertiary: true, id: "download-run-verification-report", onClick: () => handleSubmitWithLoading(handleSubmit), type: "button", buttonText: "Download report", startIcon: _jsx(ArrowDownTrayIcon, { className: "w-5 h-5" }), loading: submitLoading }));
}
