var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { safeGetModelArrayFromQuery } from '../model/getModel';
import { InvoiceModel } from '../model/objects/invoiceModel';
import { wrapError, wrapSuccess } from '../types/responses';
import { getErrorReporter } from '../utils/errors';
import { ColumnService } from './directory';
export class InvoiceService {
    constructor(context) {
        this.context = context;
    }
    getByOrderAndVersion(orderRef, version) {
        var _a;
        return __awaiter(this, void 0, void 0, function* () {
            const invoiceQuery = this.context
                .invoicesRef()
                .where('order', '==', orderRef)
                .where('orderVersion', '==', version)
                .limit(2);
            const { response: invoicesForOrder, error: invoiceError } = yield safeGetModelArrayFromQuery(InvoiceModel, this.context, invoiceQuery);
            if (invoiceError) {
                getErrorReporter().logAndCaptureError(ColumnService.OBITS, invoiceError, 'Failed to get invoice from order', {
                    orderId: orderRef.id,
                    version: `${version}`
                });
                return wrapError(invoiceError);
            }
            if (invoicesForOrder.length > 1) {
                const errorMessage = 'Expected no more than 1 invoice for order and version';
                const error = Error(errorMessage);
                getErrorReporter().logAndCaptureError(ColumnService.OBITS, error, errorMessage, {
                    orderId: orderRef.id,
                    version: `${version}`
                });
                return wrapError(error);
            }
            /**
             * Our linter does not like passing in a narrowed type (InvoiceModel<OrderInvoice>)
             * into `safeGetModelArrayFromQuery` above, so this cast enables us to type the response properly
             */
            return wrapSuccess((_a = invoicesForOrder[0]) !== null && _a !== void 0 ? _a : null);
        });
    }
}
