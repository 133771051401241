export var ClassifiedFilingTypeNames;
(function (ClassifiedFilingTypeNames) {
    ClassifiedFilingTypeNames["AnnouncementsEvents"] = "Announcements & Events";
    ClassifiedFilingTypeNames["CommunityEngagementLetters"] = "Community Engagement Letters";
    ClassifiedFilingTypeNames["ForSale"] = "For Sale";
    ClassifiedFilingTypeNames["HousingProperty"] = "Housing & Property";
    ClassifiedFilingTypeNames["Milestones"] = "Milestones";
    ClassifiedFilingTypeNames["Pets"] = "Pets";
    ClassifiedFilingTypeNames["Services"] = "Services";
})(ClassifiedFilingTypeNames || (ClassifiedFilingTypeNames = {}));
