import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { PlusCircleIcon, MagnifyingGlassIcon, Cog8ToothIcon, ArrowDownTrayIcon } from '@heroicons/react/24/outline';
import { ColumnButton } from '../ColumnButton';
import { TextField } from '../TextField';
import TableFilterDialog from './TableFilterDialog';
/**
 * Header for table layout component
 */
export default function TableLayoutHeader({ configurable, filterable, actionable, searchable, creatable, downloadable, header, id }) {
    return (_jsxs("div", Object.assign({ className: "rounded-t-lg bg-white flex items-center pl-8 pr-8 py-5", id: id }, { children: [_jsxs("div", Object.assign({ className: "font-medium" }, { children: [_jsx("p", Object.assign({ className: "text-xl text-column-gray-500 mb-1" }, { children: header.title })), _jsx("p", Object.assign({ className: "text-sm leading-6 text-column-gray-400" }, { children: header.subtitle }))] })), _jsx("div", { className: "flex-1" }), _jsxs("div", Object.assign({ className: "flex" }, { children: [searchable.enabled && (_jsx("div", Object.assign({ className: "mx-3 w-64" }, { children: _jsx(TextField, { id: 'templates-search', labelText: '', placeholder: "Search", size: "small", value: searchable.search, onChange: searchable.setSearch, prefix: _jsx("div", Object.assign({ className: "pb-1" }, { children: _jsx(MagnifyingGlassIcon, { className: "w-5 text-column-gray-300", "aria-hidden": "true" }) })) }) }))), filterable.additionalFilters && (_jsx(TableFilterDialog, Object.assign({}, filterable.additionalFilters, { id: `${id}-filter-dialog` }))), downloadable && (_jsx("div", Object.assign({ className: "mr-3" }, { children: _jsx(ColumnButton, { tertiary: true, id: "download-unverifiable-runs", onClick: () => downloadable.onClickDownload(searchable.search), type: "button", buttonText: 'Download', startIcon: _jsx(ArrowDownTrayIcon, { className: "w-5 h-5 -ml-0.5 mr-1.5" }) }) }))), actionable, header.additionalContent && header.additionalContent, configurable && (_jsx("div", Object.assign({ className: "mr-4" }, { children: _jsx(ColumnButton, { buttonText: configurable.buttonText, onClick: configurable.onClick, startIcon: _jsx(Cog8ToothIcon, { className: "w-5 h-5" }), type: "button" }) }))), creatable && (_jsx(ColumnButton, { buttonText: creatable.createButtonText, startIcon: _jsx(PlusCircleIcon, { className: "w-5 h-5" }), onClick: creatable.onCreate, primary: true, size: "sm", id: "add-table-item", type: "button" }))] }))] })));
}
