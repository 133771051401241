var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import api from 'api';
import { Form } from 'lib/components/Form';
import { Modal } from 'lib/components/Modal';
import { TextField } from 'lib/components/TextField';
import { OccupationType, OrganizationType, RoleType, State, enumToSelectInput } from 'lib/enums';
import { ColumnService } from 'lib/services/directory';
import { UserService } from 'lib/services/userService';
import createAnonymousUser from 'lib/users/createAnonymousUser';
import { getOrThrow } from 'lib/utils/refs';
import { useState } from 'react';
import { selectUserAuth } from 'redux/auth';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { createOrganization } from 'routes/register/organization/createOrganization';
import { logAndCaptureException } from 'utils';
import { getFirebaseContext } from 'utils/firebase';
import ToastActions from 'redux/toast';
import { getModelFromSnapshot } from 'lib/model';
import { UserModel } from 'lib/model/objects/userModel';
import getOrCreateCustomerWithCustomerOrganization from 'routes/ads/place/helpers/getOrCreateCustomerWithCustomerOrganization';
import NativeSelect from './NativeSelect';
const getUser = (customerData) => __awaiter(void 0, void 0, void 0, function* () {
    const context = getFirebaseContext();
    const response = yield api.post('users/get-user-by-email', {
        email: customerData.email
    });
    if (response.success) {
        const userRef = context.usersRef().doc(response.userId);
        return getOrThrow(userRef);
    }
    /*
    At this point we can safely create a new user because if there was a failure when calling the API
    it would return a 500 and exit early, and a user being found would result in response.success
    being true.
    */
    return createAnonymousUser(context, customerData);
});
function CustomerCreationModal({ onClose, onSubmit, activeOrganization }) {
    var _a, _b, _c, _d, _e, _f;
    const userAuth = useAppSelector(selectUserAuth);
    const dispatch = useAppDispatch();
    const [customerData, setCustomerData] = useState({
        addressLine1: '',
        addressLine2: '',
        city: '',
        state: null,
        zip: '',
        orgName: '',
        email: '',
        phone: '',
        firstName: '',
        lastName: '',
        internalID: null,
        billingStatus: null
    });
    const [loading, setLoading] = useState(false);
    const handleSubmit = () => __awaiter(this, void 0, void 0, function* () {
        var _g;
        try {
            setLoading(true);
            const context = getFirebaseContext();
            const newOrganizationRef = yield createOrganization({
                address: customerData.addressLine1 || '',
                addressLine2: customerData.addressLine2 || '',
                city: customerData.city || '',
                state: customerData.state || 0,
                zipCode: customerData.zip || '',
                phone: customerData.phone || '',
                name: customerData.orgName || '',
                userName: customerData.orgName || '',
                email: customerData.email,
                organizationType: OrganizationType.funeral_home.value,
                createdBy: userAuth === null || userAuth === void 0 ? void 0 : userAuth.uid
            });
            const newOrganizationSnap = yield getOrThrow(newOrganizationRef);
            const user = yield getUser(Object.assign(Object.assign({}, customerData), { firstName: customerData.orgName }));
            const customer = yield getOrCreateCustomerWithCustomerOrganization({
                userId: user.id,
                organizationId: newOrganizationRef.id,
                publisherId: activeOrganization.id
            });
            const userModel = getModelFromSnapshot(UserModel, context, user);
            yield userModel.getOrCreateInvite(customerData, newOrganizationSnap, activeOrganization);
            const userService = new UserService(context);
            const { ref, updateData } = yield userService.getUserAdditionData(user, newOrganizationSnap, RoleType.admin.value, OccupationType.funeral_director.value);
            yield ref.update(updateData);
            onSubmit({
                name: (_g = customerData.orgName) !== null && _g !== void 0 ? _g : '',
                email: customerData.email,
                ref: customer,
                organization: newOrganizationSnap
            });
            onClose();
        }
        catch (e) {
            dispatch(ToastActions.toastError({
                headerText: 'Error',
                bodyText: 'Failed to create customer. If this persists, please contact support.'
            }));
            logAndCaptureException(ColumnService.OBITS, e, 'Failure when creating customer from obituary flow', { userId: userAuth === null || userAuth === void 0 ? void 0 : userAuth.uid, newspaperId: activeOrganization.id });
        }
        finally {
            setLoading(false);
        }
    });
    return (_jsx(Modal, Object.assign({ id: "create-customer-modal", title: "Add funeral home", onClose: () => {
            onClose();
        }, primaryAction: {
            type: 'submit',
            formId: 'create-customer-form',
            buttonText: 'Add',
            loading
        }, secondaryActions: [{ type: 'button', buttonText: 'Cancel' }] }, { children: _jsx(Form, Object.assign({ id: "create-customer-form", onSubmit: event => {
                event.stopPropagation();
                void handleSubmit();
            } }, { children: _jsxs("div", Object.assign({ className: "grid gap-2 " }, { children: [_jsx(TextField, { id: "name-field", labelText: "Business name", required: true, placeholder: "Business name", value: customerData.orgName, onChange: value => {
                            setCustomerData(Object.assign(Object.assign({}, customerData), { orgName: value }));
                        } }), _jsx(TextField, { id: "email-field", type: "email", labelText: "Business email", required: true, placeholder: "Business email", value: customerData.email, onChange: value => {
                            setCustomerData(Object.assign(Object.assign({}, customerData), { email: value }));
                        } }), _jsx(TextField, { id: "phone-field", type: "tel", labelText: "Phone", placeholder: "(000)000-0000", value: (_a = customerData.phone) !== null && _a !== void 0 ? _a : '', onChange: value => {
                            setCustomerData(Object.assign(Object.assign({}, customerData), { phone: value }));
                        } }), _jsxs("div", Object.assign({ className: "grid gap-2 grid-cols-3" }, { children: [_jsx("div", Object.assign({ className: "col-span-2" }, { children: _jsx(TextField, { id: "address-field", labelText: "Street address", placeholder: "Street address", value: (_b = customerData.addressLine1) !== null && _b !== void 0 ? _b : '', onChange: value => {
                                        setCustomerData(Object.assign(Object.assign({}, customerData), { addressLine1: value }));
                                    } }) })), _jsx(TextField, { id: "secondary-address-field", labelText: "Apt/Suite", placeholder: "Apt/Suite", value: (_c = customerData.addressLine2) !== null && _c !== void 0 ? _c : '', onChange: value => {
                                    setCustomerData(Object.assign(Object.assign({}, customerData), { addressLine2: value }));
                                } })] })), _jsxs("div", Object.assign({ className: "grid gap-2 grid-cols-4" }, { children: [_jsx("div", Object.assign({ className: "col-span-2" }, { children: _jsx(TextField, { id: "city-field", labelText: "City", placeholder: "City", value: (_d = customerData.city) !== null && _d !== void 0 ? _d : '', onChange: value => {
                                        setCustomerData(Object.assign(Object.assign({}, customerData), { city: value }));
                                    } }) })), _jsx(NativeSelect, { id: "state", labelText: "State", allowUndefined: true, placeholder: "State", options: enumToSelectInput(State), value: `${(_e = customerData.state) !== null && _e !== void 0 ? _e : ''}`, onChange: value => {
                                    setCustomerData(Object.assign(Object.assign({}, customerData), { state: value ? Number(value) : null }));
                                } }), _jsx(TextField, { id: "zip-field", labelText: "Zip code", placeholder: "00000", value: (_f = customerData.zip) !== null && _f !== void 0 ? _f : '', onChange: value => {
                                    setCustomerData(Object.assign(Object.assign({}, customerData), { zip: value }));
                                } })] }))] })) })) })));
}
export default CustomerCreationModal;
