import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import SettingsHeader from '../SettingsHeader';
import { FileDownload } from '../icons';
function UserSettingsDocuments() {
    const documents = [
        {
            display_title: 'W-9 Form',
            url: 'https://enotice-production.imgix.net/custom-documents%2Fpermalink%2F73d7.2ec36-_ColumnSoftwareW9-Payment%20(2024).pdf'
        }
    ];
    return (_jsxs("div", Object.assign({ className: "bg-white sm:rounded-lg border border-gray-300 shadow" }, { children: [_jsx(SettingsHeader, { header: "Documents", description: "Download legal and financial documents from Column." }), _jsx(_Fragment, { children: _jsxs("table", Object.assign({ className: "min-w-full divide-y divide-gray-200" }, { children: [_jsx("thead", { children: _jsxs("tr", { children: [_jsx("th", Object.assign({ className: "px-5 pr-6 py-3 bg-gray-50 uppercase text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider" }, { children: "document" })), _jsx("th", Object.assign({ className: "px-5 py-3 bg-gray-50 uppercase text-right text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider" }, { children: "actions" }))] }) }), documents && (_jsx("tbody", Object.assign({ className: "divide-y divide-gray-200 rounded-b-lg" }, { children: documents &&
                                documents.map((document, index) => {
                                    return (_jsxs("tr", { children: [_jsx("td", Object.assign({ className: "pl-5 pr-2 py-4 whitespace-no-wrap text-sm leading-5 text-gray-900" }, { children: document.display_title })), _jsx("td", Object.assign({ className: "flex items-end px-6 py-6 float-right whitespace-no-wrap text-right text-sm leading-5 font-medium text-gray-900" }, { children: _jsx("a", Object.assign({ href: document.url, rel: "noopener noreferrer", target: "_blank", className: "cursor-pointer ml-4" }, { children: _jsx("div", Object.assign({ className: "w-9/12" }, { children: FileDownload })) })) }))] }, index));
                                }) })))] })) })] })));
}
export default UserSettingsDocuments;
