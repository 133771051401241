import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import htmlToText from 'html-to-text';
import classNames from 'classnames';
import { Tooltip } from 'lib/components/Tooltip';
import { NewspaperOrderStatus } from 'lib/types/newspaperOrder';
export function ObituariesOverviewRow(props) {
    var _a, _b, _c, _d;
    const { obituary } = props;
    const title = htmlToText.fromString((_b = (_a = obituary.title) === null || _a === void 0 ? void 0 : _a.replace(/,/g, '')) !== null && _b !== void 0 ? _b : '');
    const customerName = `${obituary.filerfirstname} ${obituary.filerlastname}`;
    const deceasedName = (_c = obituary.deceasedname) !== null && _c !== void 0 ? _c : '';
    const funeralHomeName = (_d = obituary.funeralhomename) !== null && _d !== void 0 ? _d : '';
    const orderConfirmedAndVerified = obituary.isverified &&
        obituary.newspaperorderstatus === NewspaperOrderStatus.CONFIRMED;
    const renderTableData = (data, widthClass) => (_jsx("td", Object.assign({ className: classNames(widthClass, {
            'text-column-gray-300': !orderConfirmedAndVerified
        }) }, { children: _jsx(Tooltip, Object.assign({ helpText: !orderConfirmedAndVerified
                ? 'You must confirm the order and ensure death verification is complete for this to be included in the publication issue.'
                : '' }, { children: _jsx(_Fragment, { children: data }) })) })));
    return (_jsxs(_Fragment, { children: [renderTableData(title, 'w-20'), renderTableData(customerName, 'w-32'), renderTableData(deceasedName, 'w-32'), renderTableData(funeralHomeName, 'w-32')] }));
}
