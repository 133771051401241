var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { SnapshotModel, getModelFromRef } from '..';
import { Collections } from '../../constants';
import { PublicationIssueModel } from './publicationIssueModel';
import { wrapError } from '../../types/responses';
import { PUBLICATION_ISSUE_SECTION_STATUS_CHANGE } from '../../types/events';
import { getOrThrow } from '../../utils/refs';
export class PublicationIssueSectionModel extends SnapshotModel {
    get type() {
        return Collections.publicationIssueSections;
    }
    updateStatus(changedBy, status) {
        var _a;
        return __awaiter(this, void 0, void 0, function* () {
            // Only fire the update if the new status differs from the current status
            const beforeStatus = this.modelData.status;
            if (beforeStatus === status) {
                return wrapError(new Error('Publication issue already in this status'));
            }
            // Update the status
            try {
                yield this.update({
                    status
                });
            }
            catch (err) {
                return wrapError(err);
            }
            // Insert status change event
            try {
                const publicationIssue = yield getOrThrow(this.ref.parent.parent);
                yield this.ctx.eventsRef().add({
                    type: PUBLICATION_ISSUE_SECTION_STATUS_CHANGE,
                    publicationIssueSection: this.ref,
                    publicationIssue: publicationIssue.ref,
                    createdAt: this.ctx.timestamp(),
                    newspaper: publicationIssue.data().publisher,
                    data: {
                        beforeStatus,
                        afterStatus: status,
                        changedBy: (_a = changedBy === null || changedBy === void 0 ? void 0 : changedBy.ref) !== null && _a !== void 0 ? _a : null
                    }
                });
                return {
                    error: null,
                    response: true
                };
            }
            catch (err) {
                return {
                    error: err,
                    response: null
                };
            }
        });
    }
    getPublicationIssueRef() {
        if (!this.ref.parent.parent) {
            throw new Error('Publication issue section missing parent publication issue');
        }
        // TODO(goodpaul) - Build stronger typing for traversing refs in models
        return this.ref.parent.parent;
    }
    getPublicationIssue() {
        return __awaiter(this, void 0, void 0, function* () {
            const publicationIssueRef = this.getPublicationIssueRef();
            const publicationIssue = yield getModelFromRef(PublicationIssueModel, this.ctx, publicationIssueRef);
            return publicationIssue;
        });
    }
    update(requestedParams) {
        const _super = Object.create(null, {
            update: { get: () => super.update }
        });
        return __awaiter(this, void 0, void 0, function* () {
            const params = Object.assign(Object.assign({}, requestedParams), { 
                // Need to figure out how to use the EDocumentData/EPartialDocumentData to stop using as any
                modifiedAt: this.ctx.fieldValue().serverTimestamp() });
            if (params.status) {
                params.statusChanges = [
                    ...(this.modelData.statusChanges || []),
                    {
                        status: params.status,
                        timestamp: this.ctx.timestamp()
                    }
                ];
            }
            yield _super.update.call(this, params);
        });
    }
}
