var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { all, take, takeEvery, call, put, race, fork, takeLatest, delay } from 'redux-saga/effects';
import { appSagaSelect } from 'redux/hooks';
import { eventChannel } from 'redux-saga';
import { logAndCaptureException } from 'utils';
import * as Sentry from '@sentry/browser';
import { exists } from 'lib/types';
import { push } from 'connected-react-router';
import api from 'api';
import { organizationToContextState } from 'lib/utils/organizations';
import { getFirebaseContext } from 'utils/firebase';
import { getAllowedOrganizationSnaps } from 'lib/users';
import { handleError } from 'redux/errors.slice';
import { getLogger } from 'utils/logger';
import { getLocationParams } from 'lib/frontend/utils/browser';
import { shouldRunSessionRecording, startSessionReplay } from 'utils/sessionRecording';
import { ColumnService } from 'lib/services/directory';
import AuthActions, { AuthTypes, authSelector } from '../redux/auth';
import Firebase, { getUserClaims, signInWithCustomToken } from '../EnoticeFirebase';
import { getSubdomain, getHostname } from '../utils/urls';
import { ENV, PROD, DEMO, SHOULD_RUN_CUSTOMER_IO, IS_LOCALHOST, IS_DEPLOY_PREVIEW } from '../constants';
import * as flags from '../utils/flags';
export const UNABLE_TO_FETCH_USER = 'Unable to fetch user';
/**
 *
 * @param {snapshot} org Organization to watch
 * Firebase snapshot to watch for updates on
 */
export function* watchActiveOrg(org) {
    if (!org)
        return;
    const orgChannel = eventChannel(emitter => org.ref.onSnapshot(emitter, err => logAndCaptureException(ColumnService.AUTH_AND_USER_MANAGEMENT, err, 'Error listening to active org snapshot', {
        orgId: org.id
    })));
    // ignore the first update
    yield take(orgChannel);
    yield takeEvery(orgChannel, function* f(org) {
        const authState = yield* appSagaSelect(authSelector);
        const { user } = authState;
        if (!user)
            return;
        const userSnap = yield call(fetchUser, user.ref);
        const availableOrgs = yield call(getAllowedOrganizationSnaps, userSnap);
        yield put(AuthActions.setAvailableOrganizations(availableOrgs));
        yield put(AuthActions.setActiveOrganization(org));
    });
    yield take(AuthTypes.LOGOUT_SUCCESS);
    orgChannel.close();
}
export function* listenActiveOrg() {
    while (true) {
        const auth = yield* appSagaSelect(authSelector);
        yield race([
            call(watchActiveOrg, auth.activeOrganization),
            take(AuthTypes.SET_ACTIVE_ORGANIZATION)
        ]);
    }
}
export function* fetchUser(userRef) {
    for (let i = 0; i < 5; i++) {
        try {
            const userSnap = yield call([userRef, userRef.get]);
            if (!userSnap.exists)
                throw new Error('User not found');
            return userSnap;
        }
        catch (err) {
            /**
             * This means we have a permanent error reaching Firestore and it's likely the user's
             * network will not allow the connection.
             *
             * We should suggest the following troubleshooting:
             * 1) Refresh the page
             * 2) If they've already refreshed and still see the error, change the setting
             */
            if (err && err.code === 'unavailable') {
                logAndCaptureException(ColumnService.AUTH_AND_USER_MANAGEMENT, err, 'User not found', {
                    userId: userRef.id
                });
                break;
            }
            // Retry fetching the user up to 5 times at 2000ms apart
            if (i < 4) {
                yield delay(2000);
            }
        }
    }
    yield put(yield call(handleError, {
        error: UNABLE_TO_FETCH_USER,
        service: ColumnService.AUTH_AND_USER_MANAGEMENT
    }));
    throw new Error('User fetch failed');
}
export function* watchActiveUser(userSnapshot) {
    const userChannel = eventChannel(emitter => userSnapshot.ref.onSnapshot(emitter, err => logAndCaptureException(ColumnService.AUTH_AND_USER_MANAGEMENT, err, 'Error listening to user snapshot', {
        userId: userSnapshot.id
    })));
    yield takeEvery(userChannel, function* f(user) {
        // Update the LaunchDarkly user; do this before setting user in state, so any components that
        // re-render on user changes evaluate LD flags with the right user.
        yield call(flags.setUser, user);
        // Set the local user state
        yield put(AuthActions.setUser(user));
    });
    yield take(AuthTypes.LOGOUT_SUCCESS);
    userChannel.close();
    // Set the LaunchDarkly user to anonymous
    yield call(flags.setUser, undefined);
    // Clear the logging user
    getLogger().setUser(undefined);
}
export function* setUserParameters(user) {
    var _a;
    try {
        const ctx = getFirebaseContext();
        /**
         * Exit early if the user is not signed in using any method
         * or is signed in anonymously
         */
        if (!user) {
            return;
        }
        yield put(AuthActions.setUserAuth(user));
        if (user.isAnonymous) {
            return;
        }
        const userRef = ctx.usersRef().doc(user.uid);
        const claims = (yield call(getUserClaims, user));
        // Custom token method of sign-in that provides claims to individual record ids, e.g. obits
        if (claims.isClaimLogin) {
            yield put(AuthActions.setClaimLogin(true));
            if (claims.orderId) {
                yield put(AuthActions.addOrderIdClaim(claims.orderId));
            }
            return;
        }
        const userSnap = (yield call(fetchUser, userRef));
        yield put(AuthActions.setImpersonating(!!claims.impersonating));
        void userSnap.ref.update({
            firstSignInTime: (_a = userSnap.data().firstSignInTime) !== null && _a !== void 0 ? _a : ctx.fieldValue().serverTimestamp(),
            lastSignInTime: ctx.fieldValue().serverTimestamp()
        });
        yield fork(watchActiveUser, userSnap);
        const userdata = userSnap.data();
        if (!userdata.organization) {
            yield put(AuthActions.setActiveOrganization(null));
            return;
        }
        const organizationSnapshot = yield call([
            userdata.organization,
            userdata.organization.get
        ]);
        const activeOrgQuery = getLocationParams().get('activeOrg');
        let orgFromQuery;
        if (activeOrgQuery) {
            const ref = ctx.organizationsRef().doc(activeOrgQuery);
            orgFromQuery = yield call([ref, ref.get]);
            const userCanAccessThisOrg = (orgFromQuery.exists && userdata.organization.id === orgFromQuery.id) ||
                (orgFromQuery.data().parent &&
                    orgFromQuery.data().parent.id === userdata.organization.id);
            const isDefaultOrg = orgFromQuery.data().id === userdata.organization.id;
            if (!userCanAccessThisOrg || isDefaultOrg)
                orgFromQuery = null;
        }
        let orgFromUserdata;
        if (userdata.activeOrganization) {
            orgFromUserdata = yield call([
                userdata.activeOrganization,
                userdata.activeOrganization.get
            ]);
        }
        else {
            orgFromUserdata = null;
        }
        const availableOrgs = yield call(getAllowedOrganizationSnaps, userSnap);
        yield put(AuthActions.setAvailableOrganizations(availableOrgs));
        yield put(AuthActions.setOrganization(organizationSnapshot));
        yield put(AuthActions.setActiveOrganization(orgFromQuery || orgFromUserdata || organizationSnapshot));
        yield fork(listenActiveOrg);
    }
    catch (err) {
        logAndCaptureException(ColumnService.AUTH_AND_USER_MANAGEMENT, err, 'Auth: Error in setUserParameters', {
            userEmail: (user === null || user === void 0 ? void 0 : user.email) || ''
        });
    }
    finally {
        const { userAuth, user, isClaimLogin } = yield* appSagaSelect(authSelector);
        if (userAuth && !userAuth.isAnonymous && !user && !isClaimLogin) {
            yield take(AuthTypes.SET_USER);
        }
        yield put(AuthActions.endAuth());
    }
}
function* register() {
    yield call(setUserParameters, Firebase.auth().currentUser);
}
function* loginTokenFlow({ token }) {
    yield put(AuthActions.startAuth());
    yield call([Firebase.auth(), Firebase.auth().signOut]);
    yield call(signInWithCustomToken, token);
}
export function* anonymousLogin() {
    const { userAuth } = yield* appSagaSelect(authSelector);
    if (!userAuth) {
        try {
            yield put(AuthActions.startAuth());
            yield call([Firebase.auth(), Firebase.auth().signInAnonymously]);
            // Set the LaunchDarkly user to anonymous
            yield call(flags.setUser, undefined);
        }
        catch (err) {
            logAndCaptureException(ColumnService.AUTH_AND_USER_MANAGEMENT, err, 'Auth: Error in anonymousLogin');
            yield put(AuthActions.setAuthError('Something went wrong in anonymous sign in'));
        }
        finally {
            yield put(AuthActions.endAuth());
        }
    }
}
function* listenOnAuth() {
    const authChannel = eventChannel(emitter => Firebase.auth().onAuthStateChanged(authState => {
        if (authState) {
            emitter(authState);
            const email = authState.email || '';
            const name = authState.displayName || '';
            if (ENV === PROD || ENV === DEMO) {
                Sentry.configureScope(scope => scope.setUser({
                    email
                }));
            }
            if (shouldRunSessionRecording(ENV)) {
                startSessionReplay({
                    email,
                    userId: authState.uid,
                    name
                });
            }
            if (SHOULD_RUN_CUSTOMER_IO) {
                try {
                    window._cio.identify({ id: authState.email });
                }
                catch (err) {
                    logAndCaptureException(ColumnService.WEB_PLACEMENT, err, 'Auth: Failed to initialize customer.io', {
                        email
                    });
                }
            }
        }
        else {
            emitter(false);
        }
    }));
    yield takeEvery(authChannel, setUserParameters);
}
function* logoutFlow() {
    yield put(AuthActions.startAuth());
    yield call([Firebase.auth(), Firebase.auth().signOut]);
    yield put(AuthActions.logoutSuccess());
    sessionStorage.clear();
    yield put(push('/login'));
}
/** Get the current relevant subdomain */
const getContextKey = () => {
    if (IS_LOCALHOST || IS_DEPLOY_PREVIEW) {
        return null;
    }
    const hostname = getHostname();
    if (['publicnoticecolorado'].indexOf(hostname) !== -1)
        return hostname;
    const subdomain = getSubdomain();
    if (subdomain === 'www')
        return null;
    return subdomain;
};
/** Make a request to get the public organization information for the subdomain */
function getSubdomainOrgContext() {
    return __awaiter(this, void 0, void 0, function* () {
        const contextKey = getContextKey();
        if (!contextKey)
            return null;
        const orgContext = yield api.get(`organizations/${contextKey}/context`);
        return orgContext;
    });
}
/**
 * Sets the organization context from the current subdomain or hostname
 * or, if one exists, from the current active organization
 */
function* getOrgContext() {
    const orgContext = yield call(getSubdomainOrgContext);
    if (orgContext) {
        yield put(AuthActions.setOrgContext(orgContext));
    }
    else {
        const { activeOrganization } = yield take(AuthTypes.SET_ACTIVE_ORGANIZATION);
        if (activeOrganization) {
            yield put(AuthActions.setOrgContext(organizationToContextState(activeOrganization)));
        }
    }
}
function* updateActiveOrganization({ activeOrganization }) {
    try {
        if (activeOrganization) {
            const { user } = yield* appSagaSelect(authSelector);
            if (user) {
                yield call([user.ref, user.ref.update], {
                    activeOrganization: activeOrganization.ref
                });
            }
            yield call(getOrgContext);
        }
    }
    catch (err) {
        logAndCaptureException(ColumnService.AUTH_AND_USER_MANAGEMENT, err, 'Auth: Error in updateActiveOrganization', {
            activeOrgId: activeOrganization.id
        });
    }
}
function* initializeProductTracking({ user }) {
    if (!exists(user))
        return;
    // When using DataDog, set the user
    getLogger().setUser({ id: user.id, email: user.data().email });
}
export default function* root() {
    yield fork(getOrgContext);
    yield fork(listenOnAuth);
    yield all([
        takeEvery(AuthTypes.REGISTER, register),
        takeEvery(AuthTypes.ANONYMOUS_LOGIN, anonymousLogin),
        takeEvery(AuthTypes.LOGIN_TOKEN, action => loginTokenFlow(action)),
        takeEvery(AuthTypes.LOGOUT, logoutFlow),
        takeEvery(AuthTypes.SET_ACTIVE_ORGANIZATION, action => updateActiveOrganization(action)),
        takeEvery(AuthTypes.SET_USER, getOrgContext),
        takeLatest([AuthTypes.SET_USER, AuthTypes.SET_ACTIVE_ORGANIZATION], action => initializeProductTracking(action))
    ]);
}
