var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import { logAndCaptureException, logAndCaptureMessage } from 'utils';
import { Popover } from 'lib/components/Popover';
import { ChevronDownIcon } from '@heroicons/react/24/outline';
import useAsyncEffect from 'lib/frontend/hooks/useAsyncEffect';
import { LoadingSpinner } from 'lib/components/LoadingSpinner';
import { Badge } from 'lib/components/Badge';
import classNames from 'classnames';
import { ColumnService } from 'lib/services/directory';
import NewspaperOrgImage from '../../components/PublisherImage';
function NewspaperOrderInfoRow({ order, newspaperOrder, inPopover }) {
    const { value: newspaper, isLoading } = useAsyncEffect({
        fetchData: () => __awaiter(this, void 0, void 0, function* () {
            const { response, error } = yield newspaperOrder.getNewspaper();
            if (error) {
                logAndCaptureException(ColumnService.OBITS, error, 'Unable to get newspaper logo in draft content step', { service: ColumnService.OBITS });
            }
            return response;
        }),
        dependencies: [newspaperOrder.modelData.newspaper.id]
    });
    if (isLoading || !newspaper) {
        return _jsx(LoadingSpinner, {});
    }
    return (_jsxs("div", Object.assign({ className: "flex flex-row items-center gap-3" }, { children: [_jsx(NewspaperOrgImage, { newspaper: newspaper, className: "max-w-32 max-h-32" }), _jsx(Badge, { children: order.modelData.filingTypeLabel }), inPopover && _jsx("div", { className: "w-8" })] })));
}
export function NewspaperOrderInfo({ order }) {
    const { value: newspaperOrders, isLoading, isError } = useAsyncEffect({
        fetchData: () => __awaiter(this, void 0, void 0, function* () { return order.getNewspaperOrders(); }),
        dependencies: [order.id]
    });
    if (isLoading) {
        return _jsx(LoadingSpinner, {});
    }
    if (!newspaperOrders) {
        return _jsx(_Fragment, {});
    }
    if (isError || newspaperOrders.length === 0) {
        logAndCaptureMessage('User reached draft content step in order placement without any newspaper orders', {
            orderId: order.id
        });
        return _jsx(_Fragment, {});
    }
    if (newspaperOrders.length === 1) {
        const newspaperOrder = newspaperOrders[0];
        return (_jsx(NewspaperOrderInfoRow, { order: order, newspaperOrder: newspaperOrder }));
    }
    const [firstNewspaperOrder, ...popoverNewspaperOrders] = newspaperOrders;
    return (_jsxs("div", Object.assign({ className: "flex flex-row items-center gap-3" }, { children: [_jsx(NewspaperOrderInfoRow, { order: order, newspaperOrder: firstNewspaperOrder }), _jsxs("div", Object.assign({ className: "font-medium text-sm" }, { children: ["+", popoverNewspaperOrders.length] })), _jsx(Popover, Object.assign({ id: "newspaper-order-info-popover", activator: _jsx(ChevronDownIcon, { className: "w-4 h-4" }), alignment: "right" }, { children: _jsx("div", Object.assign({ className: "flex flex-col m-2 gap-2" }, { children: popoverNewspaperOrders.map((newspaperOrder, index) => (_jsx("div", Object.assign({ className: classNames('w-full', {
                            'border-t border-column-gray-100 pt-2': !!index
                        }) }, { children: _jsx(NewspaperOrderInfoRow, { order: order, newspaperOrder: newspaperOrder, inPopover: true }) }), `${newspaperOrder.id}-popover-item`))) })) }))] })));
}
