export default {
    apiKey: 'AIzaSyAAu9_GWK5wLWpbL1fnn6L5VnRzEDgmeKQ',
    authDomain: 'enotice-testing.firebaseapp.com',
    databaseURL: 'https://enotice-testing.firebaseio.com',
    projectId: 'enotice-testing',
    storageBucket: 'enotice-testing.appspot.com',
    messagingSenderId: '193644169704',
    appId: '1:193644169704:web:df8817eef2e2240c753c5d',
    measurementId: 'G-FBPT9328BR'
};
