var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import { TableLayout } from 'lib/components/TableLayout';
import { capitalizeWord } from 'lib/utils/strings';
import { useState } from 'react';
import { useAppDispatch } from 'redux/hooks';
import ToastActions from 'redux/toast';
import { RegularDeadlineUpdateForm } from './Form';
import { RegularDeadlinesRow } from './TableRow';
export function RegularDeadlines({ productLine, deadlineSettings, timeZone, updateRow, configurable }) {
    const dispatch = useAppDispatch();
    const [rowToUpdate, setRowToUpdate] = useState();
    if (rowToUpdate) {
        const updateDeadlines = (dayLabel, updatedDeadlineSettings) => __awaiter(this, void 0, void 0, function* () {
            const { dayEnum } = rowToUpdate;
            updateRow(dayEnum, updatedDeadlineSettings);
            setRowToUpdate(undefined);
            dispatch(ToastActions.toastSuccess({
                headerText: 'Ad Deadline Updated',
                bodyText: `Your deadline settings and preferences for ${dayLabel} have been updated successfully.`
            }));
        });
        return (_jsx(RegularDeadlineUpdateForm, { productLine: productLine, timeZone: timeZone, deadlineSettings: rowToUpdate, onClose: () => setRowToUpdate(undefined), onSubmit: updateDeadlines }));
    }
    return (_jsx("div", Object.assign({ className: "pb-4 sm:rounded-lg" }, { children: _jsx(TableLayout, { filterable: { shouldShowTableItem: () => true, searchEnabled: false }, header: {
                title: `Regular ${capitalizeWord(productLine)} Deadlines`,
                subtitle: 'Set default deadline settings for each day of the week.'
            }, renderRow: deadlineSetting => (_jsx(RegularDeadlinesRow, { timeZone: timeZone, deadlineSetting: deadlineSetting })), editable: {
                onEdit: setRowToUpdate,
                editTooltip: `Edit`
            }, data: deadlineSettings !== null && deadlineSettings !== void 0 ? deadlineSettings : [], columns: [
                'Publication Day',
                'Publishing',
                'Deadline Day',
                'Deadline Time'
            ], pagination: { pageSize: 7 }, configurable: configurable }) })));
}
