var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import LoadingState from 'components/LoadingState';
import { Alert } from 'lib/components/Alert';
import AuthActions, { selectAuthLoading, selectUser } from 'redux/auth';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { ColumnService } from 'lib/services/directory';
import useAsyncEffect from 'lib/frontend/hooks/useAsyncEffect';
import { getLocationParams } from 'lib/frontend/utils/browser';
import { useState } from 'react';
import { apiPost } from 'api/typed';
import { getLoadOrderEmail, loadOrderItems } from './helpers/orderOperations';
import PlacementFlowStepSelector from './PlacementFlowStepSelector';
import { PlacementFlowStep } from './placementFlowStep';
import { useOrderEditableData } from './hooks/useOrderEditableData';
const steps = [
    PlacementFlowStep.Schedule,
    PlacementFlowStep.Content,
    PlacementFlowStep.Summary
];
export default function OrderEditFlow({ claim, orderId, product }) {
    const params = getLocationParams();
    const dispatch = useAppDispatch();
    const userSnap = useAppSelector(selectUser);
    const authIsLoading = useAppSelector(selectAuthLoading);
    const version = parseInt(getLocationParams().get('version') || '', 10);
    const accessCode = params.get('accessCode');
    const authEmail = getLoadOrderEmail();
    const isAnonymousFlow = !userSnap && !authIsLoading && !!accessCode && !!authEmail;
    const [reauthorized, setReauthorized] = useState(false);
    const shouldReauthorize = isAnonymousFlow && !reauthorized;
    const reauthorizeUserWithEditClaims = () => __awaiter(this, void 0, void 0, function* () {
        if (!shouldReauthorize) {
            return;
        }
        const accessResult = yield apiPost('orders/grant-access', {
            accessCode,
            authEmail,
            forFuneralHomeVerification: false,
            editVersion: version
        });
        if (!accessResult.success) {
            throw Error(accessResult.error);
        }
        const { token } = accessResult;
        dispatch(AuthActions.loginToken(token));
    });
    const { value, isError: failedToLoad } = useAsyncEffect({
        fetchData: () => __awaiter(this, void 0, void 0, function* () {
            if (shouldReauthorize) {
                yield reauthorizeUserWithEditClaims();
                setReauthorized(true);
                return null;
            }
            if (!orderId || !version) {
                return null;
            }
            return yield loadOrderItems(orderId, version);
        }),
        errorConfig: {
            service: ColumnService.OBITS,
            message: 'Failed to retrieve order model',
            tags: {
                orderId,
                claim
            }
        },
        dependencies: [orderId, version, shouldReauthorize]
    });
    const { orderModel, adModel, newspaperOrders } = value !== null && value !== void 0 ? value : {};
    const editData = useOrderEditableData({
        userSnap,
        order: orderModel !== null && orderModel !== void 0 ? orderModel : null,
        newspaperOrders: newspaperOrders !== null && newspaperOrders !== void 0 ? newspaperOrders : []
    });
    if (failedToLoad) {
        return _jsx(Alert, { title: "Loading failure", id: "loading-failure-error" });
    }
    if (orderModel && adModel && version) {
        return (_jsx(PlacementFlowStepSelector, { orderModel: orderModel, adModel: adModel, draftNewspaperOrders: newspaperOrders, steps: steps, version: version, product: product, editData: editData !== null && editData !== void 0 ? editData : undefined }));
    }
    return _jsx(LoadingState, { context: { service: ColumnService.OBITS } });
}
