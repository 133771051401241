import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import htmlToText from 'html-to-text';
export function ClassifiedsOverviewRow(props) {
    var _a, _b, _c;
    const { classified } = props;
    const title = htmlToText.fromString((_b = (_a = classified.title) === null || _a === void 0 ? void 0 : _a.replace(/,/g, '')) !== null && _b !== void 0 ? _b : '');
    const customerName = `${classified.filerfirstname} ${classified.filerlastname}`;
    const category = (_c = classified.filingtype) !== null && _c !== void 0 ? _c : '';
    return (_jsxs(_Fragment, { children: [_jsx("td", Object.assign({ className: "w-24" }, { children: title })), _jsx("td", Object.assign({ className: "w-40" }, { children: customerName })), _jsx("td", Object.assign({ className: "w-40" }, { children: category }))] }));
}
