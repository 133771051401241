var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { getOccupationValFromOrganizationVal, removeUndefinedFields } from '../helpers';
import { getModelFromSnapshot } from '../model';
import { OrganizationModel } from '../model/objects/organizationModel';
import { createNotificationsObject } from '../utils/users';
export class UserService {
    constructor(context) {
        this.context = context;
    }
    getUserAdditionData(user, organizationSnap, role, occupationType) {
        var _a;
        return __awaiter(this, void 0, void 0, function* () {
            const occupation = occupationType !== null && occupationType !== void 0 ? occupationType : getOccupationValFromOrganizationVal(organizationSnap.data().organizationType);
            if (!occupation) {
                throw new Error(`Could not retrieve occupationType from organization`);
            }
            const organizationModel = getModelFromSnapshot(OrganizationModel, this.context, organizationSnap);
            const { allowedOrganizations, roles } = yield organizationModel.getUserOrgStructureFromOrganization(role);
            const notifications = ((_a = user.data()) === null || _a === void 0 ? void 0 : _a.notifications) || createNotificationsObject(occupation);
            const existingUserData = user.data();
            // Don't overwrite roles if the user already has them set for a given org
            const newRoles = Object.assign(Object.assign({}, roles), existingUserData === null || existingUserData === void 0 ? void 0 : existingUserData.roles);
            // Only update the user's activeOrg/ organization if they don't already exist
            const updateData = removeUndefinedFields({
                roles: newRoles,
                postRegistrationComplete: true,
                activeOrganization: (existingUserData === null || existingUserData === void 0 ? void 0 : existingUserData.activeOrganization)
                    ? undefined
                    : organizationModel.ref,
                allowedOrganizations: (allowedOrganizations === null || allowedOrganizations === void 0 ? void 0 : allowedOrganizations.length)
                    ? this.context.fieldValue().arrayUnion(...allowedOrganizations)
                    : undefined,
                notifications,
                organization: (existingUserData === null || existingUserData === void 0 ? void 0 : existingUserData.organization)
                    ? undefined
                    : organizationModel.ref,
                occupation
            });
            return { ref: user.ref, updateData };
        });
    }
}
