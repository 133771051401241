import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { ColumnSelect } from 'lib/components/ColumnSelect';
function MultipleChoiceQuestionInput({ question, value, onChange }) {
    return (_jsx("div", { children: _jsx(ColumnSelect, { id: `multiplechoice-${question.varName}`, allowUndefined: true, options: question.choices.map(choice => {
                var _a;
                return ({
                    value: choice.value,
                    label: (_a = choice.label) !== null && _a !== void 0 ? _a : choice.value
                });
            }), value: value || '', onChange: v => {
                onChange({
                    varName: question.varName,
                    value: v
                });
            }, labelText: question.prompt }) }));
}
export default MultipleChoiceQuestionInput;
