var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
/* eslint-disable no-use-before-define */
/* eslint-disable max-classes-per-file */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { get as lodashGet } from 'lodash';
import { Collections } from '../constants';
import { dateTimeLikeToDate } from '../date';
import { isRef } from '../model/refs';
export const expectFieldValueDelete = () => {
    // This relies on the specific mock implementation of fieldValue() in firebaseMockUtils.
    // The real Firebase SDK has no such __name property on FieldValue
    return expect.objectContaining({ __name: 'delete' });
};
export const getIdAndPathFromIdMaybeWithPath = (idMaybeWithPath) => {
    const parsedPath = idMaybeWithPath.startsWith('/')
        ? idMaybeWithPath.substring(1)
        : idMaybeWithPath;
    let parsedId;
    if (parsedPath.indexOf('/') !== -1) {
        const parsedPathSplit = parsedPath.split('/');
        parsedId = parsedPathSplit[parsedPathSplit.length - 1];
    }
    else {
        parsedId = parsedPath;
    }
    return { id: parsedId, path: parsedPath };
};
class MockFirebaseTransaction {
    get(ref) {
        return ref.get();
    }
    add(ref, data) {
        return ref.add(data);
    }
    set(ref, data) {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        ref.set(data);
        return this;
    }
    update(ref, data) {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        ref.update(data);
        return this;
    }
    delete(ref) {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        ref.delete();
        return this;
    }
}
export class MockFirebaseContext {
    constructor() {
        this.collections = {};
    }
    fieldValue(name) {
        return {
            __name: name,
            serverTimestamp: () => this.fieldValue('serverTimestamp'),
            arrayRemove: () => this.fieldValue('arrayRemove'),
            arrayUnion: () => this.fieldValue('arrayUnion'),
            delete: () => this.fieldValue('delete'),
            increment: () => this.fieldValue('increment'),
            isEqual: () => true
        };
    }
    timestamp(options) {
        return options
            ? mockTimestampWithNanos(options.seconds, options.nanoseconds)
            : mockTimestampWithNanos(Date.now() / 1000, 0);
    }
    timestampFromDate(date) {
        return mockTimestamp(date);
    }
    /**
     * A testing only method to get or create a collection reference.
     */
    collection(collectionPath) {
        if (!this.collections[collectionPath]) {
            this.collections[collectionPath] = mockCollectionRef(collectionPath);
        }
        return this.collections[collectionPath];
    }
    /**
     * A testing only methods to clear all docs in the context.
     */
    clearData() {
        const collectionRefs = Object.values(this.collections);
        for (const cr of collectionRefs) {
            cr.setDocs([]);
        }
    }
    doc(path) {
        const segments = path.split('/').filter(Boolean);
        if (segments.length < 2 || segments.length % 2 !== 0) {
            throw new Error(`Invalid doc() path: ${path}`);
        }
        const currentCollection = this.collection(segments.slice(0, -1).join('/'));
        const currentRef = currentCollection.doc(segments[segments.length - 1]);
        return currentRef;
    }
    runTransaction(handler) {
        return handler(new MockFirebaseTransaction());
    }
    adTemplatesRef() {
        return this.collection(Collections.adTemplates);
    }
    affidavitTemplatesRef() {
        return this.collection(Collections.affidavitTemplates);
    }
    cachesRef(parent) {
        return this.collection(`${parent.path}/${Collections.caches}`);
    }
    cacheEntriesRef(parent) {
        return this.collection(`${parent.path}/${Collections.cacheEntries}`);
    }
    cardsRef() {
        return this.collection(Collections.cards);
    }
    cardInvoicesRef() {
        return this.collection(Collections.cardInvoices);
    }
    notesRef() {
        return this.collection(Collections.notes);
    }
    cardTransactionsRef() {
        return this.collection(Collections.cardTransactions);
    }
    ledgerRef() {
        return this.collection(Collections.ledger);
    }
    classifiedsRef() {
        return this.collection(Collections.classifieds);
    }
    customersRef() {
        return this.collection(Collections.customers);
    }
    customerOrganizationsRef() {
        return this.collection(Collections.customerOrganizations);
    }
    deadlinesCollectionGroupRef() {
        return this.collection(Collections.deadlines);
    }
    displaySitesRef() {
        return this.collection(Collections.displaySites);
    }
    displaySiteUploadIDsRef(parent) {
        return this.collection(`${parent.path}/${Collections.uploadIDs}`);
    }
    eeditionsRef() {
        return this.collection(Collections.eeditions);
    }
    emailConfirmationsRef() {
        return this.collection(Collections.emailConfirmations);
    }
    eventsRef() {
        return this.collection(Collections.events);
    }
    filingTypesRef() {
        return this.collection(Collections.filingTypes);
    }
    ftpFilesRef() {
        return this.collection(Collections.ftpFiles);
    }
    invitesRef() {
        return this.collection(Collections.invites);
    }
    invoicesRef() {
        return this.collection(Collections.invoices);
    }
    invoiceItemsRef() {
        return this.collection(Collections.invoiceItems);
    }
    invoiceTransactionsRef(parent) {
        return this.collection(`${parent.path}/${Collections.invoiceTransactions}`);
    }
    invoiceTransactionsCollectionGroupRef() {
        return this.collection(Collections.invoiceTransactions);
    }
    migrationsRef() {
        return this.collection(Collections.migrations);
    }
    notificationsRef() {
        return this.collection(Collections.notifications);
    }
    newspaperOrdersRef() {
        return this.collection(Collections.newspaperOrders);
    }
    newspaperOrdersCollectionGroupRef() {
        return this.collection(Collections.newspaperOrders);
    }
    obituariesRef() {
        return this.collection(Collections.obituaries);
    }
    ordersRef() {
        return this.collection(Collections.orders);
    }
    orderNewspaperOrdersRef(parent) {
        return this.collection(`${parent.path}/${Collections.newspaperOrders}`);
    }
    organizationsRef() {
        return this.collection(Collections.organizations);
    }
    organizationDeadlinesRef(parent) {
        return this.collection(`${parent.path}/${Collections.deadlines}`);
    }
    organizationProductPublishingSettingsRef(parent) {
        return this.collection(`${parent.path}/${Collections.productPublishingSettings}`);
    }
    modularSizesRef() {
        return this.collection(Collections.modularSizes);
    }
    payoutsRef() {
        return this.collection(Collections.payouts);
    }
    publicNoticesRef() {
        return this.collection(Collections.publicNotices);
    }
    previewNoticesRef() {
        return this.collection(Collections.previewNotices);
    }
    productPublishingSettingsCollectionGroupRef() {
        return this.collection(Collections.productPublishingSettings);
    }
    publicationIssuesRef() {
        return this.collection(Collections.publicationIssues);
    }
    publicationIssueAttachmentsRef(parent) {
        return this.collection(`${parent.path}/${Collections.publicationIssueAttachments}`);
    }
    publicationIssueSectionsCollectionGroupRef() {
        return this.collection(Collections.publicationIssueSections);
    }
    publicationIssueSectionsRef(parent) {
        return this.collection(`${parent.path}/${Collections.publicationIssueSections}`);
    }
    publishingSettingsRef() {
        return this.collection(Collections.publishingSettings);
    }
    adRatesRef() {
        return this.collection(Collections.rates);
    }
    ratesRef() {
        return this.collection(Collections.rates);
    }
    runsRef() {
        return this.collection(Collections.runs);
    }
    subscriptionsRef() {
        return this.collection(Collections.subscriptions);
    }
    transfersRef() {
        return this.collection(Collections.transfers);
    }
    userNoticesRef() {
        return this.collection(Collections.userNotices);
    }
    userNoticeFilesRef(parent) {
        return this.collection(`${parent.path}/${Collections.noticeFiles}`);
    }
    userDraftsRef() {
        return this.collection(Collections.userDrafts);
    }
    usersRef() {
        return this.collection(Collections.users);
    }
    notarizationsRef() {
        return this.collection(Collections.notarizations);
    }
    joinRequestsRef() {
        return this.collection(Collections.joinRequests);
    }
    stripeEventsRef() {
        // TODO: update this return type once we clean up our Stripe event & object types!
        return this.collection(Collections.stripeevents);
    }
    updateSettingRequestsRef() {
        return this.collection(Collections.updateSettingRequests);
    }
    adjudicationAreasRef() {
        return this.collection(Collections.adjudicationAreas);
    }
}
export class MockCollectionRef {
    constructor(path, parent, docs) {
        this.id = path;
        this.parent = parent;
        this.path = path;
        this.docs = [];
        this.setDocs(docs);
    }
    /**
     * A testing-only method to append docs to the collection.
     */
    addDocs(docs) {
        this.docs = [...this.docs, ...docs];
    }
    /**
     * A testing-only method to retrieve all docs in the collection.
     */
    getDocs() {
        return this.docs;
    }
    /**
     * A testing-only method to override all docs in the collection.
     */
    setDocs(docs) {
        this.docs = [...docs];
    }
    /**
     * A testing-only methods to delete a doc from a collection.
     */
    deleteDoc(id) {
        this.docs = this.docs.filter(doc => doc.id !== id);
    }
    doc(documentPath) {
        const docId = documentPath !== null && documentPath !== void 0 ? documentPath : `${Math.round(Math.random() * 10000000)}`;
        let foundDoc = this.docs.find(doc => doc.id === docId);
        if (!foundDoc) {
            foundDoc = new LinkedMockRef(docId, null, this);
            this.docs = [...this.docs, foundDoc];
        }
        return foundDoc;
    }
    add(data) {
        return __awaiter(this, void 0, void 0, function* () {
            const ref = this.doc();
            yield ref.set(data);
            return ref;
        });
    }
    where(fieldPath, opStr, initialValue) {
        // coalesce timestamps or other date like to date
        let value = initialValue;
        if (value === null || value === void 0 ? void 0 : value.toDate) {
            value = dateTimeLikeToDate(value);
        }
        const docs = Object.values(this.docs);
        const filteredDocs = docs.filter(d => {
            var _a;
            const data = d.getSnap().data();
            const fieldIsName = fieldPath === '__name__' ||
                (typeof fieldPath === 'object' &&
                    ((_a = fieldPath === null || fieldPath === void 0 ? void 0 : fieldPath.segments) === null || _a === void 0 ? void 0 : _a[0]) === '__name__');
            let val = fieldIsName ? d.id : lodashGet(data, fieldPath);
            if (val === undefined) {
                return false;
            }
            if (val === null || val === void 0 ? void 0 : val.toDate) {
                val = dateTimeLikeToDate(val);
            }
            const valueIsRef = isRef(value);
            switch (opStr) {
                case '==':
                    return valueIsRef ? val.id === value.id : val === value;
                case '!=':
                    return val !== value;
                case '<':
                    return val < value;
                case '<=':
                    return val <= value;
                case '>':
                    return val > value;
                case '>=':
                    return val >= value;
                case 'in':
                    return value.some(v => isRef(v) ? v.id === val.id : v === val);
                case 'not-in':
                    return !value.includes(val);
                case 'array-contains':
                    return val.includes(value);
                case 'array-contains-any':
                    return value.some(v => val.includes(v));
                default:
                    return false;
            }
        });
        return new MockCollectionRef(this.path, this.parent, filteredDocs);
    }
    orderBy(fieldPath, directionStr) {
        const sortFn = (a, b) => {
            const dataA = a.getSnap().data();
            const dataB = b.getSnap().data();
            const dataValueIsFirebaseTimestamp = (value) => {
                const valueIsObject = typeof value === 'object';
                if (!valueIsObject)
                    return false;
                return !!(value || {}).toMillis;
            };
            const getComparableValue = (value) => {
                if (dataValueIsFirebaseTimestamp(value)) {
                    return value.toMillis();
                }
                return value;
            };
            const valueA = getComparableValue(dataA[fieldPath]);
            const valueB = getComparableValue(dataB[fieldPath]);
            const comparison = directionStr === 'desc' ? valueA < valueB : valueA > valueB;
            return dataA[fieldPath] === dataB[fieldPath] ? 0 : comparison ? 1 : -1;
        };
        const docs = Object.values(this.docs);
        const filteredDocs = docs.filter(d => {
            const data = d.getSnap().data();
            const val = lodashGet(data, fieldPath);
            if (val === undefined) {
                return false;
            }
            return true;
        });
        filteredDocs.sort(sortFn);
        return new MockCollectionRef(this.path, this.parent, filteredDocs);
    }
    limit(limit) {
        return new MockCollectionRef(this.path, this.parent, this.docs.slice(0, limit));
    }
    limitToLast(limit) {
        return this;
    }
    startAt(...fieldValues) {
        return this;
    }
    startAfter(doc) {
        const docs = Object.values(this.docs);
        const startIndex = docs.findIndex(d => d.id === doc.id);
        return new MockCollectionRef(this.path, this.parent, docs.slice(startIndex + 1));
    }
    endBefore(...fieldValues) {
        return this;
    }
    endAt(...fieldValues) {
        return this;
    }
    get() {
        return __awaiter(this, void 0, void 0, function* () {
            const docs = Object.values(this.docs).map(ref => ref.getSnap());
            return {
                docs,
                size: docs.length,
                empty: docs.length === 0
            };
        });
    }
    onSnapshot(onNext, onError) {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        this.get().then(snap => onNext(snap));
        return () => { };
    }
}
/**
 * An implementation of MockRef that is linked to a MockCollectionRef
 */
export class LinkedMockRef {
    constructor(id, data, parent) {
        this.id = id;
        this.path = `${parent.path}/${id}`;
        this.data = data;
        this.parent = parent;
    }
    /**
     * A test only method to avoid await get()
     */
    getSnap() {
        return mockSnapshotExists(this.id, this.data, this);
    }
    collection(collectionPath) {
        return MOCK_FIREBASE_CONTEXT.collection(`${this.path}/${collectionPath}`);
    }
    update(update) {
        return __awaiter(this, void 0, void 0, function* () {
            this.data = Object.assign(Object.assign({}, this.data), update);
        });
    }
    set(update) {
        return __awaiter(this, void 0, void 0, function* () {
            this.data = update;
        });
    }
    get() {
        return __awaiter(this, void 0, void 0, function* () {
            if (this.data === null) {
                return mockSnapshot(this.id, null, this);
            }
            return this.getSnap();
        });
    }
    delete() {
        return __awaiter(this, void 0, void 0, function* () {
            this.parent.deleteDoc(this.id);
        });
    }
    onSnapshot(onNext) {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        this.get().then(snap => onNext(snap));
        return () => { };
    }
}
/**
 * Get a fully local ESnapshotExists which contains the given data.
 *
 * Note: it's generally better to call mockRef() and use .get() on the
 * result than to call this function directly.
 */
export function mockSnapshotExists(id, data, ref) {
    return {
        id,
        exists: true,
        data: () => data,
        get ref() {
            if (ref) {
                return ref;
            }
            throw new Error('ref unimplemented!');
        }
    };
}
/**
 * Get a fully local ESnapshot that can represent a non-existent doc if there is
 * no data.
 *
 * Note: it's generally better to call mockRef() with null data and use .get()
 * on the result than to call this function directly.
 */
export function mockSnapshot(id, data, ref) {
    if (data === null)
        return {
            id,
            exists: false,
            data: () => undefined,
            get ref() {
                if (ref) {
                    return ref;
                }
                throw new Error('ref unimplemented!');
            }
        };
    return mockSnapshotExists(id, data, ref);
}
/**
 * Get a fully local ERef object which will return the given data when get() is called.
 * Other methods (update, set, delete) can be mocked out via the methods argument.
 */
export function mockRef(idMaybeWithPath, data, parent, methods) {
    const unimplemented = (name) => () => {
        throw new Error(`${name}() unimplemented for ${idMaybeWithPath}!`);
    };
    const unimplementedAsync = (name) => () => __awaiter(this, void 0, void 0, function* () { return unimplemented(name); });
    const { id, path: parsedPath } = getIdAndPathFromIdMaybeWithPath(idMaybeWithPath);
    return {
        id,
        path: parent ? `${parent.path}/${parsedPath}` : parsedPath,
        getSnap() {
            return mockSnapshotExists(id, data, this);
        },
        get() {
            return __awaiter(this, void 0, void 0, function* () {
                if (data === null)
                    return mockSnapshot(id, data, this);
                return this.getSnap();
            });
        },
        update: (methods === null || methods === void 0 ? void 0 : methods.update) || unimplementedAsync('update'),
        set: (methods === null || methods === void 0 ? void 0 : methods.set) || unimplementedAsync('set'),
        delete: (methods === null || methods === void 0 ? void 0 : methods.delete) || unimplementedAsync('delete'),
        collection: (methods === null || methods === void 0 ? void 0 : methods.collection) ||
            ((name) => {
                return MOCK_FIREBASE_CONTEXT.collection(`${parsedPath}/${name}`);
            }),
        onSnapshot(onNext) {
            if (methods === null || methods === void 0 ? void 0 : methods.onSnapshot) {
                return methods.onSnapshot(onNext);
            }
            onNext(this.getSnap());
            return () => { };
        },
        get parent() {
            if (parent) {
                return parent;
            }
            return undefined;
        }
    };
}
export function mockTimestamp(date) {
    return {
        seconds: date.getTime() / 1000,
        nanoseconds: 0,
        toDate: () => date,
        toMillis: () => date.getTime(),
        isEqual: other => other.toMillis() === date.getTime(),
        valueOf: () => date.toISOString()
    };
}
export function mockTimestampWithNanos(seconds, nanoseconds = 0) {
    const date = new Date(seconds);
    return {
        seconds,
        nanoseconds,
        toDate: () => date,
        toMillis: () => date.getTime(),
        isEqual: other => other.toMillis() === date.getTime(),
        valueOf: () => date.toISOString()
    };
}
export function mockCollectionRef(path, parent) {
    let resultParent = parent;
    // Infer the parent if the path has an even number of slashes and parent is not defined
    const slashCount = (path.match(/\//g) || []).length;
    if (!parent && slashCount % 2 === 0 && slashCount > 0) {
        const parentPath = path.substring(0, path.lastIndexOf('/'));
        resultParent = MOCK_FIREBASE_CONTEXT.doc(parentPath);
    }
    return new MockCollectionRef(path, resultParent || null, []);
}
const MOCK_FIREBASE_CONTEXT = new MockFirebaseContext();
export function mockFirebaseContext() {
    return MOCK_FIREBASE_CONTEXT;
}
