/**
 * The default error reporter just uses console logs in case it is somehow unset.
 */
let _ERROR_REPORTER = {
    logInfo: (msg, tags) => {
        console.log(msg, tags);
    },
    logAndCaptureWarning: (msg, tags) => {
        console.warn(msg, tags);
    },
    logAndCaptureError: (service, err, msg, tags) => {
        console.error(msg, err, tags);
    },
    logAndCaptureCriticalError: (service, err, msg, tags) => {
        const tagsWithService = Object.assign(Object.assign({}, tags), { service });
        console.error(msg, err, tagsWithService);
    }
};
export const setErrorReporter = (reporter) => {
    _ERROR_REPORTER = reporter;
};
export const getErrorReporter = () => {
    return _ERROR_REPORTER;
};
