var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useEffect, useState, useReducer } from 'react';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { selectIsPublisher } from 'redux/auth';
import { exists } from 'lib/types';
import { State as States, CourtHouse as CourtHousesList } from 'lib/enums';
import { findMatchingNoticeType } from 'lib/publishers';
import { confirmAffidavitRecipients, selectCurrentStepId, selectIsEditing, selectNoticeType, selectPreviousNoticeType } from 'redux/placement';
import { columnObjectsAreEqual } from 'lib/utils/stringify';
import { getNoticeTypeFromNoticeData } from 'lib/helpers';
import { CONFIRM_PROOF } from '../helpers/calculatePlacementSteps';
import { verifyAddressDeliverability } from '../helpers/verifyAddressDeliverability';
import { reducer as affidavitRecipientReducer, actions as affidavitRecipientActions, initialState, DEFAULT_ERROR, DEFAULT_MAILING, DEFAULT_COURTHOUSE, selectIsFormComplete } from './affidavitRecipient.slice';
const { setSendAffidavitByMail, setCourthouseRecipients, setSendAffidavitToCourthouse, setMailingRecipients, setSendAffidavitByEmail, setRecipientValidationErrors, validateCourthouseRecipients, setShowErrors, validateMailingRecipients } = affidavitRecipientActions;
export function useAffidavitRecipientsState({ stepId, publisherOrganization, filer, draft }) {
    var _a, _b;
    const reduxDispatch = useAppDispatch();
    const [state, dispatch] = useReducer(affidavitRecipientReducer, initialState);
    const { sendAffidavitByEmail, sendAffidavitByMail, mailingRecipients, recipientValidationErrors, sendAffidavitToCourthouse, courthouseRecipients, courthouseValidationErrors } = state;
    const isPublisher = useAppSelector(selectIsPublisher);
    const editing = useAppSelector(selectIsEditing);
    const activeStepId = useAppSelector(selectCurrentStepId);
    const noticeType = useAppSelector(selectNoticeType);
    const previousNoticeType = useAppSelector(selectPreviousNoticeType);
    const [customer, setCustomer] = useState(null);
    const [sendEmailCheckEdited, setSendEmailCheckEdited] = useState(false);
    const defaultCourthouse = exists(publisherOrganization) && publisherOrganization.data().courthouseId
        ? CourtHousesList.find(c => c.id === publisherOrganization.data().courthouseId)
        : undefined;
    const placementNoticeType = useAppSelector(state => state.placement.noticeType);
    const placementMail = useAppSelector(state => state.placement.mail);
    const placementCustomer = useAppSelector(state => state.placement.customer);
    const placementRequireEmailAffidavit = useAppSelector(state => state.placement.requireEmailAffidavit);
    const publisherOrganizationState = publisherOrganization === null || publisherOrganization === void 0 ? void 0 : publisherOrganization.data().state;
    // TODO: These settings combinations are a little hard to follow — is there a way we could make them more clear?
    const mailAffidavitsOutsideColumn = !!(publisherOrganization === null || publisherOrganization === void 0 ? void 0 : publisherOrganization.data().mailAffidavitsOutsideColumn);
    const publicationRequiresPhysicalAffidavit = !!(publisherOrganization === null || publisherOrganization === void 0 ? void 0 : publisherOrganization.data().physicalAffidavit);
    const publicationRequiresWetSignature = ((_a = publisherOrganization === null || publisherOrganization === void 0 ? void 0 : publisherOrganization.data().affidavitReconciliationSettings) === null || _a === void 0 ? void 0 : _a.notarizationVendor) === 'manual';
    const customNoticeType = getNoticeTypeFromNoticeData({
        noticeType,
        previousNoticeType
    }, publisherOrganization, {
        skipDisplayType: true
    });
    const customNoticeTypeRequiresWetSignature = ((_b = customNoticeType === null || customNoticeType === void 0 ? void 0 : customNoticeType.affidavitReconciliationSettings) === null || _b === void 0 ? void 0 : _b.notarizationVendor) ===
        'manual';
    const noticeWillRequireWetSignature = publicationRequiresWetSignature || customNoticeTypeRequiresWetSignature;
    const customerMustReceiveMailedAffidavit = publicationRequiresPhysicalAffidavit || noticeWillRequireWetSignature;
    const hideEmailAffidavitOption = customerMustReceiveMailedAffidavit ||
        !!(publisherOrganization === null || publisherOrganization === void 0 ? void 0 : publisherOrganization.data().hideDigitalAffidavits);
    const doesNotRequireEmailAffidavit = !(isPublisher ||
        !hideEmailAffidavitOption ||
        !!(customer === null || customer === void 0 ? void 0 : customer.data().allowAffidavitEmail));
    // TODO: Move validation handling to TextField validation system
    useEffect(() => {
        dispatch(validateMailingRecipients());
    }, [mailingRecipients]);
    // TODO: Move validation handling to TextField validation system
    useEffect(() => {
        dispatch(validateCourthouseRecipients());
    }, [courthouseRecipients]);
    // TODO: Can this recipients calculation be moved to a Redux action?
    const loadAffidavitsRecipients = () => {
        let mailAddresses = [];
        if (activeStepId === CONFIRM_PROOF || editing) {
            if (placementMail) {
                mailAddresses = placementMail;
            }
        }
        else {
            const savedAddresses = loadFromSaved({ filer, editing });
            const placementAddresses = loadPlacementMail({ placementMail });
            const newspaperAddresses = loadNewspaperMail({
                publisherOrganization,
                placementNoticeType
            });
            if (newspaperAddresses.length) {
                mailAddresses = newspaperAddresses;
            }
            else if (placementAddresses.length) {
                mailAddresses = placementAddresses;
            }
            else {
                mailAddresses = savedAddresses;
            }
        }
        if (customerMustReceiveMailedAffidavit) {
            dispatch(setSendAffidavitByMail(true));
        }
        const courthouseAddresses = mailAddresses.filter(item => item.isCourthouse);
        if (courthouseAddresses.length) {
            dispatch(setCourthouseRecipients(courthouseAddresses));
            dispatch(setSendAffidavitToCourthouse(true));
        }
        mailAddresses = mailAddresses.filter(item => !item.isCourthouse);
        const provider = noticeWillRequireWetSignature ? 'manual' : 'lob';
        if (mailAddresses.length) {
            dispatch(setMailingRecipients(mailAddresses.map(mailDelivery => (Object.assign(Object.assign({}, mailDelivery), { provider })))));
            dispatch(setSendAffidavitByMail(true));
        }
        else {
            dispatch(setMailingRecipients([Object.assign(Object.assign({}, DEFAULT_MAILING), { provider })]));
        }
    };
    useEffect(() => {
        if (sendAffidavitByMail) {
            loadAffidavitsRecipients();
        }
    }, [sendAffidavitByMail]);
    // TODO: Can/should this state be part of PlaceNoticeState
    useEffect(() => {
        // We rely on the customer from placement to avoid creating a duplicate customer
        // when the publisherOrganization and filer are set
        if (!(placementCustomer === null || placementCustomer === void 0 ? void 0 : placementCustomer.id)) {
            setCustomer(null);
            return;
        }
        void (() => __awaiter(this, void 0, void 0, function* () {
            const customerSnap = yield (placementCustomer === null || placementCustomer === void 0 ? void 0 : placementCustomer.get());
            if (exists(customerSnap)) {
                setCustomer(customerSnap);
            }
            else {
                setCustomer(null);
            }
        }))();
    }, [placementCustomer === null || placementCustomer === void 0 ? void 0 : placementCustomer.id]);
    // TODO: Can this be moved to a reducer?
    useEffect(() => {
        if (editing && !sendEmailCheckEdited) {
            dispatch(setSendAffidavitByEmail(placementRequireEmailAffidavit));
            setSendEmailCheckEdited(true);
        }
    }, [placementRequireEmailAffidavit]);
    // TODO: Can this be moved to a reducer?
    useEffect(() => {
        if (sendAffidavitToCourthouse && defaultCourthouse) {
            dispatch(setCourthouseRecipients(courthouseRecipients.map(c => c.name === ''
                ? Object.assign(Object.assign({}, c), { courtHouse: defaultCourthouse.id, name: defaultCourthouse.name, address: {
                        address_line1: defaultCourthouse.address,
                        address_line2: '',
                        address_city: defaultCourthouse.city,
                        address_state: publisherOrganizationState || '',
                        state: States.kansas.value,
                        address_zip: defaultCourthouse.address.slice(defaultCourthouse.address.length - 5)
                    }, isCourthouse: true, copies: 1 }) : c)));
        }
    }, [sendAffidavitToCourthouse]);
    // TODO: Can this be moved to a reducer?
    useEffect(() => {
        if (!sendAffidavitByMail) {
            dispatch(setRecipientValidationErrors(DEFAULT_ERROR));
        }
    }, [activeStepId]);
    // Unfortunately, it's important that this useEffect happens after
    // the one above it (setting courthouse defaults). So don't move this!
    useEffect(() => {
        dispatch(setSendAffidavitByMail(false));
        loadAffidavitsRecipients();
    }, [filer === null || filer === void 0 ? void 0 : filer.id, publisherOrganization === null || publisherOrganization === void 0 ? void 0 : publisherOrganization.id, placementNoticeType]);
    // TODO: Can this be moved to an action?
    const validateAffidavitForm = () => __awaiter(this, void 0, void 0, function* () {
        const data = {
            mailAffidavitsOutsideColumn,
            requireEmailAffidavit: doesNotRequireEmailAffidavit
                ? false
                : sendAffidavitByEmail
        };
        if (sendAffidavitByMail && recipientValidationErrors.some(e => !!e)) {
            dispatch(setShowErrors(true));
            return { success: false, data };
        }
        if (sendAffidavitToCourthouse &&
            courthouseValidationErrors.some(e => !!e)) {
            dispatch(setShowErrors(true));
            return { success: false, data };
        }
        const isMail = !columnObjectsAreEqual(mailingRecipients, [DEFAULT_MAILING]);
        const isCourtHouse = !columnObjectsAreEqual(courthouseRecipients, [
            DEFAULT_COURTHOUSE
        ]);
        const newMail = [];
        if (isMail) {
            newMail.push(...mailingRecipients);
        }
        if (isCourtHouse) {
            newMail.push(...courthouseRecipients);
        }
        data.mail = newMail.length > 0 ? newMail : null;
        if (sendAffidavitByMail) {
            const addressVerifications = yield Promise.all(mailingRecipients.map(mailingAddress => verifyAddressDeliverability({ mailingAddress, draft })));
            const hasUndeliverableAddress = addressVerifications.some(deliverable => !deliverable);
            if (hasUndeliverableAddress) {
                const newError = [...DEFAULT_ERROR];
                addressVerifications.forEach((deliverable, index) => {
                    if (!deliverable) {
                        newError[index] =
                            'The address you’ve entered is invalid. Please input a new address to ensure the affidavit will be delivered.';
                    }
                });
                dispatch(setRecipientValidationErrors([...newError]));
                dispatch(setShowErrors(true));
                return { success: false, data };
            }
        }
        return yield reduxDispatch(confirmAffidavitRecipients(data));
    });
    const complete = selectIsFormComplete(state, activeStepId, stepId);
    return {
        state,
        dispatch,
        validateAffidavitForm,
        complete,
        doesNotRequireEmailAffidavit,
        hideEmailAffidavitOption,
        customerMustReceiveMailedAffidavit
    };
}
const loadFromSaved = ({ filer, editing }) => {
    if (!exists(filer))
        return [];
    if (!editing)
        return [];
    const { savedInfo } = filer.data();
    const savedMailAddresses = savedInfo === null || savedInfo === void 0 ? void 0 : savedInfo.mails;
    if (savedMailAddresses === null || savedMailAddresses === void 0 ? void 0 : savedMailAddresses.length) {
        return savedMailAddresses.map(savedMailAddress => (Object.assign({ description: '' }, savedMailAddress)));
    }
    return [];
};
const loadPlacementMail = ({ placementMail }) => {
    if (!(placementMail === null || placementMail === void 0 ? void 0 : placementMail.length))
        return [];
    const nonDefaultAddresses = placementMail.filter(item => !item.isNoticeTypeDefault);
    if (nonDefaultAddresses.length) {
        return nonDefaultAddresses;
    }
    return [];
};
const loadNewspaperMail = ({ publisherOrganization, placementNoticeType }) => {
    if (exists(publisherOrganization)) {
        const noticeType = findMatchingNoticeType(publisherOrganization, placementNoticeType);
        let newspaperAddresses;
        if (noticeType === null || noticeType === void 0 ? void 0 : noticeType.defaultMailingAddresses) {
            newspaperAddresses = noticeType.defaultMailingAddresses.map(mail => (Object.assign(Object.assign({}, mail), { address: Object.assign(Object.assign({}, mail.address), { address_state: typeof mail.address.address_state === 'string'
                        ? States.by_label(mail.address.address_state).value
                        : mail.address.address_state }), isNoticeTypeDefault: true })));
        }
        else {
            newspaperAddresses = [];
        }
        return newspaperAddresses;
    }
    return [];
};
