import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useContext } from 'react';
import { ColumnSelectItem } from 'lib/components/ColumnSelect/ColumnSelectItem';
import { PopoverContext } from 'lib/components/Popover';
export function AutocompletePanel({ loading, filteredOptions, selectedOption, highlightedIndex, selectOption }) {
    const { setOpen } = useContext(PopoverContext);
    return (_jsxs("ul", Object.assign({ className: "text-sm p-2 max-h-64 overflow-y-scroll" }, { children: [(filteredOptions.length === 0 || loading) && (_jsx("div", Object.assign({ className: "text-center py-2 text-column-gray-600" }, { children: loading ? 'Loading...' : 'No options' }))), !loading &&
                filteredOptions.length > 0 &&
                filteredOptions.map((option, index) => (_jsx("li", Object.assign({ role: "option", "aria-selected": option.value === (selectedOption === null || selectedOption === void 0 ? void 0 : selectedOption.value), onMouseDown: () => {
                        selectOption(option);
                        setOpen(false);
                    } }, { children: _jsx(ColumnSelectItem, { option: option, active: index === highlightedIndex, selected: option.value === (selectedOption === null || selectedOption === void 0 ? void 0 : selectedOption.value) }) }), option.value)))] })));
}
