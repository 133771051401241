var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { getReactImageStylesFromCrop } from 'lib/orders/images';
import { ArrowsPointingOutIcon, TrashIcon } from '@heroicons/react/24/outline';
import React, { useState } from 'react';
import { LoadingSpinner } from 'lib/components/LoadingSpinner';
import { MimeTypes } from 'lib/types/mime';
import { cdnIfy } from 'lib/helpers';
import FileUpload from 'lib/components/FileUpload';
import Firebase from 'EnoticeFirebase';
import ImageEditorModal from './ImageEditorModal';
const storage = Firebase.storage();
export default function ImageEditorOrUploader({ onUpdateOrderImage, onDeleteOrderImage, orderImage, layout, disabled }) {
    const [showModal, setShowModal] = useState(false);
    // Indicates the image is loading-the uploader has its own state to indicate that something is being uploaded.
    const [loading, setLoading] = useState(false);
    const handleFileUpload = (file) => __awaiter(this, void 0, void 0, function* () {
        setLoading(true);
        const imageUrl = cdnIfy(file.uploadRef.fullPath, {
            cloudinaryTransformations: 'q_auto:best'
        });
        onUpdateOrderImage({
            sourcePath: file.uploadRef.fullPath,
            imageUrl
        });
        setLoading(false);
    });
    if (!(orderImage === null || orderImage === void 0 ? void 0 : orderImage.imageUrl)) {
        return (_jsx(FileUpload, { id: "uploader", multiple: false, onFileUpload: file => {
                void handleFileUpload(file[0]);
            }, uploadFolder: `documentcloud/obits/${new Date().getTime()}`, storage: storage, acceptFileTypes: [
                MimeTypes.IMG_PNG,
                MimeTypes.IMG_JPG,
                MimeTypes.IMG_TIFF
            ], fullHeight: true, disabled: disabled }));
    }
    return (_jsxs("div", Object.assign({ className: "relative flex items-center justify-center w-full h-full" }, { children: [showModal && (_jsx(ImageEditorModal, { onUpdateOrderImage: onUpdateOrderImage, orderImage: orderImage, onClose: () => setShowModal(false), layout: layout, disabled: disabled })), loading ? (_jsx(LoadingSpinner, {})) : (_jsxs(_Fragment, { children: [_jsx("img", { src: orderImage.imageUrl, style: Object.assign({}, getReactImageStylesFromCrop(orderImage.crop)) }), !loading && (_jsxs("div", Object.assign({ className: "absolute top-0 right-0 p-1 m-1 flex gap-1" }, { children: [_jsx("div", Object.assign({ className: "p-1 rounded-md bg-column-primary-500", role: "button", tabIndex: 0, onClick: () => setShowModal(true) }, { children: _jsx(ArrowsPointingOutIcon, { className: "h-4 w-4 text-white" }) })), _jsx("div", Object.assign({ className: "p-1 rounded-md bg-column-primary-500", role: "button", tabIndex: 0, onClick: () => {
                                    if (disabled)
                                        return;
                                    onDeleteOrderImage();
                                } }, { children: _jsx(TrashIcon, { className: "h-4 w-4 text-white" }) }))] })))] }))] })));
}
