var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { InvoiceType, isPayOrderInvoiceData } from 'lib/types/invoices';
import { logAndCaptureMessage } from 'utils';
import { apiPost } from 'api/typed';
const getPaymentMethodFromInvoicePaymentMethod = (invoicePaymentMethod) => {
    switch (invoicePaymentMethod) {
        case 'saved-ach':
            return 'ach';
        case 'saved-bank':
            return 'ach';
        case 'saved-card':
            return 'card';
        case 'card':
            return 'card';
    }
};
export const processStripeAuthorizationWithCard = (paymentMethodId, paymentMethod, payInvoiceData, invoicePricingData, idempotencyKey) => __awaiter(void 0, void 0, void 0, function* () {
    if (payInvoiceData.type === InvoiceType.ORDER) {
        return;
    }
    const { invoice, advertiser } = payInvoiceData;
    const customerEmail = advertiser.modelData.email;
    if (!customerEmail) {
        logAndCaptureMessage('Customer email missing in processPaymentWithCard!', {
            invoiceId: invoice.id,
            advertiserId: advertiser === null || advertiser === void 0 ? void 0 : advertiser.id
        });
        return;
    }
    const result = yield apiPost('invoice-transactions/create-authorization', {
        amountInCents: invoicePricingData.totalAmount,
        customerEmail,
        invoiceId: invoice.id,
        paymentMethodType: paymentMethod,
        paymentMethodToken: paymentMethodId,
        idempotencyKey
    });
    if (!result.gatewayResultSuccessful) {
        throw result.responseMessage;
    }
});
export const processStripePaymentWithCard = (paymentMethodId, paymentMethod, payInvoiceData, invoicePricingData, saveCreditCard) => __awaiter(void 0, void 0, void 0, function* () {
    const { invoice } = payInvoiceData;
    let customerEmail;
    let isOrder = false;
    if (payInvoiceData.type === InvoiceType.ORDER) {
        customerEmail = payInvoiceData.customerEmail;
        isOrder = true;
    }
    else {
        const { advertiser } = payInvoiceData;
        customerEmail = advertiser.modelData.email;
    }
    if (!customerEmail) {
        logAndCaptureMessage('Customer email missing in processPaymentWithCard!', {
            invoiceId: invoice.id
        });
        return;
    }
    const result = yield apiPost('payments/pay-stripe-invoice', {
        amount: invoicePricingData.totalAmount,
        customerEmail,
        invoiceId: invoice.id,
        paymentMethod,
        paymentMethodId,
        saveCreditCard,
        isOrder,
        orderVersion: isPayOrderInvoiceData(payInvoiceData)
            ? payInvoiceData.orderVersion
            : undefined
    });
    if (!result.success) {
        throw result.error;
    }
});
export const processStripePaymentWithSavedMethod = (invoicePaymentMethod, selectedSavedMethodId, payInvoiceData, invoicePricingData, user) => __awaiter(void 0, void 0, void 0, function* () {
    if (!invoicePaymentMethod.startsWith('saved-') || !user)
        return;
    const paymentMethod = getPaymentMethodFromInvoicePaymentMethod(invoicePaymentMethod);
    const { invoice } = payInvoiceData;
    let advertiser;
    if (payInvoiceData.type === InvoiceType.PUBLIC_NOTICE) {
        advertiser = payInvoiceData.advertiser;
    }
    const customerEmail = advertiser === null || advertiser === void 0 ? void 0 : advertiser.data().email;
    if (!customerEmail) {
        logAndCaptureMessage('Customer email missing in processPaymentWithSavedMethod!', {
            invoiceId: invoice.id,
            advertiserId: advertiser === null || advertiser === void 0 ? void 0 : advertiser.id
        });
        return;
    }
    let req;
    const initialRequest = {
        amount: invoicePricingData.totalAmount,
        customerEmail,
        invoiceId: invoice.id,
        paymentMethod,
        isSavedPaymentMethod: true,
        orderVersion: isPayOrderInvoiceData(payInvoiceData)
            ? payInvoiceData.orderVersion
            : undefined
    };
    if (invoicePaymentMethod === 'saved-ach') {
        req = Object.assign(Object.assign({}, initialRequest), { userId: user === null || user === void 0 ? void 0 : user.id, stripeBankAccountSourceId: selectedSavedMethodId });
    }
    else {
        req = Object.assign(Object.assign({}, initialRequest), { paymentMethodId: selectedSavedMethodId });
    }
    const result = yield apiPost('payments/pay-stripe-invoice', req);
    if (!result.success) {
        throw result.error;
    }
});
