import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { ColumnSelect, enumKeyToSelectInput } from 'lib/components/ColumnSelect';
import { TextField } from 'lib/components/TextField';
import { RateType, State } from 'lib/enums';
import { isRateShared } from 'lib/utils/rates';
import { SharedResourceAlert } from 'routes/settings/publisher/sharing/SharedResourceAlert';
import { valueIsNumber } from 'lib/pricing';
import { CardGridLayout, GridInput } from 'lib/components/Card/Grid';
import CurrencyTextField from 'components/CurrencyTextField/CurrencyTextField';
import { isNoticeRate } from 'lib/types/rates';
import { hideRatePricingDetailsWhenEditing, showLinesPerInchRatio } from '../../../ratesUtils';
import DecimalTextField from '../../../DecimalTextField';
import { rateSupportsDisplayAdsForPublisher, getDefaultBehaviorOptions, getSupportedAdTypes, rateIsFlat, getRateAmountLabel } from '../rateUpdateFormUtils';
import { RunBasedRates } from './RunBasedRates';
/**
 * Determines the value for the "How do you want to charge per run?" select
 * @param {AdRate} rate rate to get the select value for
 * @returns {string} select value
 */
const getPerRunChargeSelectValue = (rate) => {
    if (rate.rateType === RateType.flat.value)
        return 'flat';
    if (rate.rateType === RateType.per_run.value)
        return 'per_run';
    if (rate.runBased)
        return 'run-based';
    return 'non-run-based';
};
/**
 * We collapse "per run" and "flat" rates in the UI into one option
 * @param {AdRate} rate rate to get the display value for
 * @returns {number} the rate type value to display in the UI
 */
const getDisplayRateTypeValueFromRateData = (rate) => {
    if (rate.rateType === RateType.per_run.value)
        return RateType.flat.value;
    return rate.rateType;
};
/**
 * Show different options for rate types to publishers based on which state they are in
 */
const getRateTypeOptionsForPublisher = (publisher) => {
    const rateOptions = [
        enumKeyToSelectInput(RateType.flat),
        enumKeyToSelectInput(RateType.folio),
        enumKeyToSelectInput(RateType.word_count),
        enumKeyToSelectInput(RateType.line),
        enumKeyToSelectInput(RateType.column_inch)
    ];
    if (publisher.data().state === State.oklahoma.value) {
        rateOptions.push(enumKeyToSelectInput(RateType.oklahoma));
    }
    if (publisher.data().state === State.iowa.value) {
        rateOptions.push(enumKeyToSelectInput(RateType.iowa_form));
    }
    return rateOptions;
};
export default function BasicPropertiesCard({ activeOrganization, onUpdateRateData, setDefaultBehavior, updatedRateData, defaultBehavior, rateRef }) {
    var _a, _b, _c, _d, _e;
    const isEditingNoticeRate = isNoticeRate(updatedRateData);
    const [allowImages, setAllowImages] = useState(false);
    const [excludeImagesFromPricingForLinerRate, setExcludeImagesFromPricingForLinerRate] = useState(false);
    useEffect(() => {
        var _a, _b;
        // update default allowImages value when the rate type changes
        setAllowImages(isEditingNoticeRate &&
            (valueIsNumber((_a = updatedRateData.images) === null || _a === void 0 ? void 0 : _a.linesPerInch) ||
                updatedRateData.rateType === RateType.iowa_form.value));
        setExcludeImagesFromPricingForLinerRate(isEditingNoticeRate && ((_b = updatedRateData.images) === null || _b === void 0 ? void 0 : _b.linesPerInch) === 0);
    }, [updatedRateData.rateType]);
    const supportedAdTypes = getSupportedAdTypes(rateRef === null || rateRef === void 0 ? void 0 : rateRef.id, activeOrganization, updatedRateData);
    const defaultBehaviorOptions = getDefaultBehaviorOptions(activeOrganization, updatedRateData, rateRef === null || rateRef === void 0 ? void 0 : rateRef.id);
    const showPricingDetails = !hideRatePricingDetailsWhenEditing(updatedRateData);
    const isFlatRate = rateIsFlat(updatedRateData);
    const showRunBased = !isFlatRate && !hideRatePricingDetailsWhenEditing(updatedRateData);
    // TODO: In the future we will probably want to show this for all liner rates
    const isLineRateAndOrgsAllowsImagesInLiners = updatedRateData.rateType === RateType.line.value &&
        !!activeOrganization.data().allowImagesInLiners;
    const showAllowImages = updatedRateData.rateType === RateType.oklahoma.value ||
        isLineRateAndOrgsAllowsImagesInLiners;
    const showExcludeImagesFromPricing = 
    /** We should show the option to exclude images from pricing for per-line and per-column inch rates */
    (isLineRateAndOrgsAllowsImagesInLiners && allowImages) ||
        updatedRateData.rateType === RateType.column_inch.value;
    const showLinesPerInch = showLinesPerInchRatio(updatedRateData) && showAllowImages && allowImages;
    const showRoundOff = updatedRateData.rateType === RateType.column_inch.value;
    return (_jsxs(CardGridLayout, Object.assign({ header: {
            title: 'Basic Properties',
            description: 'Configure basic properties for this rate.'
        } }, { children: [isRateShared(updatedRateData) && (_jsx(GridInput, Object.assign({ fullWidth: true }, { children: _jsx(SharedResourceAlert, { resourceNoun: "rate", numOrganizations: ((_b = (_a = updatedRateData.publisherOrganizations) === null || _a === void 0 ? void 0 : _a.length) !== null && _b !== void 0 ? _b : 0) + 1 }) }))), _jsx(GridInput, { children: _jsx(TextField, { id: "rate-name", labelText: "Rate name", required: true, value: updatedRateData.description, onChange: newValue => onUpdateRateData(Object.assign(Object.assign({}, updatedRateData), { description: newValue })) }) }), _jsx(GridInput, { children: _jsx(ColumnSelect, { id: "rate-type", labelText: "How do you calculate this rate?", options: getRateTypeOptionsForPublisher(activeOrganization), onChange: newValue => {
                        const newRateType = RateType.by_value(parseInt(newValue, 10));
                        if (!newRateType)
                            return;
                        onUpdateRateData(Object.assign(Object.assign(Object.assign(Object.assign({}, updatedRateData), { rateType: newRateType.value }), (newRateType === RateType.flat ? { runBased: false } : {})), (newRateType !== RateType.column_inch
                            ? { roundOff: null }
                            : {})));
                    }, value: getDisplayRateTypeValueFromRateData(updatedRateData).toString() }) }), isEditingNoticeRate && (_jsxs(_Fragment, { children: [_jsx(GridInput, { children: _jsx(ColumnSelect, { id: "display-ad-support", labelText: "Does this rate support display notices?", options: supportedAdTypes, onChange: newValue => {
                                onUpdateRateData(Object.assign(Object.assign({}, updatedRateData), { supportsDisplay: newValue === 'true' }));
                            }, value: rateSupportsDisplayAdsForPublisher(rateRef === null || rateRef === void 0 ? void 0 : rateRef.id, activeOrganization, updatedRateData).toString() }) }), _jsx(GridInput, { children: _jsx(ColumnSelect, { id: "rate-default-behavior-select", labelText: "Is this the newspaper's default rate?", options: defaultBehaviorOptions, onChange: newValue => setDefaultBehavior(newValue), value: defaultBehavior }) })] })), showPricingDetails && (_jsxs(_Fragment, { children: [_jsx(GridInput, { children: _jsx(CurrencyTextField, { id: "minimum", labelText: "Minimum charge", initialValue: updatedRateData.minimum, onChange: newValue => onUpdateRateData(Object.assign(Object.assign({}, updatedRateData), { minimum: newValue })) }) }), isFlatRate && (_jsx(GridInput, { children: _jsx(ColumnSelect, { id: "flat-rate-run-based", labelText: "Charge the flat rate per run?", options: [
                                {
                                    value: 'flat',
                                    label: 'No — charge the flat rate once'
                                },
                                {
                                    value: 'per_run',
                                    label: 'Yes — charge the flat rate per run'
                                }
                            ], onChange: newValue => onUpdateRateData(Object.assign(Object.assign({}, updatedRateData), { rateType: newValue === 'per_run'
                                    ? RateType.per_run.value
                                    : RateType.flat.value, additionalRates: null })), value: getPerRunChargeSelectValue(updatedRateData) }) })), showRunBased && (_jsx(GridInput, { children: _jsx(ColumnSelect, { id: "run-based", labelText: "Does the price vary per run?", options: [
                                {
                                    value: 'non-run-based',
                                    label: 'No — charge the same price per run'
                                },
                                {
                                    value: 'run-based',
                                    label: 'Yes — charge different prices per run'
                                }
                            ], onChange: newValue => onUpdateRateData(Object.assign(Object.assign({}, updatedRateData), { runBased: newValue === 'run-based', additionalRates: null })), value: getPerRunChargeSelectValue(updatedRateData) }) })), !updatedRateData.runBased && (
                    // fullWidth is conditional in order for components to fit
                    // as closely to each other as possible within the grid
                    _jsx(GridInput, Object.assign({ fullWidth: showRunBased && !showRoundOff }, { children: _jsx(CurrencyTextField, { id: "fixed-rate-rate-input", labelText: getRateAmountLabel(updatedRateData.product, updatedRateData.rateType), initialValue: updatedRateData.rate_0, onChange: newValue => onUpdateRateData(Object.assign(Object.assign({}, updatedRateData), { rate_0: newValue, rate_1: newValue, rate_2: newValue })) }) }))), updatedRateData.runBased && (_jsx(RunBasedRates, { rateType: updatedRateData.rateType, value: updatedRateData, onChange: updates => onUpdateRateData(Object.assign(Object.assign({}, updatedRateData), updates)) }))] })), showRoundOff && (_jsx(GridInput, { children: _jsx(TextField, { id: "rate-round-off", labelText: _jsxs(_Fragment, { children: ["Round off (to the nearest ", _jsx("i", { children: "n" }), " column inch)"] }), value: (_d = (_c = updatedRateData.roundOff) === null || _c === void 0 ? void 0 : _c.toString()) !== null && _d !== void 0 ? _d : undefined, type: "number", min: 0, step: 0.01, onChange: newValue => {
                        onUpdateRateData(Object.assign(Object.assign({}, updatedRateData), { roundOff: Number(newValue) }));
                    } }) })), isEditingNoticeRate && showAllowImages && (_jsx(GridInput, { children: _jsx(ColumnSelect, { id: "allow-images", labelText: "Do you want to allow images?", options: [
                        { label: 'Yes, allow images in liner ads', value: 'true' },
                        {
                            label: 'No, do not allow images in liner ads',
                            value: 'false'
                        }
                    ], onChange: newValue => {
                        const enabled = newValue === 'true';
                        if (!enabled) {
                            onUpdateRateData(Object.assign(Object.assign({}, updatedRateData), { images: Object.assign(Object.assign({}, updatedRateData.images), { linesPerInch: 0 }) }));
                        }
                        setAllowImages(enabled);
                    }, value: allowImages ? 'true' : 'false' }) })), isEditingNoticeRate && showExcludeImagesFromPricing && (_jsx(GridInput, { children: _jsx(ColumnSelect, { id: "exclude-images-from-pricing", labelText: "Should pricing include images in the notice?", options: [
                        {
                            label: 'Yes, images should count toward pricing',
                            value: 'true'
                        },
                        {
                            label: 'No, images should not count toward pricing',
                            value: 'false'
                        }
                    ], onChange: newValue => {
                        var _a, _b, _c;
                        const excludeImages = newValue === 'false';
                        // default to 5 lines per inch
                        let linesPerInchValue = 5;
                        if (excludeImages) {
                            linesPerInchValue = 0;
                        }
                        else if (((_a = updatedRateData.images) === null || _a === void 0 ? void 0 : _a.linesPerInch) &&
                            ((_b = updatedRateData.images) === null || _b === void 0 ? void 0 : _b.linesPerInch) > 0) {
                            // use existing linesPerInch if available and if including images
                            linesPerInchValue = (_c = updatedRateData.images) === null || _c === void 0 ? void 0 : _c.linesPerInch;
                        }
                        const updatedImagesData = Object.assign(Object.assign({}, updatedRateData.images), { linesPerInch: linesPerInchValue });
                        onUpdateRateData(Object.assign(Object.assign({}, updatedRateData), { images: Object.assign({}, updatedImagesData) }));
                        setExcludeImagesFromPricingForLinerRate(excludeImages);
                    }, value: excludeImagesFromPricingForLinerRate ? 'false' : 'true', disabled: !(allowImages ||
                        updatedRateData.rateType === RateType.column_inch.value) }) })), isEditingNoticeRate && showLinesPerInch && (_jsx(GridInput, { children: _jsx(DecimalTextField, { id: "image-lines-inch-conversion", labelText: 'What is the ratio of lines/inch for images?', initialValue: (_e = updatedRateData.images) === null || _e === void 0 ? void 0 : _e.linesPerInch, decimalDigits: 2, onChange: newValue => {
                        onUpdateRateData(Object.assign(Object.assign({}, updatedRateData), { images: Object.assign(Object.assign({}, updatedRateData.images), { linesPerInch: newValue }) }));
                    }, disabled: !allowImages || excludeImagesFromPricingForLinerRate, required: !excludeImagesFromPricingForLinerRate && showLinesPerInch }) }))] })));
}
