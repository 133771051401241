import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from 'react';
import { FunnelIcon } from '@heroicons/react/24/outline';
import { ColumnButton } from '../ColumnButton';
export default function TableFilterDialog({ applyFilterChanges, filterHasChanges, numFiltersActive, resetFilters, onDialogOpen, renderDialog, id }) {
    // whether or not we are showing the dropdown dialog
    const [open, setOpen] = useState(false);
    const toggleOpen = () => {
        if (!open && onDialogOpen) {
            onDialogOpen();
        }
        setOpen(!open);
    };
    return (_jsxs("div", Object.assign({ className: "relative inline-block text-left mr-4", id: id }, { children: [_jsx(ColumnButton, { buttonText: _jsxs("div", Object.assign({ className: "flex py-1 ml-2" }, { children: [_jsx("span", Object.assign({ className: "mx-1" }, { children: "Filter" })), numFiltersActive > 0 && (_jsxs("div", Object.assign({ className: "text-xs text-column-primary-500 bg-column-primary-100 rounded-full px-2 py-px mr-1 whitespace-pre" }, { children: [_jsxs("span", { children: [numFiltersActive, " active"] }), _jsx("span", Object.assign({ className: "ml-2 cursor-pointer hover:text-column-primary-700", onClick: e => {
                                        e.stopPropagation();
                                        resetFilters();
                                    } }, { children: "\u2715" }))] })))] })), endIcon: _jsx(FunnelIcon, { className: "w-5 h-5 mr-2" }), tertiary: true, onClick: toggleOpen, size: "sm", type: "button" }), open && (_jsxs("div", Object.assign({ className: "absolute flex flex-col gap-3 right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg py-5 px-4 w-100 border border-column-gray-100" }, { children: [renderDialog(), _jsxs("div", Object.assign({ className: "flex flex-row justify-between items-center mt-3" }, { children: [_jsx(ColumnButton, { buttonText: "Reset", onClick: () => {
                                    resetFilters();
                                    setOpen(false);
                                }, type: "button" }), _jsx(ColumnButton, { buttonText: "Apply", primary: true, disabled: !filterHasChanges, onClick: () => {
                                    applyFilterChanges();
                                    setOpen(false);
                                }, type: "button" })] }))] })))] })));
}
