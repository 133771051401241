var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { DEFAULT_AFFIDAVIT_URL_UPDATED } from 'lib/affidavits';
import { cdnIfy } from 'lib/helpers';
import { getAffidavitTemplateDefaultStatus } from '../statusHelpers';
import { loadAffidavitTemplateHTML } from '../htmlHelpers';
export const extractFormData = (activeOrganization, affidavitTemplateRef, affidavitTemplate) => __awaiter(void 0, void 0, void 0, function* () {
    const isNewTemplate = !affidavitTemplateRef;
    const isV2 = affidavitTemplate.version === 'v2024-03-25';
    /**
     * If this is a new template, or a legacy template being converted to v2, we
     * need to pull the default affidavit template HTML.
     *
     * Otherwise, we can use what's currently saved on the template.
     */
    const htmlUrl = isNewTemplate || !isV2
        ? DEFAULT_AFFIDAVIT_URL_UPDATED
        : cdnIfy(affidavitTemplate.storagePath);
    const affidavitTemplateHTML = yield loadAffidavitTemplateHTML(htmlUrl);
    return {
        affidavitTemplateHTML,
        defaultStatus: getAffidavitTemplateDefaultStatus(activeOrganization, affidavitTemplateRef),
        storagePath: affidavitTemplate.storagePath,
        publisher: affidavitTemplate.publisher,
        name: affidavitTemplate.name,
        isColumnManaged: affidavitTemplate.isColumnManaged,
        logoSource: isV2 ? affidavitTemplate.logoSource : 'column',
        logoHeightInInches: isV2 ? affidavitTemplate.logoHeightInInches : undefined,
        blockWidthInInches: isV2 ? affidavitTemplate.blockWidthInInches : undefined,
        fontSize: isV2 ? affidavitTemplate.fontSize : undefined
    };
});
