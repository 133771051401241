const LEE_ESCALATION_PHOENIX = 'phoenix.legals@lee.net';
const LEE_ESCALATION_MACTIVE = 'mactive.legals@lee.net';
const SOUTHEAST_ESCALATION_MACTIVE = 'SoutheastMactiveEscalations@lee.net';
const JOY = 'jmonopoli@rsnva.com';
const PAGINATION = 'pagination@column.us';
const PAUL = 'paul@column.us';
const TERESA = 'teresa@column.us';
export const TRELLO_INTEGRATION = 'jasonpupek1+a1taqjamrwyvrcirb0xq@boards.trello.com';
export const PAGINATION_INTEGRATION_EMAILS = [PAGINATION, TRELLO_INTEGRATION];
export const TEST_UPLOAD_DESTINATION = {
    label: 'Column Testing',
    newspaperCode: 'COLX',
    liaisonContacts: [PAGINATION, PAUL, TERESA],
    organizationId: 'fbhbFfBd6xl3LlTY35lz' // "Sarah's Test Paper"
};
export const LEE_UPLOAD_DESTINATIONS = [
    {
        label: 'Times Democrat',
        newspaperCode: 'ONGT',
        liaisonContacts: [PAGINATION, LEE_ESCALATION_PHOENIX, JOY],
        organizationId: '1Pe8dRZD5sZpuzCymn3h'
    },
    {
        label: 'Pantagraph',
        newspaperCode: 'BLMP',
        liaisonContacts: [PAGINATION, LEE_ESCALATION_PHOENIX, JOY],
        organizationId: 'HPeL6NJPpc5TxId2IYIP'
    },
    {
        label: 'Woodford',
        newspaperCode: 'BLMW',
        liaisonContacts: [PAGINATION, LEE_ESCALATION_PHOENIX, JOY],
        organizationId: '1sbbcSPBYwD5X6EVE0OB'
    },
    {
        label: 'Herald & Review',
        newspaperCode: 'DECH',
        liaisonContacts: [PAGINATION, LEE_ESCALATION_PHOENIX, JOY],
        organizationId: 'keQkIB7vlHi00vhCncVY'
    },
    {
        label: 'Journal Gazette and Times-Courier',
        newspaperCode: 'MATJ',
        liaisonContacts: [PAGINATION, LEE_ESCALATION_PHOENIX, JOY],
        organizationId: 's3wpzM2RIHTNrmpzneTq'
    },
    {
        label: 'Hartsville Messenger',
        newspaperCode: 'SCAM',
        liaisonContacts: [PAGINATION, SOUTHEAST_ESCALATION_MACTIVE],
        organizationId: '6RMAJ1T0AV0IBfreq1sO'
    },
    {
        label: 'Marion Star',
        newspaperCode: 'SCAS',
        liaisonContacts: [PAGINATION, SOUTHEAST_ESCALATION_MACTIVE],
        organizationId: 'ZKVtUGN2hz7A5sWHVMKF'
    },
    {
        label: 'Florence Morning News',
        newspaperCode: 'SCAF',
        liaisonContacts: [PAGINATION, SOUTHEAST_ESCALATION_MACTIVE],
        organizationId: 'aLIOF7qmvXFN0QF1HzYn'
    },
    {
        label: 'Press of Atlantic City',
        newspaperCode: 'PACP',
        liaisonContacts: [PAGINATION, SOUTHEAST_ESCALATION_MACTIVE],
        organizationId: 'PF6Oj7CkmWn4Ubpi6hvx'
    },
    {
        label: 'Dothan Eagle',
        newspaperCode: 'DOTE',
        liaisonContacts: [PAGINATION, LEE_ESCALATION_MACTIVE],
        organizationId: 'dHWAgbHhr8y0UOVCskpV'
    },
    {
        label: 'Enterprise Ledger',
        newspaperCode: 'DOTL',
        liaisonContacts: [PAGINATION, SOUTHEAST_ESCALATION_MACTIVE],
        organizationId: 'MCCLddMb5yUOw68nBFEJ'
    },
    {
        label: 'Eufaula Tribune',
        newspaperCode: 'DOTT',
        liaisonContacts: [PAGINATION, SOUTHEAST_ESCALATION_MACTIVE],
        organizationId: 'QsVXFzxpul1G5QGRuUI9'
    },
    {
        label: 'Opelika Auburn News',
        newspaperCode: 'OANO',
        liaisonContacts: [PAGINATION, SOUTHEAST_ESCALATION_MACTIVE],
        organizationId: 'dQjz1hqNNEgeV8evafZb'
    },
    {
        label: 'Bristol Herald Courier',
        newspaperCode: 'BHCB',
        liaisonContacts: [PAGINATION, SOUTHEAST_ESCALATION_MACTIVE],
        organizationId: 'cfPsOFiAW5qnpjrkL754'
    },
    {
        label: 'Wytheville Enterprise',
        newspaperCode: 'SWVW',
        liaisonContacts: [PAGINATION, SOUTHEAST_ESCALATION_MACTIVE],
        organizationId: 'AHrz05Q6m0CjzZlGrq7O'
    },
    {
        label: 'Smyth County News & Messenger',
        newspaperCode: 'SWVS',
        liaisonContacts: [PAGINATION, SOUTHEAST_ESCALATION_MACTIVE],
        organizationId: 'XR0oaU1rK9hCE9OB99hG'
    },
    {
        label: 'The Floyd Press',
        newspaperCode: 'SWVF',
        liaisonContacts: [PAGINATION, SOUTHEAST_ESCALATION_MACTIVE],
        organizationId: 'dtTG0xt67M0deRbKQ3nE'
    },
    {
        label: 'Richlands News Press',
        newspaperCode: 'SWVR',
        liaisonContacts: [PAGINATION, SOUTHEAST_ESCALATION_MACTIVE],
        organizationId: 'xzae015jchEKg3nyP7Mw'
    },
    {
        label: 'Roanoke Times',
        newspaperCode: 'ROAR',
        liaisonContacts: [PAGINATION, SOUTHEAST_ESCALATION_MACTIVE],
        organizationId: 'LIVF7f0edsUqFtUlKeuF'
    },
    {
        label: 'Franklin News-Post',
        newspaperCode: 'FNPF',
        liaisonContacts: [PAGINATION, SOUTHEAST_ESCALATION_MACTIVE],
        organizationId: 'xUnG1hcY3INQAJ2eZIZm'
    },
    {
        label: 'Fredericksburg Free Lance-Star',
        newspaperCode: 'FLSF',
        liaisonContacts: [PAGINATION, SOUTHEAST_ESCALATION_MACTIVE],
        organizationId: 'xPHHBfytSGjVHjEEz7p0'
    },
    {
        label: 'Culpeper Star Exponent',
        newspaperCode: 'CSEC',
        liaisonContacts: [PAGINATION, SOUTHEAST_ESCALATION_MACTIVE],
        organizationId: 'BdH9Ws1BxPgsmXD0GsI1'
    },
    {
        label: 'Amherst New Era Progress',
        newspaperCode: 'LNAA',
        liaisonContacts: [PAGINATION, SOUTHEAST_ESCALATION_MACTIVE],
        organizationId: 'wuC74H1SSHWVErVz4yWW'
    },
    {
        label: 'Danville Register & Bee',
        newspaperCode: 'DAND',
        liaisonContacts: [PAGINATION, SOUTHEAST_ESCALATION_MACTIVE],
        organizationId: 'YVyxZiWwliRvs34WmT4s'
    },
    {
        label: 'Lynchburg News & Advance',
        newspaperCode: 'LNAN',
        liaisonContacts: [PAGINATION, SOUTHEAST_ESCALATION_MACTIVE],
        organizationId: 'KEziG5VXuJCarfPZKQLT'
    },
    {
        label: 'Nelson County Times',
        newspaperCode: 'LNAC',
        liaisonContacts: [PAGINATION, SOUTHEAST_ESCALATION_MACTIVE],
        organizationId: '6zy06zRc71q3rAz7CRJS'
    },
    {
        label: 'Martinsville Bulletin',
        newspaperCode: 'MVBM',
        liaisonContacts: [PAGINATION, SOUTHEAST_ESCALATION_MACTIVE],
        organizationId: 'GHTiQc6rLsefcbpNqfIx'
    },
    {
        label: 'Charlottesville Daily Progress',
        newspaperCode: 'CDPP',
        liaisonContacts: [PAGINATION, SOUTHEAST_ESCALATION_MACTIVE],
        organizationId: 'yUU7SoHjCSXeoNMqBs9a'
    },
    {
        label: 'Waynesboro News Virginian',
        newspaperCode: 'WNVN',
        liaisonContacts: [PAGINATION, SOUTHEAST_ESCALATION_MACTIVE],
        organizationId: '65TB5LcJfIbRPXNOl0ji'
    },
    {
        label: 'Greene County Record',
        newspaperCode: 'CDPG',
        liaisonContacts: [PAGINATION, SOUTHEAST_ESCALATION_MACTIVE],
        organizationId: 'erQiMl2XdfaSSmycjz3j'
    },
    {
        label: 'Madison County Eagle',
        newspaperCode: 'CDPM',
        liaisonContacts: [PAGINATION, SOUTHEAST_ESCALATION_MACTIVE],
        organizationId: 'zBGfiNKfFrjcS6dGG9qr'
    },
    {
        label: 'Orange County Review',
        newspaperCode: 'CDPO',
        liaisonContacts: [PAGINATION, SOUTHEAST_ESCALATION_MACTIVE],
        organizationId: 'EXrpgxUxFONXJfYoh2i2'
    },
    {
        label: 'Rural Virginian',
        newspaperCode: 'CDPR',
        liaisonContacts: [PAGINATION, LEE_ESCALATION_MACTIVE],
        organizationId: 'z65hdtnUrmFiq4xeeLOJ'
    },
    {
        label: 'Richmond Times Dispatch',
        newspaperCode: 'RTDR',
        liaisonContacts: [PAGINATION, SOUTHEAST_ESCALATION_MACTIVE],
        organizationId: 'oEPFGiI1EOee385RJP2i'
    },
    {
        label: 'Mechanicsville Ashland Local',
        newspaperCode: 'RTDM',
        liaisonContacts: [PAGINATION, SOUTHEAST_ESCALATION_MACTIVE],
        organizationId: 'KzLpEnSMwS954WhZ7twA'
    },
    {
        label: 'Powhatan Today',
        newspaperCode: 'RTDP',
        liaisonContacts: [PAGINATION, SOUTHEAST_ESCALATION_MACTIVE],
        organizationId: 'YuQZPBc0cnnFmOY6QmZs'
    },
    {
        label: 'Goochland Gazette',
        newspaperCode: 'RTDG',
        liaisonContacts: [PAGINATION, SOUTHEAST_ESCALATION_MACTIVE],
        organizationId: 'LjPeSt8Wk5otrwVhLKno'
    },
    {
        label: 'The Daily Nonpareil',
        newspaperCode: 'CBND',
        liaisonContacts: [PAGINATION, LEE_ESCALATION_MACTIVE],
        organizationId: 'LBBgBhxQXosOjefXTPys'
    },
    {
        label: 'Bulletin-Review',
        newspaperCode: 'DBRB',
        liaisonContacts: [PAGINATION, LEE_ESCALATION_MACTIVE],
        organizationId: 'pq1HgfhaPvmhpWpLpcHK'
    },
    {
        label: 'Southwest Iowa Herald',
        newspaperCode: 'SVNI',
        liaisonContacts: [PAGINATION, LEE_ESCALATION_MACTIVE],
        organizationId: 'TWWebdDPgoVwfUV1cNyu'
    },
    {
        label: 'Sarpy County Times',
        newspaperCode: 'CBNS',
        liaisonContacts: [PAGINATION, LEE_ESCALATION_MACTIVE],
        organizationId: 'vRqeKWUhxYVgY3iKgjrg'
    },
    {
        label: 'North Platte Telegraph',
        newspaperCode: 'NPTN',
        liaisonContacts: [PAGINATION, LEE_ESCALATION_MACTIVE],
        organizationId: 'L8gQVS33jkkxRxHFt3x0'
    },
    {
        label: 'Scottsbluff Star-Herald',
        newspaperCode: 'SSHS',
        liaisonContacts: [PAGINATION, LEE_ESCALATION_MACTIVE],
        organizationId: 'jOhEP5fq94qqxGzuY4W9'
    },
    {
        label: 'Gering Courier',
        newspaperCode: 'SSHG',
        liaisonContacts: [PAGINATION, LEE_ESCALATION_MACTIVE],
        organizationId: 'CbL1jXL0CTssKO3ZDuAh'
    },
    {
        label: 'Wahoo Newspaper',
        newspaperCode: 'WAHW',
        liaisonContacts: [PAGINATION, LEE_ESCALATION_MACTIVE],
        organizationId: 'tHlmcVh95YdfWlOoi285'
    },
    {
        label: 'Waverly News',
        newspaperCode: 'WAHN',
        liaisonContacts: [PAGINATION, LEE_ESCALATION_MACTIVE],
        organizationId: '86aHe6SJKQrrPLBYXZQB'
    },
    {
        label: 'Ashland Gazette',
        newspaperCode: 'WAHA',
        liaisonContacts: [PAGINATION, LEE_ESCALATION_MACTIVE],
        organizationId: 'xBG3rA6VtmNB1lPwcCBo'
    },
    {
        label: 'Hemingford Ledger',
        newspaperCode: 'SSHH',
        liaisonContacts: [PAGINATION, LEE_ESCALATION_MACTIVE],
        organizationId: '4jLtjSIMcz5e2E8MyW6P'
    },
    {
        label: 'Greensboro News and Record',
        newspaperCode: 'GNRN',
        liaisonContacts: [PAGINATION, SOUTHEAST_ESCALATION_MACTIVE],
        organizationId: '7uRcB05utORKAPy56YWk'
    },
    {
        label: 'Rockingham Now',
        newspaperCode: 'GNRR',
        liaisonContacts: [PAGINATION, SOUTHEAST_ESCALATION_MACTIVE],
        organizationId: 'fNRc9Bnsmqmg2XK5Nbny'
    },
    {
        label: 'Hickory Daily Recod',
        newspaperCode: 'HDRR',
        liaisonContacts: [PAGINATION, SOUTHEAST_ESCALATION_MACTIVE],
        organizationId: 'ZsYymTcPolQY2Uuo7AwU'
    },
    {
        label: 'Concord Independent Tribune',
        newspaperCode: 'CITI',
        liaisonContacts: [PAGINATION, SOUTHEAST_ESCALATION_MACTIVE],
        organizationId: 'wqWtyMGlvw4CMEm0CYOV'
    },
    {
        label: 'McDowell News',
        newspaperCode: 'MMNN',
        liaisonContacts: [PAGINATION, SOUTHEAST_ESCALATION_MACTIVE],
        organizationId: 'm74QygA6Ce9i5Vyt0MFX'
    },
    {
        label: 'Winston-Salem Journal',
        newspaperCode: 'WSJJ',
        liaisonContacts: [PAGINATION, SOUTHEAST_ESCALATION_MACTIVE],
        organizationId: 'iVbvd0Ot1FrnQC4qCjfg'
    },
    {
        label: 'Mooresville Tribune',
        newspaperCode: 'MOTT',
        liaisonContacts: [PAGINATION, LEE_ESCALATION_MACTIVE],
        organizationId: 'Omkr2aDgZrIjAWAK085q'
    },
    {
        label: 'Morganton News Herald',
        newspaperCode: 'MNHH',
        liaisonContacts: [PAGINATION, SOUTHEAST_ESCALATION_MACTIVE],
        organizationId: 'vGlQaGMKPP0kVFEbP4Rl'
    },
    {
        label: 'Statesville Record and Landmark',
        newspaperCode: 'STAL',
        liaisonContacts: [PAGINATION, SOUTHEAST_ESCALATION_MACTIVE],
        organizationId: '91Gbsyi6khezIkL2cslb'
    },
    {
        label: 'Lincoln Journal Star',
        newspaperCode: 'LJSJ',
        liaisonContacts: [PAGINATION, LEE_ESCALATION_PHOENIX],
        organizationId: 'uIDVIq1Y2BzR1agKjVlj'
    },
    {
        label: 'Beatrice Daily Sun',
        newspaperCode: 'BDSD',
        liaisonContacts: [PAGINATION, LEE_ESCALATION_PHOENIX],
        organizationId: 'mcG0FjYH3aMDDfugWW4x'
    },
    {
        label: 'Fremont Tribune',
        newspaperCode: 'FRET',
        liaisonContacts: [PAGINATION, LEE_ESCALATION_PHOENIX],
        organizationId: 'srfWOTaOIsyO3qmXdOlu'
    },
    {
        label: 'Billings Gazette',
        newspaperCode: 'BILG',
        liaisonContacts: [PAGINATION, LEE_ESCALATION_PHOENIX],
        organizationId: 'vjm8mlPKDi6WoXKIGuCW'
    },
    {
        label: 'Independent Record',
        newspaperCode: 'HELI',
        liaisonContacts: [PAGINATION, LEE_ESCALATION_PHOENIX],
        organizationId: 'K510x9DSfxM8nJcse4Tq'
    },
    {
        label: 'Montana Standard',
        newspaperCode: 'BUTS',
        liaisonContacts: [PAGINATION, LEE_ESCALATION_PHOENIX],
        organizationId: 'WaP8xnz7uXpnQv9QICb7'
    },
    {
        label: 'Missoulian',
        newspaperCode: 'MISM',
        liaisonContacts: [PAGINATION, LEE_ESCALATION_PHOENIX],
        organizationId: 'xBgxeM9xLNMPLYBVmV5W'
    },
    {
        label: 'Ravalli Republic',
        newspaperCode: 'MISR',
        liaisonContacts: [PAGINATION, LEE_ESCALATION_PHOENIX],
        organizationId: 'oFjtfMJjmRQVsT0cdMPI'
    },
    {
        label: 'Quad-City Times',
        newspaperCode: 'QCTT',
        liaisonContacts: [PAGINATION, LEE_ESCALATION_PHOENIX],
        organizationId: '8dk7WZYxbGKi7RJQsRr2'
    },
    {
        label: 'Muscatine Journal',
        newspaperCode: 'QCTJ',
        liaisonContacts: [PAGINATION, LEE_ESCALATION_PHOENIX],
        organizationId: 'HASpJO0zQpuSjfBv47sF'
    },
    {
        label: 'Dispatch Argus',
        newspaperCode: 'MDAD',
        liaisonContacts: [PAGINATION, LEE_ESCALATION_PHOENIX],
        organizationId: 'MAvAvxNAEgkjmzIgdgXQ'
    },
    {
        label: 'Sioux City Journal',
        newspaperCode: 'SCJJ',
        liaisonContacts: [PAGINATION, LEE_ESCALATION_PHOENIX],
        organizationId: '6ecRmpZPmAJSjvTTNVlQ'
    },
    {
        label: 'Courier',
        newspaperCode: 'WOOC',
        liaisonContacts: [PAGINATION, LEE_ESCALATION_PHOENIX],
        organizationId: 'xzdFkPxk10lWXoYJHvjy'
    },
    {
        label: 'Globe Gazette',
        newspaperCode: 'MSCG',
        liaisonContacts: [PAGINATION, LEE_ESCALATION_PHOENIX],
        organizationId: '4m4efBcllBpnlr12eUNB'
    },
    {
        label: 'Michell County Press-News',
        newspaperCode: 'MSCM',
        liaisonContacts: [PAGINATION, LEE_ESCALATION_PHOENIX],
        organizationId: 'qp49ScofwxaejUswFlS4'
    },
    {
        label: 'Summit-Tribune',
        newspaperCode: 'MSCS',
        liaisonContacts: [PAGINATION, LEE_ESCALATION_PHOENIX],
        organizationId: '8DC6dt4MzTwMosxPEBnH'
    },
    {
        label: 'Gazette Times - Democrat Herald',
        newspaperCode: 'MVNG',
        liaisonContacts: [PAGINATION, LEE_ESCALATION_PHOENIX],
        organizationId: '4hf5AHVDlA9y2kFlWu2O'
    },
    {
        label: 'Napa Valley Register',
        newspaperCode: 'NVRR',
        liaisonContacts: [PAGINATION, LEE_ESCALATION_PHOENIX],
        organizationId: 'OKlgzKJ8FrgJavk2KBJi'
    },
    {
        label: 'St. Helena Star',
        newspaperCode: 'NVRS',
        liaisonContacts: [PAGINATION, LEE_ESCALATION_PHOENIX],
        organizationId: 'xSmu6vkzFnWMTbZ8Saxg'
    },
    {
        label: 'Daily News',
        newspaperCode: 'LNGN',
        liaisonContacts: [PAGINATION, LEE_ESCALATION_PHOENIX],
        organizationId: '1sM404s4cRBdLDm0BCSK'
    },
    {
        label: 'Bismarck Tribune',
        newspaperCode: 'BIST',
        liaisonContacts: [PAGINATION, LEE_ESCALATION_PHOENIX],
        organizationId: 'hRu6GeiIpiV0OwxisI3X'
    },
    {
        label: 'Mandan News',
        newspaperCode: 'BISM',
        liaisonContacts: [PAGINATION, LEE_ESCALATION_PHOENIX],
        organizationId: 'ewrEJuZJjyzRjCrYVvVh'
    },
    {
        label: 'Rapid City Journal',
        newspaperCode: 'RCJR',
        liaisonContacts: [PAGINATION, LEE_ESCALATION_PHOENIX],
        organizationId: 'VIrGdYW77kS55jiHgJWL'
    },
    {
        label: 'Chadron Record',
        newspaperCode: 'RCJC',
        liaisonContacts: [PAGINATION, LEE_ESCALATION_PHOENIX],
        organizationId: '4Zg29lpfR5yUzcWSrnCt'
    },
    {
        label: 'Casper Star-Tribune',
        newspaperCode: 'CAST',
        liaisonContacts: [PAGINATION, LEE_ESCALATION_PHOENIX],
        organizationId: 'S0QRjldIjmTzQDGFgQ0R'
    },
    {
        label: 'Lacrosse Tribune',
        newspaperCode: 'LACT',
        liaisonContacts: [PAGINATION, LEE_ESCALATION_PHOENIX],
        organizationId: 'svQvGBfP9hqdTjG4cvnn'
    },
    {
        label: 'Winona Daily News',
        newspaperCode: 'LACW',
        liaisonContacts: [PAGINATION, LEE_ESCALATION_PHOENIX],
        organizationId: '3EasZTZgneguAcfJF4LP'
    },
    {
        label: 'Chippewa Herald',
        newspaperCode: 'LACC',
        liaisonContacts: [PAGINATION, LEE_ESCALATION_PHOENIX],
        organizationId: 'lPdbuGY8jbHbjPVXKUby'
    },
    {
        label: 'Houston County News',
        newspaperCode: 'LACH',
        liaisonContacts: [PAGINATION, LEE_ESCALATION_PHOENIX],
        organizationId: 'ZBoq2tV6IxFTpLvSlcxC'
    },
    {
        label: 'La Crosse Courier',
        newspaperCode: 'LACL',
        liaisonContacts: [PAGINATION, LEE_ESCALATION_PHOENIX],
        organizationId: 'BbmJ8agp55ahugc0KBYL'
    },
    {
        label: 'Vernon County Times',
        newspaperCode: 'LACV',
        liaisonContacts: [PAGINATION, LEE_ESCALATION_PHOENIX],
        organizationId: 'jNpNUjvuPFK6Vo0tzfyP'
    },
    {
        label: 'Tomah Journal',
        newspaperCode: 'LACT',
        liaisonContacts: [PAGINATION, LEE_ESCALATION_PHOENIX],
        organizationId: 'GqE92wqIRMXPi6SU8bro'
    },
    {
        label: 'Dunn County News',
        newspaperCode: 'LACN',
        liaisonContacts: [PAGINATION, LEE_ESCALATION_PHOENIX],
        organizationId: 'qgeVqXp8wSBViLQ4gBYa'
    },
    {
        label: 'The Sentinel',
        newspaperCode: 'SENS',
        liaisonContacts: [PAGINATION, LEE_ESCALATION_PHOENIX],
        organizationId: 'hTNn6TmBeF2iXNQSXY2L'
    },
    {
        label: 'Post Star',
        newspaperCode: 'GLNP',
        liaisonContacts: [PAGINATION, LEE_ESCALATION_PHOENIX],
        organizationId: '93gfuexmNIGuYDVONRdw'
    },
    {
        label: 'The Citizen',
        newspaperCode: 'AUBC',
        liaisonContacts: [PAGINATION, LEE_ESCALATION_PHOENIX],
        organizationId: 'osFTGmhqBtTUp5A6hUhd'
    },
    {
        label: 'Journal Times',
        newspaperCode: 'RACJ',
        liaisonContacts: [PAGINATION, LEE_ESCALATION_PHOENIX],
        organizationId: 'wpdLsSR1mOlae6UHami7'
    },
    {
        label: 'Kenosha News',
        newspaperCode: 'KENK',
        liaisonContacts: [PAGINATION, LEE_ESCALATION_PHOENIX],
        organizationId: '8disduMvFVxYPC35slmY'
    },
    {
        label: 'Lake Geneva Reporter',
        newspaperCode: 'KENL',
        liaisonContacts: [PAGINATION, LEE_ESCALATION_PHOENIX],
        organizationId: 'rRirxYxtib0WVcwpmrBy'
    },
    {
        label: 'Wisconsin State Journal',
        newspaperCode: 'MADW',
        liaisonContacts: [PAGINATION, LEE_ESCALATION_PHOENIX],
        organizationId: 'nNVqpElC8Jo43s2BvpNA'
    },
    {
        label: 'Baraboo News Republic',
        newspaperCode: 'BARR',
        liaisonContacts: [PAGINATION, LEE_ESCALATION_PHOENIX],
        organizationId: 'JCJQysimemSe24duqYjE'
    },
    {
        label: 'Portage Daily Register',
        newspaperCode: 'PTGP',
        liaisonContacts: [PAGINATION, LEE_ESCALATION_PHOENIX],
        organizationId: '0OsuGXLJB1NBnssqxb35'
    },
    {
        label: 'Beaver Dam Daily Citizen',
        newspaperCode: 'BDMB',
        liaisonContacts: [PAGINATION, LEE_ESCALATION_PHOENIX],
        organizationId: 'ykTadnpqf9gJqNFW8OGG'
    },
    {
        label: 'The Capital Times',
        newspaperCode: 'MADC',
        liaisonContacts: [PAGINATION, LEE_ESCALATION_PHOENIX],
        organizationId: 'Tvq7r5MdGwLS5s6JDS9W'
    },
    {
        label: 'Juneau County Star Times',
        newspaperCode: 'PTGS',
        liaisonContacts: [PAGINATION, LEE_ESCALATION_PHOENIX],
        organizationId: 'voYQEaC4GlrAB54rOEg2'
    },
    {
        label: 'Wisconsin Dells Event',
        newspaperCode: 'PTGD',
        liaisonContacts: [PAGINATION, LEE_ESCALATION_PHOENIX],
        organizationId: '5wEoxcKU8C38W22JE0AL'
    },
    {
        label: 'Agri-View',
        newspaperCode: 'MADA',
        liaisonContacts: [PAGINATION, LEE_ESCALATION_PHOENIX],
        organizationId: '0Cg1bqiGxI9eG7BNidga'
    },
    {
        label: 'Times News',
        newspaperCode: 'TWNT',
        liaisonContacts: [PAGINATION, LEE_ESCALATION_PHOENIX],
        organizationId: 'iHUFzbVrEpmBVfJC4ZjU'
    },
    {
        label: 'Elko Daily Free Press',
        newspaperCode: 'ELKE',
        liaisonContacts: [PAGINATION, LEE_ESCALATION_PHOENIX],
        organizationId: 'LC7fRTgiCjI97S60mmy8'
    },
    {
        label: 'Kearney Hub',
        newspaperCode: 'KHPK',
        liaisonContacts: [PAGINATION, LEE_ESCALATION_PHOENIX],
        organizationId: '2mQdZxZMqZjcNak6hDjK'
    },
    {
        label: 'Lexington Clipper-Herald',
        newspaperCode: 'LCHL',
        liaisonContacts: [PAGINATION, LEE_ESCALATION_PHOENIX],
        organizationId: 'z4EfOE1DXuiCnb2gHmHc'
    },
    {
        label: 'Grand Island Independent',
        newspaperCode: 'GIII',
        liaisonContacts: [PAGINATION, LEE_ESCALATION_PHOENIX],
        organizationId: 'lJNwGxRwF2OBdSuVa1T8'
    },
    {
        label: 'The Columbus Telegram',
        newspaperCode: 'COLT',
        liaisonContacts: [PAGINATION, LEE_ESCALATION_PHOENIX],
        organizationId: 'RpvBCZC6McpHo3ZSRTlN'
    },
    {
        label: 'David City Banner Press',
        newspaperCode: 'COLD',
        liaisonContacts: [PAGINATION, LEE_ESCALATION_PHOENIX],
        organizationId: '4J495Gu0Ad7tIYgRvzBw'
    },
    {
        label: 'Schuyler Sun',
        newspaperCode: 'COLS',
        liaisonContacts: [PAGINATION, LEE_ESCALATION_PHOENIX],
        organizationId: 'aOK4QX7Xjvc0M9pfLgm2'
    },
    {
        label: 'York News-Times',
        newspaperCode: 'YNTY',
        liaisonContacts: [PAGINATION, LEE_ESCALATION_PHOENIX],
        organizationId: 'X4TiRxfuVzPZPSXuZHDu'
    },
    {
        label: 'St. Louis Post Dispatch',
        newspaperCode: 'STLD',
        liaisonContacts: [PAGINATION, LEE_ESCALATION_PHOENIX],
        organizationId: 'S2sP2B8XQe4Bj2q7YzMC'
    },
    TEST_UPLOAD_DESTINATION
];
