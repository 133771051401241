var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import moment from 'moment';
import DateFnsUtils from '@date-io/date-fns';
import { InputAdornment } from '@material-ui/core';
import { CalendarIcon, ExclamationCircleIcon, ArrowTopRightOnSquareIcon, TrashIcon } from '@heroicons/react/24/outline';
import { CancelOrSubmitModal } from 'lib/components/CancelOrSubmitModal';
import { guidGenerator, logAndCaptureException } from 'utils';
import { sanitize } from 'lib/helpers';
import Firebase from 'EnoticeFirebase';
import api from 'api';
import { Tooltip as CTooltip } from 'lib/components/Tooltip';
import FileDropzone from 'lib/components/FileUpload/FileDropzone';
import { MANUAL_UPLOAD } from 'lib/affidavits';
import ToastActions from 'redux/toast';
import { useAppDispatch } from 'redux/hooks';
import { ColumnService } from 'lib/services/directory';
import { getAffidavitUploadDatesForNewspaper } from './helpers';
const UPLOAD_FILE_MESSAGE = 'Uploading...';
const DELETE_FILE_MESSAGE = 'Deleting...';
function EEditionUploader({ setShowEUploadSuccessModal, activeOrganization, fixedUploadDate, setOpen, id }) {
    const [filesToUpload, setFilesToUpload] = useState([]);
    const [fileLoadingMessage, setFileLoadingMessage] = useState('');
    const [error, setError] = useState('');
    const [uploadedSnapshots, setUploadedSnapshots] = useState([]);
    const [publicationDate, setPublicationDate] = useState(fixedUploadDate);
    const [availablePubDates, setAvailablePubDates] = useState();
    const { iana_timezone } = activeOrganization.data();
    const dispatch = useAppDispatch();
    const shouldDisableDate = (date) => {
        const dateHasOutstandingNotices = (availablePubDates === null || availablePubDates === void 0 ? void 0 : availablePubDates.length) &&
            availablePubDates.some(pd => moment(pd).isSame(moment(date), 'day'));
        const dateIsInFuture = moment(date).isAfter(moment());
        return !dateHasOutstandingNotices || dateIsInFuture;
    };
    const uploadFile = (file) => __awaiter(this, void 0, void 0, function* () {
        if (!file)
            return;
        setFileLoadingMessage(UPLOAD_FILE_MESSAGE);
        const uniqueFileNameComponents = file.name.split('.');
        // Add a unique identifier to this upload
        uniqueFileNameComponents.splice(-1, 0, guidGenerator().slice(0, 8));
        const uniqueFileName = uniqueFileNameComponents.join('.');
        let fileSnapshot;
        try {
            fileSnapshot = yield Firebase.storage()
                .ref()
                .child(`e-editions/${activeOrganization.id}/manual_${sanitize(uniqueFileName)}`)
                .put(file);
        }
        catch (err) {
            setError('We are unable to upload your file at this time. Please try again.');
            logAndCaptureException(ColumnService.AFFIDAVITS, err, 'Unable to upload e-edition', {
                newspaperId: activeOrganization.id,
                fileName: uniqueFileName
            });
            /* If the file is not in the process of being deleted
            (if the user has not hit the delete button before the
            file finished uploading), then we should remove
            the current loading message. If, however, the file
            is in the process of being deleted, we should keep
            the deletion message in the UI until the deletion
            is complete. */
            if (fileLoadingMessage !== DELETE_FILE_MESSAGE) {
                setFileLoadingMessage('');
            }
            return;
        }
        setFileLoadingMessage('');
        setUploadedSnapshots(currentSnapshots => [
            ...currentSnapshots,
            fileSnapshot
        ]);
    });
    const handleFileDrop = (files) => __awaiter(this, void 0, void 0, function* () {
        let tempFilesToUpload = filesToUpload || [];
        tempFilesToUpload = tempFilesToUpload === null || tempFilesToUpload === void 0 ? void 0 : tempFilesToUpload.concat(files);
        setFilesToUpload(tempFilesToUpload);
        for (const file of files) {
            yield uploadFile(file);
        }
    });
    const handleDeleteFile = (index) => __awaiter(this, void 0, void 0, function* () {
        if (!filesToUpload && !uploadedSnapshots && !fileLoadingMessage)
            return;
        setFileLoadingMessage(DELETE_FILE_MESSAGE);
        const newFilesToUpload = filesToUpload;
        newFilesToUpload[index] = undefined;
        // if there are no files to upload, set the array to an empty array.
        if (newFilesToUpload.every(file => file === undefined)) {
            setFilesToUpload([]);
        }
        else {
            setFilesToUpload(newFilesToUpload);
        }
        setError('');
        const previousUploadedSnapshot = uploadedSnapshots[index];
        /* We have to set the uploadedSnapshot state variable to undefined
        before actually deleting the ref so that we know throughout
        the rest of the component to treat the file as deleted */
        const tempUploadedSnapshots = uploadedSnapshots;
        tempUploadedSnapshots[index] = undefined;
        setUploadedSnapshots(tempUploadedSnapshots);
        if (tempUploadedSnapshots.every(snapshot => snapshot === undefined)) {
            setUploadedSnapshots([]);
        }
        else {
            setUploadedSnapshots(tempUploadedSnapshots);
        }
        if (previousUploadedSnapshot) {
            try {
                yield previousUploadedSnapshot.ref.delete();
            }
            catch (err) {
                logAndCaptureException(ColumnService.AFFIDAVITS, err, 'Unable to delete e-edition', {
                    newspaperId: activeOrganization.id,
                    fileName: previousUploadedSnapshot.ref.name
                });
            }
        }
        setFileLoadingMessage('');
    });
    const handlePopOut = (index) => __awaiter(this, void 0, void 0, function* () {
        const uploadedSnapshot = uploadedSnapshots[index];
        if (!uploadedSnapshot) {
            setError('We are unable to find the uploaded e-editions. Please try uploading your file again.');
            return;
        }
        let url;
        try {
            url = yield uploadedSnapshot.ref.getDownloadURL();
        }
        catch (err) {
            setError('We are unable to load your uploaded e-edition. Please try uploading your file again.');
            return;
        }
        window.open(url);
    });
    const handleSubmit = () => __awaiter(this, void 0, void 0, function* () {
        const formattedDate = moment(publicationDate).format('MM/DD/YYYY');
        const filesFailedToUpload = [];
        yield Promise.allSettled(uploadedSnapshots.map((uploadedSnapshot, index) => __awaiter(this, void 0, void 0, function* () {
            var _a;
            if (uploadedSnapshot) {
                try {
                    const storagePath = uploadedSnapshot.ref.fullPath;
                    const reqBody = {
                        newspaperId: activeOrganization.id,
                        storagePath,
                        runDateString: formattedDate,
                        uploadMethod: MANUAL_UPLOAD
                    };
                    const resp = yield api.post('affidavit-automation/events/create-eedition-uploaded-event', reqBody);
                    if (!resp.success) {
                        throw new Error(resp.error ||
                            'Unknown error creating verification upload initiated event');
                    }
                    return;
                }
                catch (err) {
                    const fileName = ((_a = uploadedSnapshots[index]) === null || _a === void 0 ? void 0 : _a.ref.name) || 'unknown';
                    filesFailedToUpload.push(fileName);
                    throw new Error(`Failed to upload ${fileName}`, {
                        cause: err
                    });
                }
            }
        })));
        if (filesFailedToUpload.length) {
            dispatch(ToastActions.toastError({
                headerText: 'Upload Failed',
                bodyText: `The following file(s) failed to upload: ${filesFailedToUpload.join(', ')}. You can try uploading them again; if the problem persists, please contact help@column.us.`
            }));
        }
        else {
            dispatch(ToastActions.toastSuccess({
                headerText: 'Verification Initiated',
                bodyText: `You have successfully uploaded your file(s), and the verification process has begun. You will receive an email notification of the verification results upon completion.`
            }));
        }
        setOpen(false);
        setShowEUploadSuccessModal && setShowEUploadSuccessModal(true);
    });
    /**
     * If the user has not selected a publication date, we should
     * set the publication date to the most recent publication date
     */
    useEffect(() => {
        // If the user has already selected a publication date, we should not change it
        if (fixedUploadDate)
            return;
        const setDatesWithOutstandingNotices = () => __awaiter(this, void 0, void 0, function* () {
            const allowedPublishingDates = yield getAffidavitUploadDatesForNewspaper(activeOrganization);
            const allowedDates = allowedPublishingDates.map(allowedPublishingDate => allowedPublishingDate.date.toDate());
            setAvailablePubDates(allowedDates);
            const mostRecentPublicationDate = allowedDates
                ? allowedDates[0]
                : undefined;
            if (mostRecentPublicationDate) {
                setPublicationDate(mostRecentPublicationDate);
            }
        });
        void setDatesWithOutstandingNotices();
    }, [activeOrganization.id]);
    return (_jsx(CancelOrSubmitModal, Object.assign({ onClose: () => setOpen(false), body: "We will start processing affidavits once you submit your e-edition and a publication date.", header: `Upload E-edition for ${activeOrganization.data().name}`, width: "md:max-w-xl", id: id, primaryButtonText: "Submit e-edition", tertiaryButtonText: "Cancel", onSubmit: handleSubmit, disablePrimaryButton: !!fileLoadingMessage ||
            !publicationDate ||
            !uploadedSnapshots ||
            !filesToUpload.length ||
            !!error, showLoadingSpinner: true }, { children: _jsxs("div", Object.assign({ className: "flex-col space-y-6 py-6" }, { children: [_jsx(FileDropzone, { id: "eEdition-upload-modal", multiple: true, acceptFileTypes: '.pdf', onDrop: (file) => __awaiter(this, void 0, void 0, function* () {
                        setError('');
                        yield handleFileDrop(file);
                    }) }), error && (_jsx("div", Object.assign({ className: "text-sm" }, { children: _jsx("div", Object.assign({ className: "flex flex-row mt-2 space-x-2" }, { children: _jsxs("div", Object.assign({ className: "flex flex-row items-center rounded-md bg-red-50 align-middle min-h-11 w-full space-x-2 py-1.5 pr-3" }, { children: [_jsx("div", Object.assign({ className: "flex flex-row items-center" }, { children: _jsx(ExclamationCircleIcon, { className: "text-red-600 h-8 w-8 pl-3" }) })), _jsx("div", Object.assign({ className: "text-red-600 flex items-center" }, { children: error }))] })) })) }))), filesToUpload &&
                    filesToUpload.map((fileToUpload, index) => (_jsx("div", Object.assign({ className: "text-sm" }, { children: fileToUpload && (_jsxs("div", { children: [_jsx("div", Object.assign({ className: "text-column-gray-300" }, { children: "E-EDITION" })), fileLoadingMessage && (_jsxs("div", Object.assign({ className: "flex flex-row items-center mt-2 space-x-2" }, { children: [_jsxs("div", Object.assign({ className: "flex flex-row rounded-md border border-column-gray-100 bg-column-gray-50 align-middle min-h-11 w-full space-x-2 py-3" }, { children: [_jsx("div", Object.assign({ className: "flex pl-3 items-center justify-center rounded-b" }, { children: _jsx("div", { className: "loader ease-linear rounded-full border-4 border-t-4 border-column-gray-200 h-5 w-5" }) })), _jsx("div", Object.assign({ className: "text-column-gray-400 flex items-center" }, { children: fileLoadingMessage }))] })), _jsx(TrashIcon, { className: "bg-red-50 h-11 w-12 rounded-md text-red-600 p-2 cursor-pointer", onClick: () => handleDeleteFile(index) })] }))), !fileLoadingMessage && (_jsxs("div", Object.assign({ className: "flex flex-row items-center mt-2 space-x-2" }, { children: [_jsx("div", Object.assign({ className: "border border-column-gray-100 align-middle rounded-md bg-column-gray-50 text-column-gray-400 flex items-center pl-3.5 min-h-11 w-full py-3" }, { children: fileToUpload.name })), _jsx(ArrowTopRightOnSquareIcon, { className: "bg-column-primary-50 h-11 w-12 rounded-md text-column-primary-500 p-2 cursor-pointer", onClick: () => handlePopOut(index) }), _jsx(TrashIcon, { className: "bg-red-50 h-11 w-12 rounded-md text-red-600 p-2 cursor-pointer", onClick: () => handleDeleteFile(index) })] })))] })) }), `eEdition file ${index}`))), _jsxs("div", Object.assign({ className: "text-sm" }, { children: [_jsx("div", { children: "Publication date" }), _jsx(CTooltip, Object.assign({ helpText: !fixedUploadDate && (!uploadedSnapshots || !filesToUpload)
                                ? `Please upload your e-edition before selecting a publication date.`
                                : '', id: "e-edition-uploader-datepicker-tooltip" }, { children: _jsx("div", Object.assign({ className: "border border-column-gray-200 rounded-md mt-2" }, { children: _jsx(MuiPickersUtilsProvider, Object.assign({ utils: DateFnsUtils }, { children: _jsx(DatePicker, { label: "", value: publicationDate || moment().tz(iana_timezone).toDate(), placeholder: "MMM dd, YYYY", format: "MMM dd, yyyy", className: 'text-xs w-full', InputProps: {
                                            disableUnderline: true,
                                            endAdornment: (_jsx(InputAdornment, Object.assign({ position: "end" }, { children: _jsx(CalendarIcon, { className: "text-column-gray-300" }) }))),
                                            className: 'px-3.5 py-1.5',
                                            style: {
                                                fontSize: '14px'
                                            }
                                        }, onChange: date => {
                                            setError('');
                                            if (iana_timezone && date) {
                                                setPublicationDate(moment(date.getTime()).tz(iana_timezone).toDate());
                                            }
                                        }, shouldDisableDate: date => !!date && shouldDisableDate(date), disabled: Boolean(fixedUploadDate) ||
                                            !uploadedSnapshots ||
                                            !filesToUpload }) })) })) }))] }))] })) })));
}
export default EEditionUploader;
