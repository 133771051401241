export var ColumnService;
(function (ColumnService) {
    /** Column Admin */
    ColumnService["ADMIN"] = "admin";
    /** Static file storage */
    ColumnService["STATIC_ASSETS"] = "static-assets";
    /** Our primary Firestore database */
    ColumnService["DATABASE"] = "database";
    /** Automated affidavits */
    ColumnService["AFFIDAVITS"] = "affidavits";
    /** AFFinity X Service */
    ColumnService["AFFINITY_X"] = "affinity-x";
    /** inApp notifications */
    ColumnService["APPLICATION_NOTIFICATIONS"] = "application-notifications";
    /** Automated notice placement */
    ColumnService["AUTOMATED_NOTICE_PLACEMENT"] = "automated-notice-placement";
    /** Bulk invoicing (v1 and v2) */
    ColumnService["BULK_INVOICES"] = "bulk-invoices";
    /** Deadline scheduling and syncing */
    ColumnService["DEADLINES"] = "deadlines";
    /** Elastic */
    ColumnService["ELASTIC"] = "elastic";
    /** Check Reconciliation, ACH reconciliation, etc */
    ColumnService["FINANCIAL_RECONCILIATION"] = "financial-reconciliation";
    /** Authentication and user management */
    ColumnService["AUTH_AND_USER_MANAGEMENT"] = "authentication-and-user-management";
    /** Inbound FTP to our FileMage server */
    ColumnService["FTP"] = "ftp";
    /** General Cloud infrastructure */
    ColumnService["GENERAL_INFRASTRUCTURE"] = "general-infrastructure";
    /** InDesign Service */
    ColumnService["INDESIGN"] = "indesign";
    /** General integrations infrastructure */
    ColumnService["INTEGRATIONS"] = "integrations";
    /** Lee Adpoint integration */
    ColumnService["INTEGRATIONS_LEE_ADPOINT"] = "integrations-lee-adpoint";
    /** SCNG Naviga integration */
    ColumnService["INTEGRATIONS_SCNG"] = "integrations-scng";
    /** Launch Darkly Service */
    ColumnService["LAUNCH_DARKLY_SERVICE"] = "launch-darkly-service";
    /** Classifieds & Obituaries */
    ColumnService["OBITS"] = "obits";
    /** All payment processing (Stripe, Elavon, Payway, Dwolla, etc.) */
    ColumnService["PAYMENTS"] = "payments";
    /** Custom pagination (i.e. code that's tied to /pagination) */
    ColumnService["PAGINATION"] = "pagination";
    /** Registered Agents API */
    ColumnService["REGISTERED_AGENTS"] = "registered-agents";
    /** Support Bot Context */
    ColumnService["SUPPORT_BOT"] = "support-bot";
    /** All other issues */
    ColumnService["UNKNOWN"] = "unknown";
    /** Placing notices on the web */
    ColumnService["WEB_PLACEMENT"] = "web-placement";
    /** Display sites */
    ColumnService["DISPLAY_SITES"] = "display-sites";
    /** CI and deployment */
    ColumnService["CI_CD"] = "ci-and-deployment";
})(ColumnService || (ColumnService = {}));
