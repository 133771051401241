var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useEffect, useMemo, useState } from 'react';
import { exists } from 'lib/types';
import { cdnIfy } from 'lib/helpers';
import { getIsAfterPublishingDeadline } from 'lib/utils/deadlines';
import { useFirestoreSnapshot } from 'lib/frontend/hooks/useFirestoreSnapshot';
import { NoticeDetailsAction, placementSelector } from 'redux/placement';
import { generateProofForDraft, submitNoticeToPublisher } from 'redux/placement/placementActions';
import classNames from 'classnames';
import { canEditNoticeWithoutSupport } from 'utils/permissions';
import { isNoticeContentData } from 'lib/types/notice';
import { FileUploadListItem } from 'lib/components/FileUpload/FileUploadListItem';
import { calculateInvoicePricing, getConvenienceFeeWithRepFeeFromRateOrNewspaper, getInvoiceAmountsBreakdown, getInvoiceLineItems, pricesAreTheSame } from 'lib/pricing';
import { getFirebaseContext } from 'utils/firebase';
import { safeStringify } from 'lib/utils/stringify';
import { Modal } from 'lib/components/Modal';
import { compareNoticeAndInvoiceLineItems } from 'lib/utils/invoices';
import { useHasPermission } from 'utils/useHasPermission';
import { Permissions } from 'lib/permissions/roles';
import { getAffidavitSettingsForNotice } from 'lib/pricing/affidavits';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { getMailDataFromNoticeOrDraft } from 'lib/mail';
import { createDbPricingObjFromPlacement } from 'utils/pricing';
import Firebase from 'EnoticeFirebase';
import { getBooleanFlag } from 'utils/flags';
import { LaunchDarklyFlags } from 'lib/types/launchDarklyFlags';
import { getOrganizationGateway } from 'lib/billing/gateways';
import { STRIPE } from 'lib/constants';
import useAsyncEffect from 'lib/frontend/hooks/useAsyncEffect';
import { Product } from 'lib/enums';
import { getOriginalFileName, getOriginalFirebaseStoragePath } from './helpers';
import ScrollStep from './ScrollStep';
import InvoiceReminderModal from './InvoiceReminderModal';
import AffidavitReminderModal from './AffidavitReminderModal';
// This component will render a generated proof with an image of the formatted ad
function ConfirmProofOrFilePreview({ placement, fileReady, previewUrl, newspaper, error }) {
    return (_jsxs("div", Object.assign({ id: "confirm-proof-modal", className: "relative text-center divide-y" }, { children: [_jsx("div", Object.assign({ id: "confirm-proof-preview", className: "relative" }, { children: _jsx("div", Object.assign({ className: "h-80 bg-grey-100" }, { children: fileReady ? (_jsx("embed", { className: "w-full h-full", src: `${previewUrl}#toolbar=0&navpanes=0&scrollbar=0`, type: "application/pdf" })) : (_jsx("div", Object.assign({ className: "w-full h-full flex" }, { children: _jsx("div", Object.assign({ className: "px-3 rounded-b m-auto" }, { children: _jsx("div", { style: { borderTopColor: '#2D9BDB' }, className: "loader ease-linear rounded-full border-4 border-t-4 border-white h-8 w-8" }) })) }))) })) })), _jsxs("a", Object.assign({ style: {
                    top: '307px',
                    right: '187px',
                    border: '1px solid #ABD7F1'
                }, href: previewUrl, target: "_blank", rel: "noreferrer", className: classNames(`absolute w-fit items-center rounded-full bg-blue-50 px-2 py-0.5 text-xs font-medium text-blue-900`, { 'cursor-not-allowed pointer-events-none': !fileReady }) }, { children: ["Scroll above or click here to open in a new tab", ' ', _jsx("span", Object.assign({ role: "img", "aria-label": "Hand with index finger pointing up" }, { children: "\u261D" }))] })), _jsxs("div", Object.assign({ id: "confirm-proof-body", className: "px-12 py-10" }, { children: [_jsx("div", Object.assign({ className: "pb-4 text-xl font-semibold text-gray-900" }, { children: fileReady
                            ? `Preview ${placement.postWithoutFormatting ? 'file' : 'proof'} and confirm submission`
                            : `Generating proof...` })), _jsxs("div", Object.assign({ className: "text-sm font-medium text-grey-400 leading-6" }, { children: ["Scroll above to view your", ' ', placement.postWithoutFormatting ? 'uploaded file' : 'proof', " or", ' ', _jsx("a", Object.assign({ className: classNames('text-blue-900', {
                                    'cursor-not-allowed pointer-events-none': !fileReady
                                }), href: previewUrl, target: "_blank", rel: "noreferrer" }, { children: "click here" })), ' ', "to open it in a new tab.", placement.postWithoutFormatting
                                ? ' By submitting your notice without formatting, you grant the newspaper permission to edit the layout of your notice. '
                                : ' ', "After review, click the confirm button to submit your notice to", ' ', (newspaper === null || newspaper === void 0 ? void 0 : newspaper.data().name) || 'the newspaper', "."] })), error && (_jsx("div", Object.assign({ className: "pt-2 text-red-600 leading-tight" }, { children: error })))] }))] })));
}
// This component will render a list of file items for notices
// that have been submitted without formatting
function ConfirmFileItemsList({ newspaper, noticeContentFiles, fileReady, placement, error }) {
    return (_jsxs("div", Object.assign({ id: "confirm-proof-modal", className: "divide-y" }, { children: [_jsx("div", Object.assign({ className: "px-12 py-10" }, { children: noticeContentFiles.map(noticeFile => (_jsx(FileUploadListItem, { firebaseStoragePath: getOriginalFirebaseStoragePath(noticeFile), displayFileName: getOriginalFileName(noticeFile), fileExtensionString: noticeFile.fileFormat || undefined, storage: Firebase.storage() }, `${noticeFile.originalFileName}-upload-list-item`))) })), _jsxs("div", Object.assign({ id: "confirm-proof-body", className: "px-10 py-8 text-center" }, { children: [_jsx("div", Object.assign({ className: "pb-4 text-xl font-semibold text-gray-900 text-center" }, { children: fileReady
                            ? `Preview ${placement.postWithoutFormatting ? 'file' : 'proof'} and confirm submission`
                            : `Generating proof...` })), _jsxs("div", Object.assign({ className: "text-sm font-medium text-grey-400 leading-6" }, { children: ["Click each preview button above to view your uploaded file. By submitting your notice without formatting, you grant the newspaper permission to edit the layout of your notice. After review, click the button below to submit your notice to", ' ', (newspaper === null || newspaper === void 0 ? void 0 : newspaper.data().name) || 'the newspaper', "."] })), error && (_jsx("div", Object.assign({ className: "pt-2 text-red-600 leading-tight" }, { children: error })))] }))] })));
}
export default function ConfirmProofStep({ user, id, newspaper, notice, isPublisher, onDisabledStepClick, editing, placementActions }) {
    const dispatch = useAppDispatch();
    const placement = useAppSelector(placementSelector);
    const [showModal, setShowModal] = useState();
    const showProofConfirmationModal = showModal === 'proof';
    const showInvoiceReminderModal = showModal === 'invoice';
    const showAffidavitReminderModal = showModal === 'affidavit';
    const [error, setError] = useState('');
    const { proofStoragePath, filesToAttach } = placement;
    const noticeContentFiles = (filesToAttach || []).filter(fileToAttach => isNoticeContentData(fileToAttach));
    const getPreviewUrl = () => {
        if (!placement.postWithoutFormatting) {
            return proofStoragePath
                ? cdnIfy(proofStoragePath, { useImgix: true })
                : '';
        }
        return '';
    };
    const fileReady = Boolean(proofStoragePath || placement.postWithoutFormatting);
    const deadlinePassed = () => {
        var _a;
        if (!exists(newspaper) || !((_a = placement.publicationDates) === null || _a === void 0 ? void 0 : _a.length))
            return;
        const { deadlines, deadlineOverrides = {}, iana_timezone } = newspaper.data();
        if (!deadlines)
            throw new Error('No deadlines found for newspaper');
        return getIsAfterPublishingDeadline(placement.publicationDates[0].toDate(), deadlines, deadlineOverrides, iana_timezone, placement, newspaper);
    };
    const invoice = useFirestoreSnapshot(notice.data().invoice);
    // Determine if the new total is >= 1 cent different from the old total
    const [newTotalInCents, setNewTotalInCents] = useState(0);
    const oldTotalInCents = exists(invoice)
        ? getInvoiceAmountsBreakdown(invoice).totalInCents
        : 0;
    const hasPriceChange = !pricesAreTheSame(newTotalInCents, oldTotalInCents);
    // Determine if the invoice and the notice have all the same publication dates
    const hasPublicationDateChange = useMemo(() => {
        if (!exists(invoice)) {
            return false;
        }
        const res = compareNoticeAndInvoiceLineItems(notice, invoice);
        return !!res.error;
    }, [
        safeStringify(notice.data().publicationDates),
        safeStringify(invoice === null || invoice === void 0 ? void 0 : invoice.data().inAppLineItems)
    ]);
    // Calculate the pricing for the new notice every time we launch the proof
    // confirmation modal
    const rateRef = useAppSelector((state) => state.placement.rate);
    const rate = useFirestoreSnapshot(rateRef);
    useEffect(() => {
        const calculatePricing = () => __awaiter(this, void 0, void 0, function* () {
            var _a;
            if (!exists(newspaper) || !exists(rate)) {
                return;
            }
            // These are implicit dependencies in createDBPricingObject. They should
            // always be set, but sometimes the placement flow initializes in strange
            // states.
            if (!notice.data().newspaper ||
                !((_a = placement.publicationDates) === null || _a === void 0 ? void 0 : _a.length) ||
                !notice.data().filer) {
                return;
            }
            // Calculate the pricing using the same helpers that are be used
            // in the invoice creation flow in order to predict what the new
            // invoice pricing will be.
            const dbPricing = yield createDbPricingObjFromPlacement(placement, Product.Notice);
            const inAppLineItems = getInvoiceLineItems(dbPricing, newspaper, rate);
            const affidavitReconciliationSettings = yield getAffidavitSettingsForNotice(getFirebaseContext(), notice);
            const convenienceFee = getConvenienceFeeWithRepFeeFromRateOrNewspaper(newspaper, rate);
            const mail = yield getMailDataFromNoticeOrDraft(notice.ref);
            const newInvoicePricing = calculateInvoicePricing(newspaper, {
                inAppLineItems,
                affidavitReconciliationSettings,
                convenienceFee,
                mail,
                noticePublicationDates: placement.publicationDates,
                rate
            });
            setNewTotalInCents(newInvoicePricing.totalInCents);
        });
        void calculatePricing();
    }, [
        exists(newspaper),
        exists(rate),
        showProofConfirmationModal,
        safeStringify(placement.displayParams),
        placement.previewContent.price
    ]);
    // If there is no invoice or no meaningful change, skip this modal.
    // If the notice has already transferred it's too late.
    const userCanCreateInvoices = useHasPermission(Permissions.INVOICES_CREATE);
    const userCanRefund = useHasPermission(Permissions.INVOICES_VOID);
    const userCanVoid = useHasPermission(Permissions.INVOICES_REFUND);
    const userCanVoidOrRefundInvoice = userCanVoid || userCanRefund;
    const { value: paymentGateway } = useAsyncEffect({
        fetchData: () => getOrganizationGateway(newspaper === null || newspaper === void 0 ? void 0 : newspaper.ref),
        dependencies: [newspaper === null || newspaper === void 0 ? void 0 : newspaper.id]
    });
    const enablePartialRefundsV2 = getBooleanFlag(LaunchDarklyFlags.ENABLE_PARTIAL_REFUNDS_V_2, false) &&
        paymentGateway === STRIPE;
    // original shouldShowInvoiceReminderModal boolean
    const shouldShowInvoiceReminderModalv1 = exists(invoice) &&
        userCanCreateInvoices &&
        !notice.data().transfer &&
        (hasPriceChange || hasPublicationDateChange);
    // conditionally add userCanVoidOrRefundInvoice depending on whether we are on enablePartialRefundsV2
    const shouldShowInvoiceReminderModal = enablePartialRefundsV2
        ? shouldShowInvoiceReminderModalv1 && userCanVoidOrRefundInvoice
        : shouldShowInvoiceReminderModalv1;
    const confirmProof = () => __awaiter(this, void 0, void 0, function* () {
        var _a, _b;
        if (!isPublisher && ((_a = newspaper === null || newspaper === void 0 ? void 0 : newspaper.data()) === null || _a === void 0 ? void 0 : _a.editRunDatesAfterInvoicePaid)) {
            yield finishConfirmation();
        }
        else if (editing &&
            isPublisher &&
            exists(user) &&
            !canEditNoticeWithoutSupport(notice, user, newspaper) &&
            !((_b = notice.data()) === null || _b === void 0 ? void 0 : _b.invoice)) {
            setShowModal('affidavit');
        }
        else if (!shouldShowInvoiceReminderModal) {
            yield finishConfirmation();
        }
        else {
            setShowModal('invoice');
        }
    });
    const finishConfirmation = () => __awaiter(this, void 0, void 0, function* () {
        if (!placement.confirming) {
            if (!deadlinePassed() || isPublisher) {
                yield dispatch(submitNoticeToPublisher());
            }
            else {
                setError('Error: The ad deadline for this notice has passed. Please select a new date.');
            }
        }
    });
    useEffect(() => {
        if (placement.publicationDates && !deadlinePassed()) {
            setError('');
        }
    }, [placement.publicationDates]);
    return (_jsxs(_Fragment, { children: [_jsx(ScrollStep, Object.assign({ id: id, overrideStyles: true, onDisabledStepClick: onDisabledStepClick }, { children: _jsx("div", Object.assign({ className: "items-center" }, { children: _jsx("button", Object.assign({ id: "confirm-options", className: "w-full h-20 bg-blue-900 text-white text-2xl font-medium uppercase rounded-md", onClick: () => {
                            if (!placement.postWithoutFormatting) {
                                void dispatch(generateProofForDraft());
                            }
                            setShowModal('proof');
                        } }, { children: _jsxs("div", Object.assign({ className: "inline-block" }, { children: ['Review & Confirm', " ", editing ? ' edits' : ''] })) })) })) })), showProofConfirmationModal && (_jsxs(Modal, Object.assign({ id: "confirm-proof-modal", onClose: () => setShowModal(undefined), title: "Review & Confirm", titleHidden: true, primaryAction: {
                    buttonText: 'Submit',
                    onClick: confirmProof,
                    id: 'confirm-proof-button',
                    loading: placement.confirming,
                    disabled: !!error || !fileReady,
                    type: 'button'
                }, secondaryActions: [
                    {
                        buttonText: 'Back',
                        type: 'button'
                    }
                ], sectioned: false, size: "2xl" }, { children: [placement.postWithoutFormatting && (_jsx(ConfirmFileItemsList, { placement: placement, error: error, newspaper: newspaper, fileReady: fileReady, noticeContentFiles: noticeContentFiles })), !placement.postWithoutFormatting && (_jsx(ConfirmProofOrFilePreview, { placement: placement, error: error, newspaper: newspaper, fileReady: fileReady, previewUrl: getPreviewUrl() }))] }))), showInvoiceReminderModal && (_jsx(InvoiceReminderModal, { notice: notice, invoice: invoice, newspaper: newspaper, oldPriceInCents: oldTotalInCents, newPriceInCents: newTotalInCents, hasPriceChange: hasPriceChange, hasPublicationDateChange: hasPublicationDateChange, placementPubDates: placement.publicationDates, onBackClicked: () => setShowModal(undefined), enablePartialRefundsV2: enablePartialRefundsV2, onConfirmClicked: ({ reinvoiceAfterPlacement, partialRefundAfterPlacement }) => {
                    if (reinvoiceAfterPlacement) {
                        placementActions.setPostPlacementAction(NoticeDetailsAction.REINVOICE);
                    }
                    // redirect below only if we are on partialRefundsV2
                    if (partialRefundAfterPlacement && enablePartialRefundsV2) {
                        placementActions.setPostPlacementAction(NoticeDetailsAction.PARTIAL_REFUND);
                    }
                    if (exists(user) &&
                        !canEditNoticeWithoutSupport(notice, user, newspaper)) {
                        setShowModal('affidavit');
                    }
                    else {
                        void finishConfirmation();
                    }
                } })), showAffidavitReminderModal && (_jsx(AffidavitReminderModal, { onConfirmClicked: () => finishConfirmation(), onBackClicked: () => setShowModal(undefined) }))] }));
}
